.candidate-pool-table {
  border: 1px solid #d9d9d9;
  background-color: white;

  thead.ant-table-thead > tr > th {
    background-color: white;
  }

  tbody.ant-table-tbody > tr > td {
    padding: 0.75em;
    vertical-align: middle;
  }
}

.ant-tag.filter-tag {
  font-size: 14px;
  height: 32px;
  line-height: 2;
  text-align: center;
}

.ant-dropdown-menu-item {
  span.ant-tag.dropdown-filter-tag {
    i.anticon.anticon-close {
      margin-right: 0;
    }
  }
}

.select-columns-menu {
  li:hover, div.ant-dropdown-menu-submenu-title:hover {
    cursor: default;
  }

  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background: white;
  }

  .select-columns-submenu {
    .ant-dropdown-menu-submenu-title {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }

    .ant-dropdown-menu {
      background: white;
      box-shadow: none;
    }

    li.select-columns-submenu-item {
      padding-left: 12px !important;
    }
  }
}
