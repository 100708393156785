$mainColor: #f175a5;
$main-border-color: #e2e8f5;

.ant-btn-primary:hover {
  opacity: 0.7;
}

.template-container {
  position: relative;
  padding: 2rem;
  border-top: 1px solid rgb(236, 236, 236);

  &__dropdown-menu {
    width: 17.75rem;
    padding: 5px 0 5px 16px;
  }

  &__title {
    padding: 20px 0px 10px 0px;

    h3 {
      margin: 0;
    }

    @media screen and (max-width: 400px) {
      padding-top: 25%;

      h3 {
        font-size: 1rem;
        margin: 0;
      }
    }
  }

  &__info {
    margin-bottom: 1rem;
  }

  &__tags {
    border-style: dashed;
  }

  &__table-container {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: var(--myHeight) !important;

    &--tags {
      height: 4.063rem;
      i {
        margin: 10px 0 5px 0;
      }
    }
    &--more {
      border-bottom: 1px solid #d9d9d9;
      height: 4.063rem;
    }
    &--plus-container {
      border-bottom: 1px solid #d9d9d9;
      height: 4.063rem;
    }
    &--plus {
      height: 100%;
    }
    &--plus-selected {
      height: 100%;
      background-color: #f5f5f5;
    }
    &--all-cat {
      height: 100%;
      color: #5e5e5e;
      cursor: pointer;
    }

    &--svg-container {
      margin: 146px 0 0 0;

      svg {
        margin: 8px 0 0 0;
      }

      @media screen and (max-width: 1356px) {
        margin: 209px 0 0 0;
      }
    }
  }

  &__create-button {
    margin-bottom: 20px;
  }

  &__switch-button {
    margin: 5px 0 10px 0;
    span {
      margin-left: 10px;
    }
  }

  .ant-table {
    .ant-btn {
      margin-right: 5px;
    }
    border: 1px solid #d9d9d9;
    background: white;
    border-top: none;
    border-bottom: none;

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      border-color: $mainColor;
      color: $mainColor;
    }
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active:hover,
  .ant-pagination-item-active:active,
  .ant-pagination-item-active:focus {
    border-color: $mainColor;
  }

  .ant-pagination-item:active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $mainColor !important;
  }

  .ant-pagination-item a {
    color: #616161;
  }

  .ant-pagination-item-active a,
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: #ffffff;
    background-color: $mainColor;
  }

  .ant-pagination {
    float: left;
  }

  .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: #f0f0f0 !important;
  }

  &__modal-preview-row {
    margin: 0 0 20px 0;
    gap: 20px;
  }
}

.ant-modal-confirm-body-wrapper {
  .ant-btn-danger:hover {
    color: #ffffff !important;
    opacity: 0.7;
  }
}

.template-crud {
  min-height: 100vh;
  border-top: 1px solid rgb(236, 236, 236);
  background-color: #ffffff;
  padding-left: 45px;
  padding-right: 45px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 40px 0px 30px 10px;
    background-color: #ffffff !important;

    @media screen and (max-width: 400px) {
      margin-top: 30px;

      h3.ant-typography {
        font-size: 16px;
      }
    }

    h3.ant-typography {
      margin-bottom: 0px;
      margin-left: 10px;
    }
  }

  &__body {
    margin-left: 10px;
    width: 80%;

    @media screen and (max-width: 550px) {
      margin: 0;
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    @media screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-form-item {
        margin: 0;

        .ant-btn {
          width: 200px;
        }
      }
    }

    .ant-btn {
      margin-right: 5px;
    }

    .ant-btn-secondary:hover,
    .ant-btn-secondary:focus,
    .ant-btn-secondary:active {
      border-color: $mainColor;
      color: $mainColor;
    }
  }
}

.modal-preview {
  width: 90% !important;
  height: 80% !important;
}
