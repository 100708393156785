textarea {
    border-radius: 1px !important;
}

.cute-button-primary{
    width: 100%;
    border-radius: 1px;
    margin-bottom: 15px;
    color: #FFFFFF !important;
}

.cute-button-disabled{
    width: 100%;
    border-radius: 1px;
    margin-bottom: 15px;
}

.cute-button-primary:hover{
    color: #FFFFFF !important;
    opacity: .7;
}
.cute-button-primary:active{
    color: #FFFFFF !important
}
.cute-button-primary:focus{
    color: #FFFFFF !important
}

.cute-button-secondary{
    width: 100%;
    border-radius: 1px;
    margin-bottom: 10px;
}

.cute-button-prev{
    margin-right: 20px;
    border-radius: 1px,
}
.cute-form-answe-option-img{
    height: 24px !important;
}