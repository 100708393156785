.disc-pdf-modal {
  // Hide the default header and footer in the native print setting usings margins under 10px
  @page {
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  @media print {
    html,
    body {
      height: 100vh;
    }
  }

  &__mainsection {
    height: 90%;
    width: 90%;
    margin: 0px 30px;
    position: relative;
    page-break-inside: avoid;

    display: table;
    table-layout: fixed;

    h5 {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: bold;
      color: #454545;
    }

    h4 {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: bold;
      color: #454545;
    }

    &__summary {
      height: 100%;
      &__header {
        display: flex;
        flex-direction: column;
        &__candidateinformation {
          margin: 10px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h5 {
            color: #2b2b2b;
            font-weight: 700;
          }

          p {
            color: #5e5e5e;
            font-size: 10px;

            @media print {
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
}
