.leadership-report-modal {
  .ant-modal-body {
    padding: 0;
  }
}

.leadership-pdf {
  .ant-divider {
    margin: 0;
  }

  @media print {
    .no-print {
      display: none;
    }

    // Don t break the page inside a div
    .protected-div {
      page-break-inside: avoid;
    }

    .break-page {
      position: relative;
      page-break-inside: avoid;
    }

    .break-before {
      page-break-before: always;
    }

    .first-page {
      height: 1111px;
    }
  }

  .page {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    span {
      text-align: justify;
    }

    h3,
    h4 {
      margin: 0;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__left {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.chart-colors {
  height: fit-content;
  display: flex;
  gap: 1.5rem;
  justify-content: end;
  padding: 0 1rem;

  &__item {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}
