.client-calendar {
  padding: 1rem;
  margin: 0 !important;

  .ant-fullcalendar-date {
    background: #ffffff;
  }

  .ant-btn-link {
    padding: 0;
  }

  &__right {
    .ant-card-body {
      padding: 16px;
    }
  }

  .ant-fullcalendar-header {
    .ant-radio-group-outline {
      display: none;
    }
  }

  .ant-modal-close {
    display: none !important;
  }
}

.calendar-interview {
  cursor: pointer;

  &__left {
    text-align: right;
  }

  &__assistants {
    width: 100%;
    padding-block: 6px;
    display: flex;
    gap: 6px;
  }
}

.calendar-interview-modal {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__people {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    > span {
      font-size: 16px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.5rem;
    }

    &__text {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &__pint {
  }

  &__collab {
  }
}

.assistant-badge {
  height: 42px;
  width: 42px;
  position: relative;
  border-radius: 66%;
  display: grid;
  place-content: center;

  i {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.assistant-creator,
.assistant-collab {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
}

.assistant-candidate {
  background: #fff0f6;
  border: 1px solid #f759ab;

  span {
    color: #f759ab;
  }
}

.assistant-many-left {
  background: #f5f5f5;
  border: 1px dashed #d9d9d9;

  span {
    color: #d9d9d9;
  }
}
