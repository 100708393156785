@import '../../styles/variables.scss';

// Genomawork-BusinessManager
.gw-bm {
  font-family: 'Lato';

  // Card style
  .ant-card {
    background-color: $neutral-2;
    &-head {
      border: none;

      &-title {
        font-weight: 700;
        font-size: 1.9em;
      }
    }

    &-body {
      padding-top: 0;
    }
    
    .ant-table {
      background-color: $neutral-1;
      border-top: 1px solid $neutral-5;
      tr {
        border: 1px red;
      }

      .ant-table-thead, .ant-table-tbody {
        .ant-table-row, tr > th {
          background-color: $neutral-1;
        }
      }
    }
  }

  // Button group style
  .switch-table-button-group{
    button.ant-btn {
      background-color: $neutral-4;
      border-color: $neutral-5;
      color: #CCCCCC;
    }
    button.ant-btn.active {
      background-color: #FFFFFF;
      border-color: $neutral-5;
      color: #454545;
    }
  }

  // Table style
  .custom-table-title {
    *.ant-table-column-title {
      font-weight: normal;
      color: #454545;
    }
  }

  td {
    vertical-align: middle;
  }

  *.table__footer {
    animation: .2s forwards animate-footer;
    background: #2e2e2e !important;
    border: 1px solid $main-border-color;
    border-radius: 7px;
    bottom: 20px;
    box-shadow: $main-shadow;
    height: 88px;
    left: 0;
    margin: auto;
    max-width: 1215px;
    padding: 5px 25px 5px 25px;
    position: fixed;
    right: 0;
    width: calc(94% - 350px);
    z-index: 1000;

    display: flex !important;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 320px) {
      width: 360px;
      position: fixed;
      border-radius: 0%;
      bottom: 0;
    }

    @media screen and (max-width: 720px) {
      width: 360px;
    }

    @keyframes animate-footer {
      from {
        transform: translateY(100px);
      }

      to {
        transform: translateY(0px);
      }
    }

    .table__actions {
      width: 100%;
      
      .left {
        display: flex;

        .close-footer {
          border: none;
          background: transparent;
          box-shadow: 0 0 0 0;
          padding: 0;
          color: #ffffff;

          &:hover {
            background: #ffffff27;
          }
        }

        span.ant-typography {
          color: rgb(233, 233, 233);
        }
      }

      .right {
        display: flex;

        @media screen and (max-width: 720px) {
          display: flex;
        }

        @media screen and (max-width: 583px) {
          display: flex;
          width: 100%;
        }

        @media screen and (max-width: 320px) {
          justify-content: flex-start;
          padding: 15px;
        }
      }
    }

    button {
      border-color: $main-border-color;

      &:hover {
        color: #292929;
        filter: brightness(0.9);
      }
    }

    .ant-btn-primary {
      display: inline-flex;
      align-items: center;

      &:hover {
        color: #fff;
        opacity: 0.8;
      }

      img {
        width: 13px;
        margin-right: 4px;

        @media screen and (max-width: 583px) {
          position: absolute;
        }
      }
    }
  }

  // Edit dropdown
  *.edit-dropdown {
    &:hover {
      cursor: pointer;
    }
  }
}

.permission-modal {
  min-width: 50em;
}

.invitation-modal {
  min-width: 50em;

  .ant-modal-content {
    margin-top: -4em;
  }

}

.ant-modal {
  *.ant-modal-header {
    background-color: #FFFFFF;
  }
  
  .permission-avatar {
    background-color: $mainColor;
    margin-right: 1rem;
  }

  .modal-title {
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 0;
    margin-bottom: 0;
    color: #5E5E5E;
  }

  .permission-description {
    color: #A4A4A4;
  }

  .ant-select-selection.ant-select-selection--multiple {
    border-radius: 2px;
  }

  .ant-row {
    img.invite-collaborators-img {
      width: -webkit-fill-available;
    }
  }

  *.collaborator-select {
    width: -webkit-fill-available;
    height: 100%;

    .ant-select-selection--single {
      height: inherit;
    }
  }
}

.business-role-section{
  border: 1px solid #D9D9D9;
  background-color: #ffffff;
  width: 100%;
  min-height: 400px;
  padding: 16px;

  .role-section-title{
    color: #5E5E5E;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .role-section-button{
    margin-bottom: 15px;
    width: 100%;
  }
}