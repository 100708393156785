@import '../../../styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');


%img-common-styles-sb {
  display: flex;
  justify-content: space-between;

  .img-description {

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      border-color: $mainColor;
      color: $mainColor;
    }

    @media screen and (min-width: 450px) {
      min-width: 300px;
    }
  }
}

.sb__bg {
  @extend %img-common-styles-sb;
  display: initial;
  justify-content: none;

  %contrast {
    width: 250px;
    height: 150px;
    position: relative;
    border: 1px solid rgb(221, 221, 221);
  }


  .upload-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      span {
        background: #ffffffde;
      }
    }

    span {
      padding: 5px;
      border-radius: 7px;
      background: #ffffff9f;
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 250px;
    height: 150px;
    position: relative;

    img {
      width: 250px;
      height: 150px;
      object-fit: cover;
    }
  }

  .ant-upload.ant-upload-select-picture-card:hover {
    border-color: $mainColor;
  }

  &__preview {
    @media screen and (max-width: 999px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-home {
      @extend %contrast;
      margin-bottom: 10px;

      &-img {
        width: 100%;
        height: 100%;
      }
    }

    &__contain {
      width: 250px;
      height: 150px;
      position: relative;
      border: 1px solid #ccc;
    }

    &__img {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }

    &__block-back {
      height: 96px;
      width: 98px;
      z-index: 2;
      position: absolute;
      top: 10px;
      left: 150px;

      p {
        display: none;
      }
    }

    &__block-front {
      height: 88px;
      width: 105px;
      z-index: 3;
      position: absolute;
      top: 22px;
      left: 110px;
      background-color: #c4c4c4;

      p {
        display: none;
      }
    }

    &__block-bottom {
      height: 35px;
      width: 100%;
      z-index: 4;
      position: absolute;
      top: 113px;
      text-align: center;

      p {
        display: none;
      }
    }

    &__block-button {
      height: 8px;
      width: 20px;
      z-index: 5;
      position: absolute;
      top: 85px;
      left: 40px;

      p {
        display: none;
      }
    }

    &__block-title {
      height: 5px;
      width: 180px;
      z-index: 6;
      position: absolute;
      top: 118px;
      left: 40px;

      p {
        display: none;
      }
    }

    &__block-text {
      height: 4px;
      width: 140px;
      z-index: 6;
      position: absolute;
      top: 127px;
      left: 60px;

      p {
        display: none;
      }
    }

    &__block-circleLeft {
      height: 10px;
      width: 10px;
      z-index: 7;
      position: absolute;
      top: 134px;
      left: 80px;
      border: 5px solid;
      border-radius: 50px;
      color: #ffffff;

      p {
        display: none;
      }
    }

    &__block-circleMiddle {
      height: 10px;
      width: 10px;
      z-index: 8;
      position: absolute;
      top: 134px;
      left: 120px;
      border: 5px solid;
      border-radius: 50px;
      color: #ffffff;

      p {
        display: none;
      }
    }

    &__block-circleRight {
      height: 10px;
      width: 10px;
      z-index: 8;
      position: absolute;
      top: 134px;
      left: 160px;
      border: 5px solid;
      border-radius: 50px;
      color: #ffffff;

      p {
        display: none;
      }
    }

    &__span {
      span {
        color: #bebebe;
      }
    }

    .uploaded-bg {
      position: absolute;
      width: 100%;
      height: 74%;
      top: 8%;
      filter: brightness(40%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: #ffffff;
      }
    }

    sup {
      color: #bebebe;
    }
  }

  &__color {
    display: flex;
    padding: 1em 0;
    // margin: 0 10px;

    &__input {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .ant-typography {
        width: 150px;
        text-align: center;
      }

      input {
        width: 50px;
        height: 50px;
      }

      @media screen and (max-width: 999px) {
        margin-bottom: 1em;
      }
    }
  }
}