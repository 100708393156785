.profile-section {
  margin: 1.5rem 2.5rem 1.5rem 2.5rem;

  &-card {
    border: 1px solid #d9d9d9 !important;

    &__extra-info {
      @media screen and (max-width: 575px) {
        text-align: left;
      }

      @media screen and (min-width: 576px) {
        text-align: right;
      }
    }

    &__avatar-initials {
      border: 5px solid #F175A5;
      border-radius: 50%;
      color: #F175A5;
      font-size: 40px;
      height: 100px;
      padding: 0.33em;
      text-align: center;
      width: 100px;
    }

    &__cv {
      .cv-file-name {
        max-width: 20em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__cv-actions:hover {
      color: #F175A5;
      cursor: pointer;
    }

    &__data-row {
      margin-top: 0.75em;
      margin-bottom: 0;
    }

    &__update {
      border-color: #F175A5;
      color: #F175A5;

      @media screen and (max-width: 575px) {
        margin-top: 0.5em;
      }
    }

    &__update:hover {
      background-color: #F175A5;
      border-color: #F175A5;
      color: #FFF;
      cursor: pointer;
    }
  }
}

.edit-personal-data-modal {
  .profile-label {
    margin: 0;

    .ant-col.ant-form-item-label {
      line-height: 1em;
    }

    .gender-btns {
      display: flex;
      justify-content: space-around;
      text-align: center;
      width: 100%;
      padding-top: 0.25em;

      .ant-radio-button-wrapper {
        border-radius: 2px !important;
        min-width: 30%;
      }

      .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
        border: 1px solid #d9d9d9 !important;
      }

      .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background-color: #FFF0F6;
        border-color: #F175A5 !important;
        box-shadow: -1px 0 0 0 #F175A5;
        color: #F175A5;
      }

      .ant-radio-button-wrapper:hover {
        color: #F175A5;
      }
    }

    .info-card {
      margin-top: 1em;

      .ant-card-body {
        min-height: auto;
      }
    }
  }

  .full-width {
    width: max-content;
  }
}
