.alternatives-pdf-modal {
  // Hide the default header and footer in the native print setting usings margins under 10px
  @page {
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  @media print {
    html,
    body {
      height: 100vh;
    }
  }

  &__coverpage {
    height: 100vh;
    margin: 0px;
    &__container {
      height: 100%;
    }
  }

  &__mainsection {
    height: 90%;
    margin: 0px 30px;
    position: relative;
    page-break-inside: avoid;

    display: table;
    table-layout: fixed;

    h5 {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: bold;
      color: #454545;
    }

    h4 {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: bold;
      color: #454545;
    }

    &__summary {
      height: 100%;
      &__header {
        display: flex;
        flex-direction: column;
        &__candidateinformation {
          margin: 10px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h5 {
            color: #2b2b2b;
            font-weight: 700;
          }

          p {
            color: #5e5e5e;
            font-size: 10px;

            @media print {
              margin: 5px 0;
            }
          }
        }
      }
    }

    &__details {
      min-height: 100%;
      padding: 50px 0;
      &__answer {
        break-inside: avoid;
        display: inline-block;
        page-break-inside: avoid;
      }
    }
  }

  &__cute {
    padding: 5px 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    border: 1px solid #d9d9d9;

    // na
    overflow: auto;
    text-overflow: ellipsis;
    width: 520px;

    &__correct {
      border-color: #52c41a;
    }

    &__wrong {
      border-color: #ff4d4f;
    }
  }

  div.protected-div-cute-answer-pdf {
    page-break-after: always;
    page-break-inside: avoid;
    padding-top: 20px;
  }

  div.protected-div-container-pdf {
    display: block;
  }

  div.break-page {
    position: relative;
    page-break-inside: avoid;
    // exclude padding and fotter
    height: 85%;
  }

  div.break-before {
    page-break-before: always;
  }
}
