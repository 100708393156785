.welcome-container {
  &__header.shrinked {
    height: 80vh;
    transition: height 0.5s ease-in-out;
    @media screen and (max-width: 768px) {
      height: 25vh;
    }
  }
}

.welcome-container {
  &__header {
    transition: height 0.3s ease-in;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 768px) {
      height: calc(100vh);
    }
    @media screen and (max-width: 769px) {
      height: calc(35vh);
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
  }

  .welcome-background-image {
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    filter: brightness(70%);
    width: 100%;
    height: 100%;
  }

  .welcome-background-image-mobile {
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    filter: brightness(70%);

    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  .welcome-header-mobile {
    left: 0;
    position: absolute;
    text-align: center;
    top: 30px;
    width: 100%;
  }

  .support-card {
    margin: 1rem 0 4rem 0;
    padding: 0.5rem 1rem 0.5rem 2rem;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 2px;
    line-height: 20px;
    font-size: 13px;
    text-align: justify;

    i {
      color: #1890ff;
      font-size: 18px;
      position: absolute;
      left: 6px;
      top: 6px;
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 5.5rem;
    }
  }
}

.icons-header {
  display: flex !important;
  justify-content: space-around;
  @media screen and (min-width: 768px) {
    margin: 1.5rem 0;
  }
  @media screen and (max-width: 769px) {
    margin: 0.3rem 0;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      line-height: 30px;
      font-size: 25px;
    }

    span {
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: #f0f0f0;
    }
  }

  &__separator {
    background-color: #f0f0f0;
    width: 1px;
  }
  &__title {
    background-color: #f0f0f0;
    width: 100px;
  }
}

.welcome-custom-tabs {
  mix-blend-mode: darken;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 3px 5px rgb(140 140 140 / 10%);

  > div {
    width: 95%;
    max-width: 450px;
    height: 46px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;

    > div {
      position: relative;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      color: #828282;
    }

    .selected-tab {
      font-weight: bold;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -13px;
        left: 0;
        background-color: var(--bu-theme-color);
      }
    }
  }
}

.start-process-div-button {
  background-color: #ffffff;
  padding: 12px 0px 12px 0px;

  @media screen and (min-width: 768px) {
    width: 100% !important;
  }

  > div > div > button {
    width: 100%;
    &:hover {
      opacity: 0.8;
    }
  }
}

.start-process-button {
  min-width: 150px;
  button {
    min-width: 300px;
    &:hover {
      opacity: 0.8;
    }
  }
}

.welcome-other-processes {
  margin-top: 2rem;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;

    > span {
      font-size: 13px;
      line-height: 20px;
      color: #828282;
    }
  }
}

.welcome-social-networks {
  margin-top: 2rem;
  display: flex !important;
  flex-direction: column;

  a {
    margin-top: 1.5rem;
    color: #828282;

    span {
      margin-left: 10px;
      font-size: 13px;
    }
  }
}

.welcome-text-start-process {
  > div {
    > p {
      margin-top: 10px;
      line-height: 1.6;
    }
  }
}
