.account-circle {
  display: flex;
  margin: 0px;
  align-items: center;
  justify-content: center;
  border: solid 1px #d9d9d9;
  padding: 30px;
  width: 68px;
  height: 68px;
  border-radius: 100%;
}

.account-config-container {
  padding: 2rem;
}

.account-base-card {
  border: 1px solid #d9d9d9;
  background: #ffffff;
  padding: 16px;
  box-shadow: 0px 2px 6px rgba(90, 90, 90, 0.07);
}

.account-mfa-card {
  background: #fff0f6;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
}

.account-calendar {
  height: calc(100vh - 64px);
  padding: 40px;
  display: flex;
  flex-direction: column;

  // back button
  > button {
    padding: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    i {
      color: #5e5e5e;
      font-size: 20px;
    }

    span {
      color: #2b2b2b;
      font-size: clamp(20px, 3vw, 30px);
      font-weight: 700;
    }
  }

  &__no-sync {
    flex-grow: 1;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    position: relative;
  }

  &__presentation {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    > p {
      font-weight: 700;
      color: #454545;
    }

    // img background
    > img:last-child {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    &__steps {
      display: flex;
      width: 100%;

      &__step {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          height: 46px;
          width: 46px;
          border-radius: 66%;
          display: grid;
          place-content: center;
          color: #ffffff;
          font-weight: 700;
          margin-bottom: 12px;
        }

        > p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #454545;
          padding-inline: 2.5rem;
        }
      }
    }
  }

  &__first-step {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
      margin-block: 1rem;
      font-size: 14px;
      color: #000000;
    }

    &__calendar {
      width: 210px;
      height: 60px;
      padding-inline: 1rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      background: #fafafa;
      border: 1px solid #bfbfbf;
      border-radius: 2px;
      cursor: pointer;

      span {
        margin-left: 1rem;
        color: #454545;
      }
    }
  }

  &__second-step {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
      font-size: 18px;
      color: #454545;
      margin-block: 1.5rem;
    }
  }
}
