@import '../../../../../../../../../../../styles/variables.scss';

.game-description {
  margin-top: 20px;

  .ant-modal-content {
    border-radius: 15px !important;
    padding: 0;

    b {
      color: #424242;
    }

    .ant-modal-body {
      padding: 30px;
      min-height: 75vh;
      max-height: 75vh;
      overflow-x: auto;
      @extend %scroll-bar;
    }
  }

  .added-btn, .added-btn:hover {
    background: $mainColor;
    cursor: default;
  }

  .headline {
    text-align: center;
    margin-bottom: 2em;

    h4 {
      margin: 0;
    }

    span.ant-typography {
      font-size: 14px;
    }

    img {
      width: 100%;
      max-width: 80px;
      border-radius: 100%;
      margin: auto 5px;
    }

    .ant-tag {
      margin-top: 1.5em;
      background: $main-bg;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      color: rgb(95, 95, 95);
      font-weight: 600;

      i {
        margin-right: 3px;
      }
    }
  }

  button.ant-modal-close {
    color: $primary-color;
    top: 10px;
    right: 10px;

    .ant-modal-close-x {
      width: 36px;
      height: 36px;
      line-height: 36px;
    }
  }

  .description {
   .title {
    margin-bottom: 7px;
      
      span.ant-typography {
        color: #2b2b2b;
      }
    }
    .trait {
      padding: 15px 15px 0;
      background: $main-bg;
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }

  .ant-modal-footer {
    padding: 0;

    footer {
      display: flex;
      padding: 25px 30px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;

      button {
        border-radius: 6px;
      }

      .left-side {
        span.ant-typography {
          margin-left: 10px;
          color: #fff;
          letter-spacing: 3px;
        }
        button, button:hover, button:focus, button:active {
          border: 1px solid $main-border-color;
          color: #666;
        }
        button:hover {
          filter: brightness(0.8);
        }
      }

      .right-side {
        button, button:hover, button:focus, button:active {
          color: #fff;
          border: none;
          transition: 0s all;
          box-shadow: $main-shadow;

          @media screen and (min-width: 765px) {
            min-width: 12em;
          }
        }
      }
    }
  }
}