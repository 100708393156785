.page-logo {
  max-width: 200px;
}

.protected {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ffffff;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #ffffff;
}
.container:before,
.container:after {
  content: ' ';
  display: table;
}

.container:after {
  clear: both;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 100%;
    background-color: #ffffff;
  }
}

// .text-center {
//   text-align: center;

//   .privacy-link {
//     font-size: 14px;
//     color: rgb(73, 66, 66);
//     &:hover {
//       color: #f175a5;
//     }
//   }
// }

.home__container {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    padding: 2em;
    height: 83%;
    background: #ffffff;
    box-shadow: 0px 30px 80px rgba(180, 0, 95, 0.06);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    h2 {
      color: #786d73;
      text-align: center;
      margin: 0;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (max-width: 614px) {
      > span {
        width: 100%;
        justify-content: center;
      }
    }

    button {
      padding: 0 2px;
      color: #f175a5;
    }

    span {
      font-size: 13px;
      line-height: 17px;
      display: flex;
      align-items: center;
    }
  }
}

.home-layout {
  background-size: cover;
  height: 100%;
}

.home__center {
  display: flex;
  flex-direction: column;
  height: 176px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.button-antd {
  position: relative;
  width: 80%;
  max-width: 439px;

  @media screen and (max-width: 600px) {
    width: 95%;
  }
}