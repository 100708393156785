@page {
  size: A4 portrait;
}
.report-pdf-modal {
  .ant-modal-body {
    padding: 0;
  }

  p,
  h4 {
    color: #454545;
  }

  p,
  h4 {
    line-height: 1;
  }

  .t18 {
    font-size: 18px;
    font-weight: bold;
  }

  .t12 {
    font-size: 12px;
  }

  .t10 {
    font-size: 10px;
  }
}

.report-pdf {
  .ant-divider {
    margin: 0;
  }
  @media print {
    p,
    h4 {
      color: #454545;
    }

    .no-print {
      display: none;
    }

    .t18 {
      font-size: 18px;
      font-weight: bold;
      color: #2b2b2b;
    }

    .t12 {
      font-size: 12px;
    }

    .t10 {
      font-size: 10px;
    }

    .page {
      height: 1111px;
      padding: 1.5rem 0;
    }

    // Don t break the page inside a div
    .protected-div {
      page-break-inside: avoid;
    }

    // .break-first-page {
    //   position: relative;
    //   page-break-inside: avoid;
    //   height: 98%;
    // }

    .break-page {
      position: relative;
      page-break-inside: avoid;
    }

    .break-before {
      page-break-before: always;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .svg-progress-circle-bg {
    fill: none;
  }

  .svg-progress-circle {
    fill: none;
  }

  .svg-progress-circle-text {
    font-family: 'Lato';
    font-size: 23px;
    line-height: 28px;
    text-anchor: middle;
    fill: #433d40;
    font-weight: bold;
  }

  .first-page {
    padding-block: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    background-color: white !important;

    &__img {
      padding: 0 2rem;
      width: 250px;
    }

    &__text {
      padding: 0 3rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__summary {
      margin-left: 25%;
      background-color: #fff0f6;
      height: 838px;
      border-top-left-radius: 40px;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      &__data {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }

      &__evaluations {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        ul {
          list-style-type: disc;
          list-style-position: inside;
          padding: 0 0.5rem;
          font-size: 12px;
        }
      }

      &__footer-text {
        margin-top: auto;
        text-align: right;
      }
    }
  }
}

.report-pdf-header,
.report-pdf-footer {
  display: none;

  @media print {
    width: 100%;
    font-size: 12px;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;
  }
}

.report-pdf-footer {
  @media print {
    bottom: 0;
  }
}

.report-pdf-header {
  top: 0;
}

.report-pdf-traits-page {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__header {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 2rem;

    &__texts {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  .show-header {
    display: none;

    @media print {
      display: flex;
    }
  }

  &__content {
  }
}

.report-pdf-trait {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .config-preview {
    height: 36px;
    padding: 0 6px;

    svg {
      margin: none;
      -webkit-transform: none;
    }
  }

  .config-printing {
    height: 40px;
    padding: 0 1px;

    svg {
      margin-left: 54px;
      -webkit-transform: scale(1.365, 1); /* Safari and Chrome */
    }
  }
}

.report-pdf-kos {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.report-pdf-videointerview {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  // .ant-progress-show-info .ant-progress-outer {
  //   padding: 0;
  //   margin: 0;
  // }

  &__global-eval {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__recommendation {
    display: flex;
    align-items: center;
    gap: 1rem;

    .t12 {
      width: 180px;
    }
  }

  &__questions {
    padding: 1.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;

    &__final-score {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
    }

    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__question {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      > p:first-child {
        flex-grow: 1;
      }
    }
  }
}

.report-pdf-reference {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  font-size: 12px;
}

.report-pdf-interview-kits {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__group-question {
    > h6 {
      margin-top: 1.5em;
      font-weight: bold;
      font-size: 14px;
      color: #8c8c8c;
    }
    div {
      margin: 1.2em 0;
      ul {
        list-style-type: disc;

        li {
          margin-left: 2em;
        }
      }
      p:nth-child(1) {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 5px;
      }
      > p {
        font-size: 14px;
      }
      > span {
        font-size: 14px;
      }
    }
  }

  .scores-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .scorecard {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;

    p {
      font-size: 12px;
      line-height: 11px;
      color: #1a000e;
      margin-bottom: 1em;
    }
  }
}
