$mainColor: #f175a5;

.references-view {
  &__title {
    height: auto;
    background: #fff !important;
    border-top: 1px solid rgb(236, 236, 236);

    .ant-typography {
      padding-top: 30px;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 450px) {
        margin-top: 25px;
        font-size: 14px;
      }
    }
  }

  &__card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-collapse-header {
      margin-left: 15px;
      padding-top: 20px !important;
      padding-bottom: 20px !important;

      .header-titles {
        margin-left: 1em;
        display: flex;
        flex-direction: column;

        span>strong {
          font-size: 16px;
        }
      }
    }

    .reference-relationship {
      .ant-form-item-children {
        .ant-form-item-children-icon {
          margin-top: 10px;
        }
      }
    }

    .ant-btn-secondary {
      margin-top: 20px;
    }

    .ant-btn-secondary:hover,
    .ant-btn-secondary:focus,
    .ant-btn-secondary:active {
      border-color: $mainColor;
      color: $mainColor;
    }


    .ant-card-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #ffffff;
      border: none;

      .ant-btn-primary:hover {
        opacity: 0.7;
      }

      .ant-btn-dashed {
        margin-right: 40%;
      }

      .ant-btn-dashed:hover,
      .ant-btn-dashed:focus,
      .ant-btn-dashed:active {
        color: $mainColor;
        border-color: $mainColor;
      }
    }
  }

  .references-sent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10%;

    @media screen and (max-width: 450px) {
      font-size: 11px;
    }
  }

}