.dat-pdf-modal {
  // Hide the default header and footer in the native print setting usings margins under 10px
  @page {
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  @media print {
    html,
    body {
      height: 100vh;
    }
  }

  &__coverpage {
    height: 100vh;
    margin: 0px;
    background: white;

    &__container {
      height: 100%;
      background: white;

      &__logos {
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__firstcolor {
        height: 75%;
        background: #ff9cd2;
        border-end-end-radius: 50%;
      }

      &__secondcolor {
        background: linear-gradient(to right bottom, #ff8ccb, #ff81c6, #ff75c0, #ff68ba, #ff5ab4);
        border-end-end-radius: 80%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
          color: white;
          margin: 0 0 0 20px;
          font-size: 70px;
          padding: 0;
        }
      }
    }
  }

  &__mainsection {
    height: 90vh;
    margin: 0px 30px;
    position: relative;
    page-break-inside: avoid;
    height: 100%;

    &__page1 {
      height: 90vh;
      &__header {
        display: flex;
        flex-direction: column;

        &__candidateinformation {
          margin: 10px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h5 {
            color: #2b2b2b;
            font-weight: 700;
          }

          p {
            color: #5e5e5e;
            font-size: 10px;

            @media print {
              margin: 5px 0;
            }
          }
        }
      }

      &__description {
        margin: 0 0 20px 0;

        &__title {
          display: flex;
          align-items: center;

          h6 {
            margin: 0 0 0 10px;
            font-weight: 700;
            color: #454545;
          }
        }

        &__text {
          margin: 10px 0 0 0;
          font-size: 12px;
          color: #5e5e5e;
          line-height: 20px;
          text-align: justify;
        }
      }

      &__scores {
        margin: 20px 0 0 0;

        h6 {
          font-weight: 700;
          color: #454545;
        }

        &__graphics {
          display: flex;
          align-items: flex-end;

          &__low {
            width: 166px;
            height: 82px;
            border: 1px solid #ff4d4f;

            &__up {
              height: 50px;
              border-bottom: 1px solid #ff4d4f;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &__below {
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;

              p {
                color: #ff4d4f;
              }
            }
          }

          &__intermediate {
            margin: 0px 20px;
            width: 166px;
            height: 102px;
            border: 1px solid #faad14;

            &__up {
              height: 70px;
              border-bottom: 1px solid #faad14;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &__below {
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;

              p {
                color: #faad14;
              }
            }
          }

          &__high {
            width: 166px;
            height: 122px;
            border: 1px solid #52c41a;

            &__up {
              height: 90px;
              border-bottom: 1px solid #52c41a;
              display: flex;
              justify-content: center;
              align-items: center;

              h4 {
                color: white;
              }
            }

            &__below {
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;

              p {
                color: #52c41a;
              }
            }
          }
        }
      }

      &__results {
        margin: 30px 0 0 0;
        border: 1px solid #d9d9d9;

        &__high {
          height: 90px;
          width: 100%;
          background: #f6ffed;
          border-bottom: 1px solid #d9d9d9;
          display: flex;

          &__title {
            font-size: 12px;
            width: 20%;
            padding: 15px;
            border-right: 1px solid #d9d9d9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
              color: #52c41a;
            }
          }

          &__content {
            width: 80%;
            font-size: 12px;
            line-height: 20px;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: justify;
          }
        }

        &__intermediate {
          height: 90px;
          width: 100%;
          border-bottom: 1px solid #d9d9d9;
          display: flex;

          &__title {
            font-size: 12px;
            width: 20%;
            padding: 15px;
            border-right: 1px solid #d9d9d9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
              color: #faad14;
            }
          }

          &__content {
            width: 80%;
            font-size: 12px;
            line-height: 20px;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: justify;
          }
        }

        &__low {
          height: 90px;
          width: 100%;
          display: flex;

          &__title {
            font-size: 12px;
            width: 20%;
            padding: 15px;
            border-right: 1px solid #d9d9d9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
              color: #ff4d4f;
            }
          }

          &__content {
            width: 80%;
            font-size: 12px;
            line-height: 20px;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: justify;
          }
        }
      }
    }

    &__page2 {
      height: 90vh;
      &__header {
        display: flex;
        flex-direction: column;
      }

      &__question {
        h6 {
          color: #454545;
          font-weight: 700;
          margin: 0 0 5px 0;
        }

        p {
          color: #5e5e5e;
          font-size: 12px;
          line-height: 20px;
          text-align: justify;
        }
      }

      &__explanation {
        h6 {
          margin: 20px 0 5px 0;
          color: #454545;
          font-weight: 700;
        }

        p {
          color: #5e5e5e;
          font-size: 12px;
          line-height: 20px;
          text-align: justify;
        }
      }

      &__segments {
        display: flex;
        justify-content: space-between;

        @media print {
          justify-content: center;
        }

        &__box {
          margin: 20px 0 0 0;

          @media print {
            margin: 20px 20px 0 20px;
          }

          width: 166px;
          height: 110px;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &__colorbar {
            display: flex;
          }

          p {
            font-size: 12px;
            color: #5e5e5e;
          }
        }
      }

      &__heatmap {
        height: 300px;
        margin: 20px 0 0 0;

        @media print {
          margin: 100px 0 0 100px;
        }
      }
    }

    &__page3 {
      height: 90vh;
      &__header {
        display: flex;
        flex-direction: column;
      }

      &__question {
        display: flex;
        flex-direction: column;

        h6 {
          color: #454545;
          font-weight: 700;
          margin: 0 0 5px 0;
        }

        p {
          color: #5e5e5e;
          font-size: 12px;
          text-align: justify;
          line-height: 20px;
          margin: 0 0 20px 0;
        }
      }
    }

    &__page4 {
      height: 90vh;
      &__header {
        display: flex;
        flex-direction: column;
      }

      &__references {
        h6 {
          color: #454545;
          font-weight: 700;
          margin: 0 0 10px 0;
        }

        li {
          font-size: 12px;
          margin: 10px 0;
          line-height: 20px;
        }
      }
    }
  }
}
