@import '../../../../../../NewProcessForm.scss';
$ideal-genome: $primary-color;

.ig-config {
  margin-bottom: 4em;

  .chart-tooltip {
    box-shadow: $main-shadow;
    padding: 4px 8px;
    background: #fff;
  }

  &__switch {
    padding: 15px;
    border-radius: 6px;
    background: $main-bg;

    .ant-switch {
      margin-right: 7px;
      margin-bottom: 3px;
    }

    .ant-switch-checked {
      background: $primary-color;
    }

    span.ant-typography {
      &:last-child {
        padding-top: 10px;
        font-size: 14px;
      }
    }
  }

  .ant-card {
    margin-bottom: 1em;
    box-shadow: $main-shadow;

    .trait-slider {
      margin-top: 1em;

      span.ant-typography {
        font-size: 15px;
      }

      .ig-percent {
        display: grid;
      }
    }
  }

  // ANTD
  .with-distribution {
    .slider {
      .ant-slider {
        margin-bottom: 8px;
      }

      .mark {
        width: 14px;
        height: 26px;
        position: absolute;
        z-index: 1;
        top: -8px;
        right: auto;
        transform: translateX(-50%);

        img {
          height: 100%;
          pointer-events: none;
        }
      }
    }
  }

}

.trait-slider {

  &__action {
    width: 100%;
    .ant-slider {
      margin: 0;
      padding-top: 0;
    }

    .ant-slider-track {
      display: none !important;
    }

    .ant-slider-rail,
    .ant-slider:hover .ant-slider-rail {
      background: #f5f5f5 !important;
    }

    .ant-slider-rail {
      height: 10px;
    }

    .ant-slider-handle {
      background: $ideal-genome;
      -webkit-box-shadow: 0 0 0 0;
      box-shadow: 0 0 0 0;
      width: 20px;
      height: 20px;
      margin-top: -5px;
      margin-left: -2px;
      z-index: 9;
    }

  }
}

.trait-name-popover {

  .ant-popover-inner,
  .ant-popover-arrow {
    background: #222222 !important;
    border-color: #222222 !important;
  }

  .ant-popover-inner-content {
    background: #222222;
    border-radius: 18px !important;
    color: rgb(240, 240, 240) !important;
  }
}
