.layout-job-portal {
  /* HEADER BACKGROUND */
  .header-background {
    background: linear-gradient(0deg, rgba(3, 8, 82, 0.6), rgba(3, 8, 82, 0.6)), url(https://genoma-assets.s3.us-east-2.amazonaws.com/people-working.png);
    background-size: cover;
    background-position: 50%;
    height: 180px;
    @media all and (max-width: 575px) {
      height: 90px;
    }
    h1 {
      color: white;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.7);
      font-size: 42px;
      margin: 0;
      padding: 0;
      @media all and (max-width: 575px) {
        font-size: 30px;
      }
    }
  }
  .common-header {
    flex-direction: column;
    margin: 20px 146px 0 146px;
    h1 {
      margin: 20px 0 0 0;
      padding: 0;
    }
    @media all and (max-width: 1200px) {
      margin: 20px 40px 0 40px;
    }
    @media all and (max-width: 768px) {
      margin: 20px 20px 0 20px;
    }
  }

  .content-job-portal {
    margin: 20px 146px;
    @media all and (max-width: 1200px) {
      margin: 20px 40px;
    }
    @media all and (max-width: 768px) {
      margin: 20px;
    }
    /* FILTERS */
    .jobs-search {
      margin: 0 0 10px 0;
      .input-filter {
        height: 80px;
        .ant-row-flex {
          height: 100%;
          flex-direction: column;
        }
        input {
          height: 40px;
        }
        .ant-select-selection {
          height: 40px;
        }
      }
    
      .button-filter {
        height: 80px;
        .ant-row-flex {
          height: 100%;
          .ant-btn-primary {
            width: 100%;
            height: 40px;
          }
        }
        @media all and (max-width: 766px) {
          width: 100%;
        }
      }
    }
    /* JOB INFO  */
    .ant-modal {
      padding: 10px;
    }
    .ant-modal-content {
      .ant-modal-body {
        padding: 10px;
      }
    }
     /* PAGINATION */
    .pagination {
      min-height: 60px;
      margin: 20px 0;
    }
    /* MORE JOBS BUTTON */
    .more-jobs-buttons {
      margin: 20px 0 60px 0;
      .button-jobs {
        background: transparent;
        border: 1px solid #F175A5;
        color: #F175A5;
        &:hover {
          background: #F175A5;
          color: white;
          border: 1px solid #F175A5;
        }
      }
    }
    /* LOCATION COUNTRY */
    .location-country {
      margin: 40px 0 40px 0;
      background-color: #FFFBE6;
      padding: 10px;
      border: 1px solid #FFE58F;
      .flag-text {
        font-size: 16px;
        flex-wrap: wrap;
        img {
          margin: 0 8px 0 0;
          width: 30px;
          height: 22px;
        }
      }
      .button-modal {
        button {
          font-size: 16px;
        }
      }
      @media all and (max-width: 992px) {
        justify-content: center;
        .flag-text {
          justify-content: center;
          text-align: center;
        }
        .button-modal {
          button {
            // font-size: 14px;
            padding: 0;
          }
        }
      }
    }
  }
}
