.custom-notification-container {
  margin-left: 15px;
  margin-right: 25px;
  padding: 5px 0px;
  cursor: pointer;
}

.custom-notification-badge {
  > sup {
    > span {
      > p {
        line-height: 1.5;
        color: #fff;
      }
    }
  }
}

.popover-notification-content {
  max-height: 600px;
}

.custom-notification-item {
  display: flex;
  padding: 10px 16px 10px 16px;
  margin-left: -16px;
  margin-right: -16px;
  cursor: pointer;
}
.custom-notification-item:hover{
  background-color: #F0F5FF;
}

.notifications-collapse {
  background: transparent !important;

  *.ant-collapse-item {
    border-bottom: 0px !important;

    *.ant-collapse-header {
      padding: 0px !important;
      padding-left: 2em !important;
      margin-bottom: 0px !important;

      .ant-collapse-arrow {
        left: 0px !important;
        z-index: 1 !important;
      }
    }
  }
}
