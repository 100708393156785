// Step 1
.job-application-form {
  padding: 40px 10px 0px 40px;
  .breadcrumbs-button {
    cursor: pointer;
  }

  .jobapp-info-container {
    padding: 20px;
    background-color: #d6e4ff;
    border-radius: 16px;
  }
  .jobapp-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    padding-left: 10px;
  }
  .jobapp-section {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 18px;
    margin-bottom: 30px;
    .jobapp-section-title {
      font-weight: 700;
      font-size: 20px;
      color: #454545;
      margin-bottom: 30px;
    }
    .jobapp-section-label {
      font-weight: 700;
      font-size: 16px;
      color: #5e5e5e;
    }
  }

  .jobapp-grid-gap {
    display: grid;
    grid-gap: 10px 10px;
  }

  .jobapp-video-providers {
    display: flex;
    > div {
      height: 38px;
      width: 100px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60px auto;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      margin: 5px;
      padding: 20px;
    }

    :nth-child(1) {
      background-image: url('https://genoma-assets.s3.us-east-2.amazonaws.com/youtube.png');
    }
    :nth-child(2) {
      background-image: url('https://genoma-assets.s3.us-east-2.amazonaws.com/vimeo.png');
    }
  }
  .confidential-info {
    background-color: #e6f7ff !important;
    width: 100%;
    padding: 0.5rem;
    height: auto;
    border: 1px solid #91d5ff !important;
  }
  .laptop {
    position: relative;
    margin: auto;
    max-width: 80rem;
    max-height: 60rem;

    .laptop-screen {
      position: relative;
      z-index: 6;
      padding: 3%;
      border-radius: 2rem;
      border: 2px solid #ccc;
      max-width: 100%;
      width: 806px;
      background: #ffffff;
      box-shadow: 0px 0px 14.4932px rgba(0, 0, 0, 0.15);
      border-radius: 26.1426px;
    }
    .laptop-content {
      display: block;
      max-width: 100%;
      height: auto;
      position: relative;
      overflow-y: scroll;
      z-index: 4;
      border: 0.522852px solid #cccccc;
      border-radius: 10.457px;
    }
    .navbar {
      max-width: 100%;
      height: auto;
      background-color: #ffffff;
      position: relative;
      z-index: 2;
      padding: 0.5rem;
      padding-left: 1rem;
    }
    .logo {
      height: 1rem;
      width: auto;
    }
    .flag {
      height: 0.6rem;
      width: auto;
      padding-left: 0.2rem;
    }
    .start-process-button {
      min-width: 5rem;
      button {
        min-width: 7rem;
        max-height: 1rem;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .job-app-information {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: -1;
      max-width: 100%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .job-app-information::before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgba(0, 0, 0, 0.25);
    }

    .jobapp-data {
      height: 100%;
      min-height: 24rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .smartphone {
      width: 14rem;
      height: 24rem;

      background: #ffffff;
      box-shadow: 0px 0px 14.4932px rgba(0, 0, 0, 0.15);
      border-radius: 20.9141px;
    }

    .inner-margin {
      position: relative;
      display: block;
      width: 12rem;
      height: 20rem;
      left: 1em;
      top: 2em;
      overflow-y: hidden;
      border: 0.522852px solid #cccccc;
      border-radius: 10.457px;
      object-fit: cover;
      position: absolute;
    }
    .content-mobile-preview {
      padding: 3%;
      min-height: 7rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: var(--backgroundImage);
    }
    .content-mobile-preview::before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.25);
    }

    .mobile-buttons {
      max-height: 0.5rem;
      min-width: 5rem;
      margin-bottom: 0.8rem;
      top: 0.3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      button {
        cursor: default;
      }
    }

    .mobile-info {
      font-size: 10px !important;
      position: relative;
      top: 0.5rem;
      padding: 1rem;
    }
  }

  .jobapp-types-radio {
    .ant-radio-group {
      width: 100%;
      display: flex;

      .ant-radio-button-wrapper {
        flex-grow: 1;
        padding: 1rem;
      }
    }

    &__type {
      display: flex;
      align-items: center;
      gap: 1rem;

      i {
        font-size: 2rem;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 16px;
        text-align: justify;
      }
    }
  }
}

// Step 2
.jobapp-evaluations {
  .jobapp-stage-summary {
    width: 280px;
    height: 45px;
    border: 1px solid #f0f0f0;
    border-radius: 2px 2px 0px 0px;
    background-color: #ffffff;
  }
  .jobapp-stages {
    width: 100%;
    min-height: 80px;
    border-radius: 0px;
    overflow-x: auto;
    display: flex;
    .jobapp-stage {
      background-color: #ffffff;
      height: 100%;
      min-width: 210px;
      //max-width: 210px;
      border-radius: 0px;
      padding: 18px 15px;
      border-radius: 20px 20px 0px 0px;
      border: 1px solid #f0f0f0;
      border-bottom: 0px;
      border-left: 0px;

      .stage-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 2px;
        cursor: pointer;
      }
    }

    .jobapp-stage:hover .move-stage-button__left {
      visibility: visible;
    }
    .jobapp-stage:hover .move-stage-button__right {
      visibility: visible;
    }

    .add-stage-button {
      left: -webkit-calc(100% - 12px);
      left: -moz-calc(100% - 12px);
      left: calc(100% - 12px);
      position: absolute;
      top: 28px;
      z-index: 50;
      padding-bottom: 10px;
    }
    .add-stage-button-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      border-radius: 20px 20px 0px 0px;
      border: 1px solid #f0f0f0;
      background-color: #ffffff;
    }
    .move-stage-button {
      &__left {
        visibility: hidden;
        left: -webkit-calc(50% - 24px);
        left: -moz-calc(50% - 24px);
        left: calc(50% - 24px);
        position: absolute;
        top: 0px;
        z-index: 50;
      }
      &__right {
        visibility: hidden;
        left: -webkit-calc(50% + 12px);
        left: -moz-calc(50% + 12px);
        left: calc(50% + 12px);
        position: absolute;
        top: 0px;
        z-index: 50;
      }
    }
    .disabled {
      background-color: #f2f2f2;
      color: #a4a4a4 !important;
      border-color: #e9e9e9;
      font-weight: 400;
      > div {
        :nth-child(1) {
          cursor: default;
        }
      }
    }
    .active {
      border-color: #d6e4ff;
      background-color: #d6e4ff;
      > div {
        :nth-child(1) {
          color: #597ef7 !important;
          font-weight: 600;
        }
        :nth-child(2) {
          color: #85a5ff !important;
          font-weight: 400;
        }
      }
      z-index: 40;
    }
    .active::before {
      content: '';
      position: absolute;

      background-color: transparent;
      bottom: -0px;
      height: 50px;
      left: -25px;
      width: 25px;
      border-bottom-right-radius: 25px;
      box-shadow: 0px 25px 0 0 #d6e4ff; /* This is where the magic happens! */
    }
    .active::after {
      content: '';
      position: absolute;

      background-color: transparent;
      bottom: -0px;
      height: 50px;
      right: -25px;
      width: 25px;
      border-bottom-left-radius: 25px;
      box-shadow: 0px 25px 0 0 #d6e4ff; /* This is where the magic happens! */
      // This is necesary dunno why
      z-index: 1;
    }
  }

  .evaluations-step-content-container {
    background-color: #d6e4ff;
    padding: 20px 20px 20px 20px;
    border-radius: 0px 0px 16px 16px;
  }

  .evaluations-step-content {
    margin-bottom: 20px;
    background: #ffffff;
    border-top: none;
    padding: 20px;
    border-radius: 8px;
    .evaluations-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 10px;
    }
    .evaluations-sub-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
    .evaluations-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin: 20px 0px 0px 0;

      .evaluations-item {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      @mixin base-eval-button {
        text-align: center;
        min-height: 34px;
        width: 100%;
        padding: 10px 6px;
        cursor: pointer;
      }
      .evaluations-button {
        @include base-eval-button;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        &__active {
          @include base-eval-button;
          background: #fff0f6;
          color: #f175a5;
          border: 1px solid #f175a5;
          border-radius: 2px;
        }
        &__disabled {
          @include base-eval-button;
          background: #f5f5f5;
          color: #cccccc;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          cursor: not-allowed;
        }
      }
    }
    .evaluation-detail {
      background: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 15px;
      min-height: 250px;
      .evaluation-add-button {
        background: #f175a5;
        border: 1px solid #f175a5;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
        color: #ffffff;
      }
      .evaluation-added-button {
        background: #ffffff;
        border: 1px solid #52c41a;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
        color: #52c41a;
      }
      .evaluation-delete-button {
        background: #ffffff;
        border: 1px solid #ff4d4f;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
        color: #ff4d4f;
      }
    }
    .evaluations-search-input {
      height: 42px;
      min-width: 300px;
    }
  }
}

//Step 3
.jobapp-preview {
  padding: 40px;
  .tab {
    .ant-tabs-tab-active {
      color: #597ef7 !important;
    }
  }
  .results-popover,
  .activity-popover,
  .stage-popover {
    max-width: 290px;

    .ant-popover-inner {
      border-radius: 7px !important;
    }

    .ant-popover-inner-content {
      box-shadow: 20px 0px 70px rgba(0, 0, 0, 0.06),
        -20px 0px 70px rgba(0, 0, 0, 0.06) !important;
    }
  }
  .jobapp-preview-section {
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
      height: 60px;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
      border-radius: 16px 16px 0px 0px;
      height: 60px;
      border: 'None';
      padding-top: 8px;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
      /* For tabs border */
      background: #d6e4ff;
      color: #597ef7;
      border-color: #d6e4ff;
    }
  }

  .stage-popover {
    .ant-popover-arrow {
      border-top-color: #2dc4f1 !important;
      border-left-color: #2dc4f1 !important;
    }
    .ant-popover-inner {
      background: #2dc4f1;
    }
    .ant-popover-inner-content {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;

      span {
        color: white;
        text-align: center;

        &:first-child {
          font-size: 18px;
          line-height: 1.3;
          margin-bottom: 10px;
          display: inline-block;
        }

        &:last-child {
          font-size: 16px;
        }
      }
    }
  }
}

// Extras
.share-process-modal {
  .ant-modal-body {
    padding: 0px;
  }
  .share-process-modal-header {
    background-color: #7e7e7e;
    font-size: 18pt;
    border-radius: 4px 4px 0px 0px;
    height: 140px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .share-process-modal-content {
    margin: 15px;
    > span {
      font-size: 16px;
    }
  }
}

// Menu is a portal and does not have  a real parent
.added-evaluation-current-stage {
  background-color: #597ef7;
  height: 42px;
  width: 100%;
  border-radius: 2px;
  padding: 10px 15px 10px 15px !important;
  color: #ffffff;
  font-size: 16px;
}
.added-evaluation-dropdown-menu {
  background-color: #597ef7 !important;
  padding: 10px 15px 10px 15px;
  font-weight: 400;
  border-radius: 2px;

  > div {
    line-height: 22px;
    color: #f5f5f5;
    margin-left: 5px;
  }
}
