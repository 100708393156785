.not-completed-request{
    height: 80%;
    border: 3px solid #D9D9D9;
    border-Radius: 5px;
    background-color: #FAFAFA;
    padding: 0.5em
}

.completed-request{
    height: 80%;
    border: 3px solid #FAAD14;
    border-Radius: 5px;
    background-color: #FFF1B8;
    padding: 0.5em
}

.not-completed-image {
    filter: grayscale(0.90)
}

.file-uploader {
    .ant-upload.ant-upload-drag {
        height: auto;
    }

    .ant-upload-list {
        color: #1890ff;
    }
}
