@import '../../../../styles/variables.scss';

.pj__job-form {
  position: relative;
  width: 600px !important;
  transition: 0s all !important;

  .ant-modal-header {
    background: #fff;
    padding-bottom: 0;

    h4 {
      margin-bottom: 15px !important;
    }
  }

  .ant-modal-body {
    padding: 0;
    max-height: 77vh;
    min-height: 77vh;
  }

  .ant-steps {
    padding: 0 !important;
    
    .ant-steps-item {
      flex: auto;
    }
    .ant-steps-item-icon {
      width: 22px;
      height: 22px;
      font-size: 11px;
      line-height: 22px;
    }
    .ant-steps-item-title {
      font-size: 13px;
    }
  }

  form {
    @extend %opacity-animation;
    
    .form-view {
      padding: 1em 2em 3em;
      min-height: 65vh;
      max-height: 65vh;
      overflow: auto;
      @extend %scroll-bar;

      .vacancies {
        display: flex;
        align-items: center;

        button {
          height: 35px;
          width: 35px;
          color: #fff;
          border-radius: 7px;
          border-color: $main-border-color;
          background: $primary-color;
          padding-top: 2px;
        }
        .ant-input-number {
          max-width: 7em;
          margin: 0 10px;
        }
      }
    }
  }

  &__headline {
    padding: 0 5px;

    h4 {
      color: #1b1b1b !important;
    }
  }

  &__done {
    text-align: center;
    margin-top: 1em;
    @extend %opacity-animation;

    .ant-btn-link {
      color: #fff;
      background: $primary-color;
      margin-top: 2em;
      min-width: 9em;

      &:hover {
        opacity: 0.8;
      }
    }

    img {
      width: 55px;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 30px;
    background: #fff;
    box-shadow: 0 -2px 10px 0 rgba(128, 98, 96, 0.08);

    button {
      height: 40px;
      border: none;
      box-shadow: none;
      border-radius: 7px;

      &:first-child {
        min-width: 6em;
        color: #1b1b1b;
        background: $main-bg;
        margin-right: 7px;
        border: 1px solid $main-border-color;

        &:hover {
          filter: brightness(0.9);
        }
      }

      &:last-child {
        color: #fff;
        background: $primary-color;
        filter: $purple-drop-shadow;
        min-width: 10em;
        margin-left: auto;

        &:hover {
          opacity: 0.8;
          filter: none;
        }
      }
    }
  }
}

.ant-modal.finish {
  width: 450px !important;
  .ant-modal-body {
    min-height: auto !important;
    padding: 1em 0 3em;
  }
}