.pdf-modal {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  &__footer {
    margin: 0px 25px 25px 25px;
    padding: 5px 0px;
    font-size: 8px;
    color: #a4a4a4;
    background: white;
    @media print {
      position: absolute;
      bottom:0px;
    }
  }
}
