.custom-test-table {
  padding: 30px 20px; //mobile

  @media all and (min-width: 1200px) {
    padding: 30px 40px;
  }

  .ant-page-header {
    padding: 20px 0 10px 0;

    span {
      color: #2B2B2B;
    }

    .anticon {
      color: #5E5E5E;
    }
  }

  &__rowbreadcrumb {
    .ant-breadcrumb {
      display: flex;
      justify-content: center;
    }
  }

  &__headertext {
    margin: 10px 0 20px 0;

    .ant-typography {
      color: #5E5E5E;
    }
  }

  &__filters {
    flex-direction: column !important; // mobile

    @media all and (min-width: 1200px) {
      flex-direction: row !important;
    }

    &__colinput,
    &__colbutton {
      margin: 5px 0; // mobile

      @media all and (min-width: 1200px) {
        margin: 0;
      }

      &__row {
        flex-direction: column !important; // mobile

        @media all and (min-width: 1200px) {
          flex-direction: row !important;
        }

        &__select {
          width: 100%;
        }

        &__button {
          width: 100%; // mobile

          @media all and (min-width: 1200px) {
            width: 30%;
          }
        }
      }
    }
  }

  &__table {
    margin: 20px 0 0 0;

    .ant-spin {
      height: 60vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__yescute {
      border: 1px solid #D9D9D9 !important;
    }

    &__notcute {
      flex-direction: column !important;
      border: 1px solid #D9D9D9;
      height: 50vh;

      img {
        width: 80px; // mobile
        height: 80px; // mobile

        @media all and (min-width: 1200px) {
          width: 120px;
          height: 120px;
        }
      }

      .ant-typography {
        color: #A4A4A4;
      }

      .ant-btn {
        color: #F175A5;
      }
    }
  }

  &__toprow {
    flex-direction: column !important; // mobile

    @media all and (min-width: 576px) {
      flex-direction: row !important;
    }

    &__boxinformation1,
    &__boxinformation2 {
      border: 1px solid #D9D9D9;
      background-color: #FAFAFA;
      padding: 12px;

      &__box {
        flex-direction: column !important;

        &__icon {
          color: #F175A5 !important;
        }

        &__title {
          color: #5E5E5E !important;
          margin: 10px 0 5px 0;
        }

        &__text {
          color: #5E5E5E !important;
        }
      }
    }

    &__boxinformation1 {
      margin: 0 5px 0 0;
    }

    &__description {
      flex-direction: column !important;
      margin: 10px 0 0 0; // mobile
    }

  }
}

.new-custom-test {
  &__frame {
    padding: 30px 20px; // mobile

    @media all and (min-width: 1024px) {
      padding: 30px 40px;
    }

    &__breadcrumbs {
      .ant-breadcrumb {
        display: flex;
        justify-content: center;
      }
    }

    &__page-header {
      padding: 16px 0 !important;
      margin: 20px 0 !important;
    }

    &__steps-row {
      width: 95%; // mobile

      @media all and (min-width: 768px) {
        width: 80%;
        height: 5vh;
        margin: 0;
      }

      @media all and (min-width: 1024px) {
        width: 65%;
        margin: 0 0 20px 0;
      }

      &__step {
        display: flex !important;
        flex-direction: column; // mobile

        @media all and (min-width: 768px) {
          flex-direction: row;
        }
      }
    }

    &__step-by-step {
      margin: 0; // mobile

      @media all and (min-width: 482px) {
        margin: 20px 0;
      }

      @media all and (min-width: 768px) {
        margin: 20px 40px;
      }

      &__step1 {
        &__fields {
          margin: 5px 0 !important;

          span {
            color: #FF4D4F;
            margin: 0 5px 0 0;
          }

          .ant-typography {
            color: #454545;
          }

          .anticon {
            color: #A4A4A4;
            margin: 0 0 0 5px;
          }
        }

        &__input {
          margin: 0 0 20px 0 !important;
        }

        &__configuration {
          &__radio-group {
            width: 100%;

            &__radio {
              margin: 15px 0 0 0 !important; // mobile

              @media all and (min-width: 768px) {
                margin: 0 !important;
              }

              &__input {
                margin: 0 10px 0 0 !important;
                width: 40% !important; // mobile

              }
            }
          }
        }

        &__dimensions {
          // margin: 10px 0 0 0;

          .ant-select-selection,
          .ant-select-selection--single {
            padding: 0 !important;
          }
        }

        &__dimensions-list {
          margin: 0px 0 50px 0;

          .ant-btn {
            border: 1px solid #597EF7 !important;
            color: #587EF7 !important;
            margin: 10px 0 0 0;
          }

          .anticon {
            color: #454545 !important;
          }
        }
      }

      &__step2 {
        &__genomin {
          img {
            width: 40px;
            height: 60px;
          }
        }

        &__box-information {
          margin: 0 0 20px 0;
          border: 1px solid #91D5FF;
          background: #E6F7FF;
          padding: 10px;
          flex-direction: column !important; // mobile

          @media all and (min-width: 768px) {
            flex-direction: row !important;
          }

          &__icon {
            margin: 0 5px 0 0;
          }

          &__text {
            text-align: justify !important;
          }
        }

        &__fields {
          margin: 0 0 5px 0 !important;

          span {
            color: #FF4D4F;
            margin: 0 5px 0 0;
          }

          .ant-typography {
            color: #454545;
          }

          .anticon {
            color: #A4A4A4;
            margin: 0 0 0 5px;
          }

        }

        &__instruction-field {
          margin: 0 0 5px 0;

          .ant-typography {
            color: #454545;
          }

          span {
            color: #A4A4A4;
            margin: 0 0 0 5px;
          }

          .anticon {
            color: #A4A4A4;
          }
        }

        &__input {
          margin: 0 0 20px 0 !important;
        }

        &__collapse-page {
          margin: 20px 0 0 0 !important;

          &__question {
            margin: 20px 0 0 0 !important;

            &__category {
              flex-direction: column !important; // mobile

              @media all and (min-width: 480px) {
                flex-direction: row !important;
              }

              &__tag {
                border: 1px solid #597EF7 !important;
                color: #587EF7 !important;
                margin: 10px 0 0 0;

                .anticon {
                  color: #454545 !important;
                }
              }

              &__select {
                color: #F175A5 !important;
              }
            }

            &__title {
              margin: 10px 0 !important;
            }

            &__alternative {
              margin: 0 0 10px 0 !important; // mobile

              @media all and (min-width: 768px) {
                &__input {
                  width: 95% !important;
                  margin: 0 0 0 20px !important;
                }
              }
            }
          }
        }
      }

    }

  }

  &__footer-app {
    width: 100%;
    position: fixed;
    bottom: 0;
    margin: 300px 0 0 0;
    z-index: 99;
    padding: 24px 100px !important; // mobile
    background: white !important;

    &__btn-right {
      margin: 0 0 0 10px; // mobile

      @media all and (min-width: 1024px) {
        margin: 0 0 0 20px;
      }
    }
  }
}