@import '../../../IdealGenome.scss';

.ig__candidate-path {
  .ig__headline {
    span.ant-typography {
      line-height: 1.3;
      font-size: 15px;
    }
  }

  .ant-collapse {

    .ant-collapse-item {
      .ant-collapse-header {
        padding: 0;
        background: #fff !important;
        span.ant-typography {
          color: rgb(255, 255, 255) !important;
        }
        h4 {
          @media screen and (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
      .description {
        padding: 10px;
        padding-left: 30px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        @media screen and (max-width: 500px) {
          display: none;
        }
      }

      &:nth-child(1) {
        .ant-collapse-header {
          h4, i.anticon.anticon-right.ant-collapse-arrow {
            background: #4e4cac;
          }
          .description {
            background: #4e4cac83;
          }
        }
      }

      &:nth-child(2) {
        .ant-collapse-header {
          h4, i.anticon.anticon-right.ant-collapse-arrow {
            background: #9698d5;
          }
          .description {
            background: #9698d5af;
          }
        }
      }

      &:nth-child(3) {
        .ant-collapse-header {
          h4, i.anticon.anticon-right.ant-collapse-arrow {
            background: #f07f8b;
          }
          .description {
            background: #f07f8aad;
          }
        }
      }

      &:nth-child(4) {
        .ant-collapse-header {
          h4, i.anticon.anticon-right.ant-collapse-arrow {
            background: #ca646e;
          }
          .description {
            background: #ca646ead;
          }
        }
      }

      i.anticon.anticon-right.ant-collapse-arrow {
        padding: auto;

        @media screen and (max-width: 500px) {
          padding: 5px;
        }
      }
      h4 {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        padding: 10px 10px 10px 70px;
        @media screen and (max-width: 500px) {
          padding: 10px 10px 10px 45px;
        }
      }

      .ant-collapse-header {
        .ant-collapse-arrow {
          top: 24px !important;
          @media screen and (max-width: 500px) {
            top: 20px !important;
          }
        }
      }
    }
  }

  .game-row {
    margin-bottom: 2em;
    margin-top: 2em;

    &-headline {
      display: flex;
      align-items: center;
      
      span.ant-typography {
        font-size: 18px;
        color: #202020;
      }

      .ant-tag {
        padding: 0 5px;
        margin-left: 7px;
        font-weight: 600;
        font-size: 10px !important;
      }
    }
  }

  .game-bags {
    .ant-col {
      margin-bottom: 15px;
    }

    .ant-card {
      border-radius: 10px;
      padding: 0;
      transition: transform .2s;
      border: 1px solid #ececec;

      span.ant-typography {
        line-height: 1.3;
        font-size: 15px;
      }

      .ant-tag {
        margin-top: 6px;
        margin-bottom: 32px;
        border: none;
        margin-right: 0;
        background: rgb(240, 240, 240);
      }

      .ant-card-cover {
        padding: 15px;

        img {
          width: 100%;
          height: 100%;
          max-height: 100px;
          object-fit: contain;
          border-radius: 15px;
          min-height: 70px;
          @media screen and (max-width: 500px) {
            max-height: 70px;
          }
        }
      }

      &-body {
        padding: 5px 12px 12px;
        min-height: 70px;
        
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;  
        

        .ant-card-meta {
          width: 100%;
          height: 100%;

          .ant-card-meta-detail {
            width: 100%;
            height: 100%;
          }
        }

        .ant-card-meta-title {
          font-size: 16px;
          overflow: initial;
          white-space: normal;
          text-align: center;
          line-height: 1;
          //color: #fff;
        }

        .ant-card-meta-description {
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 6px;
          left: 0;
          right: 0;
          padding: 0 15px;

          button {
            border: none;
            font-size: 14px;
            padding: 3px 7px;
            height: auto;
            color: #fff;
            background: $mainColor;
            margin-bottom: 4px;
            width: 100%;

            &:first-child {
              margin-right: 3px;
            }
          }

          .btn-detail {
            width: 100%;
            max-width: 30px;
            color: #000000;
            background: #fff;
            border: 1px solid rgb(231, 231, 231);
            display: flex;
            align-items: center;

            img {
              max-width: 17px;
            }
          }

          .added-btn {
            cursor: default;
          }
        }
      }
    }
  }
}