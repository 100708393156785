@import '../../../../../../../../../styles/variables.scss';

.ig-header {
  display: grid;
  align-items: center;
  
  .ant-steps {
    margin-left: -30px;
    @media screen and (max-width: 600px) {
      margin-left: 0;
    }
    .ant-steps-item-title {
      @media screen and (max-width: 330px) {
        display: none;
      }
      @media screen and (max-width: 1030px) {
        display: inline-block; 
        text-overflow: ellipsis;
        width: calc(95%);
      }
    }
  }

  .ant-steps-item {
    max-width: 250px;
    @media screen and (max-width: 390px) {
      max-width: 100%;
      width: 100px;
    }
    &:first-child {
      margin-right: 40px !important;
      @media screen and (max-width: 330px) {
        margin-right: 0 !important;
      }
    }
  }

  .bar {
    width: 100%;
    height: 12px;
    display: flex;
    margin-bottom: 10px;

    div {
      height: 100%;
      margin-right: 2px;

      &:first-child {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
}

.category-relevance {
  max-width: 230px !important;
  
  span.ant-typography {
    font-size: 13px;
    color: #fff;
    line-height: 0 !important;

    strong {
      font-weight: 500;
    }
  }
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
    border-radius: 2px !important;
  }
}