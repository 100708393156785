.avatar-VE {
  background-color: #f5f5f5 !important;
  border: 0.5px solid #d9d9d9;
}

.global-evaluations-container {
  border: 1px solid #d9d9d9 !important;
  bottom: 0.5em !important;
}

.global-evaluations-title {
  margin-left: 1em !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
.questions-and-answers-container {
  border: 1px solid #d9d9d9;
  top: 0.5em;
  margin-bottom: 0.5em;
}
.recommendation-candidate-container {
  display: flex;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.go-back-icon {
  display: flex !important;
  margin-bottom: 0.5em !important;
  margin-right: 1em !important;
}

.recommendation-evaluations-container {
  margin-left: 1em !important;
  margin-bottom: 0.5em !important;
  margin-top: 0.5em !important;
}

.recommendations-options {
  background-color: white !important;
  border-style: none !important;
  border-color: #cccccc !important;
}

.new-modal-video-interviews {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: none;
  }
}

.video-interviews-navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.video-interviews-recommendation-container {
  padding: 1rem;
  border: 1px solid #d9d9d9;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.global-evaluations-evaluators {
  margin-bottom: 1.2rem;
  padding: 1rem;
  border: 1px solid #d9d9d9;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
}
