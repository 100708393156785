$mainColor: #ea4c89;

.impersonate {    
    &__title {
        font-size: 16px;
        font-weight: bold;
        color: black;
    }

    &__div_forgot {
        display: flex;
        justify-content: center;
    }

    &__text_forgot {
        font-size: 13px;
    }

    &__text_forgot:hover {
        color: $mainColor ;
    }

    &__alerts {
        margin-bottom: 10px;
    }
}

.impersonate-form {
    max-width: 300px;
}

.impersonate-form-button {
    width: 100%;
}