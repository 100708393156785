.candidate-filters {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
  
    &__header {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      > span {
        font-weight: 700;
        font-size: 16px;
        color: #454545;
      }
    }
  
    > div:last-child {
      padding: 1rem;
  
      > button {
        width: 100%;
      }
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
  
      .ant-radio-group {
        padding: 1rem;
      }
    }
  
    .candidate-filter {
      display: flex;
      flex-direction: column;
  
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        box-shadow: inset 0px -0.5px 0px #f0f0f0, inset 0px 0.5px 0px #f0f0f0;
  
        span {
          font-weight: 700;
          
        }
      }
    }
  }
  