$delete-color: #ffb48e;

.gw-as {
  margin: 40px;
  background: transparent;

  .ant-card {
    margin-bottom: 2em;

    &-head {
      border: none;

      &-title {
        font-weight: 600;
        font-size: 1.5em;
      }
    }
  }

  .ant-form-item-required::before {
    display: none;
  }

  button.ant-btn.ant-btn-primary { // submit
    height: auto;
    border-radius: 6px;
    padding: 7px 12px;
    min-width: 10em;

    &:hover {
      opacity: 0.9;
    }
  }

  button {
    box-shadow: 0 0 0 0;
    text-shadow: none;
  }

  .ant-row.ant-form-item {
    margin-bottom: 10px;
  }

  .ant-divider {
    margin: 3em 0;
  }

  .ant-upload.ant-upload-select-picture-card {
    background: #fff;

    &:hover {
      border-color: #363636;
    }
  }

  .subtitle strong {
    font-size: 17px;
    color: #5c5c5c;
  }

  &__users {
    button.ant-btn.ant-btn-dashed { // agregar otro
      border: none;
      padding: 0;
    }

    .delete-field {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 20px;
        margin-top: 22px;
        color: #7a7a7a;

        &:hover {
          color: #353535;
        }
      }
    }

    &__headline {
      margin-bottom: 2em;

      .ant-typography {
        strong { 
          font-size: 17px;
          color: #5c5c5c;
        }
        &:last-child {
          font-size: 15px !important;
        }
      }
    }

    &__table {
      margin-top: 2em;
      margin-bottom: 6em;
      overflow-x: auto;

      tr.ant-table-row.email-to-delete, 
      tr.ant-table-row.email-to-delete:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background: $delete-color;
      }

      .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, 
      .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, 
      .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background: #fff !important;
      }

      .ant-switch-disabled, 
      .ant-switch-disabled::after {
        opacity: 1;
        cursor: default;
      }

      .ant-switch-disabled {
        background: #ebf1fa;
      }

      button.ant-switch.ant-switch-checked {
        background: #62eb79;
      }

      .ant-btn-icon-only { // btn eliminar usuario
        border-radius: 6px;
        background: #ebf1fa;
        border: none;
        color: #757575;
        transition: 0s all;

        &:hover {
          background: tomato;
          color: #fff;
        }
      }
    }
  }

  %img-common-styles {
    display: flex;

    .img-description {
      padding: 0 10px;

      @media screen and (min-width: 450px) {
        min-width: 400px;
      }
    }

    .img-uploader {
      margin: 0 30px;
    }
  }

  &__bu-bg {
    @extend %img-common-styles;
    %contrast {
      width: 250px;
      height: 150px;
      position: relative;
      border: 1px solid rgb(221, 221, 221);
    }

    .upload-btn {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        span {
          background: #ffffffde;
        }
      }

      span {
        padding: 5px;
        border-radius: 7px;
        background: #ffffff9f;
      }
    }

    .ant-upload.ant-upload-select-picture-card {
      width: 250px;
      height: 150px;
      position: relative;

      img {
        width: 250px;
        height: 150px;
        object-fit: cover;
      }
    }

    &__preview {

      @media screen and (max-width: 990px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &-home {
        @extend %contrast;
        margin-bottom: 10px;
  
        &-img {
          width: 100%;
          height: 100%;
        }
  
        .uploaded-bg {
          position: absolute;
          width: 43%;
          height: 64%;
          right: 15%;
          top: 18%;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background: #ffffff;
          }
        }
      }

      sup {
        color: #bebebe;
      }
    }

    @media screen and (max-width: 990px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-items: center;
    }
  }

  &__bu-logo {
    @extend %img-common-styles;

    %common-size {
      width: 120px;
      height: 120px;
    }

    .ant-upload.ant-upload-select-picture-card {
      @extend %common-size;

      img {
        display: none;
      }
    
    }

    &__preview {
      @extend %common-size;
      border: 1px dashed #d1d1d1;
      display: flex;
      align-items: center;

      img {
        width: 85%;
        height: 85%;
        margin: auto;
        display: flex;
      }
    }

    @media screen and (max-width: 990px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-items: center;
    }
  }
}