.new-process-left-menu {
  box-shadow: 8px 0 8px -8px rgba(0, 0, 0, 0.15);
  padding: 20px 0px;
  height: 100%;

  .menu-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0px;
    padding: 5px 20px;
  }

  .menu-item {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    cursor: pointer;

    > img {
      width: 30px;
      height: 30px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      margin-right: 8px;
    }
    .avatar-active {
      border: 1px solid #f175a5;
    }
  }
  .active {
    background-color: #fff0f6;
    border-right: 3px solid #f175a5;
    > span {
      color: #f175a5;
    }
    > img {
      border: 1px solid #f175a5 !important;
    }
  }
}

.new-process-right-menu {
  height: 100%;
  overflow: auto;
  .genoma-new-process-info {
    background: #597ef7;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color: #ffffff;
    padding: 10px 10px 10px 40px;

    :nth-child(1) {
      font-weight: 400;
    }
    :nth-child(2) {
      font-weight: 300;
      margin: 10px 30px 10px 0px;
    }
  }
  .genome-card {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 10px;
    overflow: auto;
    min-height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .genome-card-title {
      font-weight: 600;
      font-size: 16px;
      color: #454545;
      display: flex;
      justify-content: space-between;
    }
    .genome-card-content {
      > div {
        margin: 0px 0px 10px 0px;
      }
    }
  }
  .smart-genome-card {
    border: 1px solid #9254DE;
    border-radius: 2px;
    padding: 10px;
    overflow: auto;
    min-height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .genome-card-title {
      font-weight: 600;
      font-size: 16px;
      color: #454545;
      display: flex;
      justify-content: space-between;
    }
    .genome-card-content {
      > div {
        margin: 0px 0px 10px 0px;
      }
    }
  }
}

