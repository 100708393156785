$mainColor: #ea4c89;
$main-shadow: 0 2px 8px #f0f1f2;

.candidate-path-layout {
  min-height: 100vh;
  width: 100%;
  margin: 0px;
  justify-items: center;
  padding-top: 3em;
  background: white !important;
  //border-top: 1px solid rgb(224, 222, 222);
  //box-shadow: $main-shadow;

  display: flex;
  align-items: center;

  .cp-headline {
    text-align: center;

    h3 {
      color: #292929 !important;
    }

    &__kpis {
      margin: 2em 0;

      h4 {
        color: $mainColor !important;
      }
    }
  }
  .prvw {
    width: 70px !important;
    height: 70px !important;

    .icon {
      height: 61px !important;
      width: 95% !important;
    }
  }
  .castle-image-preview {
    width: 60px;
  }

  .small-heart {
    z-index: 999;
    position: absolute;
    width: 42px;
    height: 42px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
    box-sizing: border-box;
    right: 0;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
      width: 25px;
      height: 25px;
    }
  }
  .aborted {
    filter: grayscale(100%);
  }

  .castle-image {
    width: 60px;
  }

  .outer-circle {
    max-width: 110px;
    position: relative;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    margin: 0 auto;
    filter: drop-shadow(2px 4px 6px #6c717b73);

    @media screen and (max-width: 350px) {
      height: 4rem;
      width: 4rem;
    }

    @media screen and (max-width: 400px) {
      filter: none !important;
    }

    @media screen and (min-width: 351px) and (max-width: 500px) {
      width: 6rem;
      height: 6rem;
    }
  }

  .outer-circle-playable {
    @extend .outer-circle;
    cursor: pointer;
    &:hover {
      filter: drop-shadow(2px 4px 6px #6c717b73) brightness(0.9);
    }
  }

  .blocked-level {
    background-color: black;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }

  .blocked-activity {
    -webkit-filter: grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }

  .activity-icon {
    height: 95px;
    padding: 3px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 92%;
    margin: auto;
    display: flex;
    object-fit: contain;

    @media screen and (max-width: 350px) {
      height: 57px;
    }

    @media screen and (min-width: 351px) and (max-width: 500px) {
      height: 84px;
    }
  }
  .label-icon {
    @media screen and (max-width: 400px) {
      font-size: 14px;
    }
  }

  .mobile-tag {
    font-size: 11px;
    padding: 0 4px;
    line-height: 1.5;
    margin-right: 0;
    margin-top: 4px;
  }

  .cp-coins {
    color: #995b3d;
    font-weight: 900;
    font-size: 13px;

    @media screen and (max-width: 500px) {
      font-size: 11px;
    }
  }

  .cp-results-popover,
  .cp-activity-popover,
  .cp-stage-popover {
    max-width: 290px;

    .ant-popover-inner {
      border-radius: 7px !important;
    }

    .ant-popover-inner-content {
      box-shadow: 20px 0px 70px rgba(0, 0, 0, 0.06), -20px 0px 70px rgba(0, 0, 0, 0.06) !important;
    }
  }
  .disabledB {
    filter: grayscale(100%);
    opacity: 0.7;
  }
}
