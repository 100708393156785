@import 'config/reset';
@import 'config/fonts';
@import 'config/typography';

@import 'theme/base';
@import 'theme/login';
@import 'theme/navbar';
@import 'theme/footer';

@import 'utils/margins';
@import './variables.scss';

#root,
.full-height {
  height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

html {
  background: $neutral-2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: $neutral-2;
  caret-color: $mainColor;
}

em {
  font-style: italic !important;
}

.ant-typography, html, body, span, p, h1, h2, h3, h4 { // Dejar esto una vez corregidos los juegos.
  font-family: 'Lato', 'Roboto', 'Open Sans' !important;
  line-height: 1.4;
}

// ANTD
.ant-layout{
  background-color: $neutral-2;
  .ant-layout-header, .ant-layout-content, .ant-layout-footer {
    background-color: transparent;
  }
}

.ant-steps-navigation {
  .ant-steps-item-process .ant-steps-item-icon {
    background: $primary-color;
  }
  .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: $primary-color;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: $primary-color;
  }
}
.ant-steps-navigation .ant-steps-item::before {
  display: none;
}
input {
  color: black;
}

.ant-form-item-required::before {
  display: none;
}
.ant-col.ant-form-item-label label {
  font-weight: 600;
}
i.anticon.anticon-down.ant-select-arrow-icon {
  color: $mainColor;
}
i.anticon.anticon-close.ant-modal-close-icon {
  color: $primary-color;
}
.ant-select-selection.ant-select-selection--single,
.ant-select-selection.ant-select-selection--multiple {
  border-radius: 2px;
}

.ant-select-selection__rendered{
  margin-top: -7px;
  margin-left: 4px;
}

.ant-select-selection-selected-value {
  line-height: 1.5;
  margin-top:5px;
}

.ant-btn,
.ant-input {

  &:focus {
    box-shadow: none;
  }
}

.ant-input-number-input {
  height: 20px;
  padding: 0px;
}

.ant-input,
.ant-input-number,
.ant-select-selection.ant-select-selection--single,
.ant-select-selection.ant-select-selection--multiple {
  font-weight: 400;
  //line-height: 24px;
  padding: 7px;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #0d0c22;
  //border: $main-border-color solid 1px !important;
  background-color: #ffffff;

  &:focus,
  &-focused,
  &:active {
    background-color: white;
  }
}

.ant-select {
  &:focus,
  &-focused,
  &:active {
    background-color: white !important;
    border: $main-border-color solid 1px !important;
  }
}

.ant-select-disabled .ant-select-selection {
  // Overwrite select disabled color
  background: #f5f5f5;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  &:focus,
  &-focused,
  &:active {
    background-color: #f5f5f5 !important;
    border: $main-border-color solid 1px !important;
  }
}

.has-error {
  input {
    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }
}

// Fuentes y tipografias
div.ant-typography {
  // parrafo
  line-height: 25px;
}

h1.ant-typography {
  font-size: 30px;
}
h2.ant-typography {
  font-size: 24px;
}
h3.ant-typography {
  font-size: 20px;
}
h4.ant-typography {
  font-size: 16px;
}

div.ant-typography {
  font-size: 14px;
}

p.ant-typography {
  font-size: 14px;
}

// ANTD shadow
.ant-dropdown-menu,
.ant-tooltip-inner,
.ant-select-dropdown {
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

// ANTD ddropdown
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #5757571e;
}

li.ant-select-dropdown-menu-item {
  height: 35px;
  display: flex;
  align-items: center;
  transition: none !important;
  font-size: 14px;
}

//ANTD TOOLTIP
.ant-tooltip {
  animation: none !important;
  transition: 0s all !important;
}
.ant-tooltip-inner {
  transition: 0s all !important;
  background: #272727;
  padding: 9px 10px;
  font-size: 13px;
  border-radius: 7px;
  max-width: 200px;
  text-align: center;
  animation: 0.4s animate-tooltip forwards;
}
@keyframes animate-tooltip {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//ANTD Form
.ant-checkbox {
  .ant-checkbox-inner {
    width: 16px !important;
  }
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #1890ff;
}

// LOADING
.particle-error,
.permission_denied,
#particles-js {
  width: 100%;
  height: 93vh;
  margin: 0px !important;
}

#particles-js {
  position: fixed !important;
  opacity: 0.23;
}

.permission_denied {
  background: #24344c !important;
}

.permission_denied a {
  text-decoration: none;
}

.denied__wrapper {
  max-width: 390px;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  position: relative;
}

.permission_denied h1 {
  text-align: center;
  color: #fff;
  font-family: 'Dosis', sans-serif;
  font-size: 100px;
  margin-bottom: 0px;
  font-weight: 800;
}

.permission_denied h3 {
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin: 3px auto 20px auto;
  font-family: 'Dosis', sans-serif;
  font-weight: 400;
}

.permission_denied h3 span {
  position: relative;
  width: 65px;
  display: inline-block;
}

.permission_denied h3 span:after {
  content: '';
  border-bottom: 3px solid #ffbb39;
  position: absolute;
  left: 0;
  top: 43%;
  width: 100%;
}

.denied__link {
  background: none;
  color: #fff;
  padding: 12px 0px 10px 0px;
  border: 1px solid #fff;
  outline: none;
  border-radius: 7px;
  width: 150px;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  vertical-align: middle;
  display: block;
  margin-bottom: 40px;
  margin-top: 25px;
  font-family: 'Dosis', sans-serif;
  font-weight: 400;
}

.denied__link:hover {
  color: #ffbb39;
  border-color: #ffbb39;
  cursor: pointer;
  opacity: 1;
}

.permission_denied .stars {
  animation: sparkle 1.6s infinite ease-in-out alternate;
}

@keyframes sparkle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

#astronaut {
  width: 56px;
  position: absolute;
  right: 20px;
  top: 210px;
  animation: spin 4.5s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@media (max-width: 600px) {
  .permission_denied h1 {
    font-size: 75px;
  }
  .permission_denied h3 {
    font-size: 16px;
    width: 200px;
    margin: 0 auto;
  }
  .permission_denied h3 span {
    width: 60px;
  }
  #astronaut {
    width: 35px;
    right: 40px;
    top: 170px;
  }
}

.saturn,
.saturn-2,
.hover {
  animation: hover 2s infinite ease-in-out alternate;
}

.st0 {
  fill: none;
}
.st1 {
  fill: #504e55;
}
.st2 {
  fill: #f39e72;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  opacity: 0.24;
}
.st5 {
  fill: #77574e;
}
.st6 {
  fill: #fbd68d;
}
.st7 {
  fill: #ececec;
}
.st8 {
  fill: #f4a89c;
}
.st9 {
  fill: #cfc9e5;
}
.st10 {
  opacity: 0.28;
}
.st11 {
  opacity: 0.25;
}
.st12 {
  fill: #6f635c;
}
.st13 {
  fill: #dae7be;
}
.st14 {
  fill: #ffe0a6;
}
.st15 {
  fill: #5f5e60;
}
.st16 {
  fill: #cfe1af;
}
.st17 {
  fill: #ebe9f5;
}
.st18 {
  fill: #53515a;
}
.st19 {
  opacity: 0.42;
}
.st20 {
  fill: #53515b;
}

@keyframes hover {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}

#genomin-flotando {
  width: 250px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cover {
  padding: 10px;
}

.cover h1 {
  color: #fff;
  background-color: none;
  letter-spacing: 0.1em;
  text-shadow: -1px -1px 1px red, 0px 0px 25px #ef8624;
}

canvas#canvas {
  color: black;
  background-color: #ef8624;
  border-radius: 100%;
  position: relative;
  transition: all 0.5s ease-in-out;
  height: 350px;
  width: 350px;
}

.floating {
  -webkit-animation-name: Floatingx;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: Floating;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
}

.floatingAlternate {
  -webkit-animation-name: FloatingxAlternate;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: FloatingAlternate;
  -moz-animation-duration: 2.5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
}

@-webkit-keyframes Floatingx {
  from {
    -webkit-transform: translate(0, 0px);
  }
  65% {
    -webkit-transform: translate(0, 15px);
  }
  to {
    -webkit-transform: translate(0, -0px);
  }
}

@-moz-keyframes Floating {
  from {
    -moz-transform: translate(0, 0px);
  }
  65% {
    -moz-transform: translate(0, 15px);
  }
  to {
    -moz-transform: translate(0, -0px);
  }
}

@-webkit-keyframes FloatingxAlternate {
  from {
    -webkit-transform: translate(0, 0px);
  }
  60% {
    -webkit-transform: translate(0, 25px);
  }
  to {
    -webkit-transform: translate(0, -0px);
  }
}

@-moz-keyframes FloatingAlternate {
  from {
    -moz-transform: translate(0, 0px);
  }
  60% {
    -moz-transform: translate(0, 25px);
  }
  to {
    -moz-transform: translate(0, -0px);
  }
}

.antd4-games-custom-wrapper{
  .ant-row{
    display: flex;
    vertical-align: middle;
  }
  .white-text-color {
    color: white;
  }
  .navigation-buttons-row{
    position: absolute;
  }
  .genomin-col-container{
    position: absolute;
  }
}