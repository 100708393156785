@import '../../styles/variables.scss';

.tools-dashboard {
  @extend %opacity-animation;
  .ant-layout-header {
    line-height: 24px;
  }

  .ant-typography {
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.tool-item {
  height: 220px;
  width: 270px;
  border: solid 1px $neutral-5;
  border-radius: 2px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  > div {
    padding: 0.75rem;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-bottom: 1px solid #d9d9d9;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      width: fit-content;
    }
  }
}
