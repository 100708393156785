.pa-pdf {
  @page {
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }

  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  @media print {

    html,
    body {
      height: 100vh;
      // margin: 0 200px;
    }
    .break-page {
      position: relative;
      page-break-inside: avoid;
      height: 100%;
    }
    .card-print {
      @media print {
        margin: 20px;
      }
    }
  }

  &__coverpage {
    height: 100vh;
    margin: 0;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 30px 0;

      h1 {
        color: #F175A5;
      }
    }

    &__logos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      :nth-child(2) {
        height: 80px;
      }

      margin: 30px 0 0 0;
      @media print {
        margin: 200px 50px 0 50px;
        :nth-child(1) {
          height: 60px;
        }
      }
    }

  }

  &__overview {
    height: 100vh;
    margin: 0;
    background: white;
    @media print {
      margin: 0 200px;
    }
  }
}