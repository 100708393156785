.evaluation-modal {
  .evaluation-modal-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }
  .evaluation-modal-sub-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0px 10px 0px;
  }
  .evaluation-modal-section {
    margin: 10px 0px 15px 0px;
  }
  .evaluation-card {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 20px;
  }
  .evaluation-card-button-add {
    color: #f175a5;
    border-color: #f175a5;
    margin-top: 15px;
  }
  .evaluation-card-button-remove {
    color: #ff4d4f;
    border-color: #ff4d4f;
  }

  .evaluations-flex-div {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .additional-question-icon {
    cursor: pointer;
    padding: 5px;
    border: solid 1px #d9d9d9;
    color: #2b2b2b;
    border-radius: 2px;
    margin-right: 10px;
  }
  .additional-question-button {
    cursor: pointer;
    padding: 6px;
    color: white;
    background-color: #f175a5;
    border-radius: 2px;
    margin-left: 10px;
    &__secondary {
      cursor: pointer;
      padding: 5px;
      border: solid 1px #f175a5;
      color: #f175a5;
      border-radius: 2px;
      margin-left: 10px;
    }
    &__basic {
      cursor: pointer;
      padding: 5px;
      border: solid 1px #d9d9d9;
      color: #2b2b2b;
      border-radius: 2px;
      margin-left: 10px;
    }
  }

  .new-question-card {
    border-radius: 2px;
    margin-bottom: 1em;
    background: rgb(255, 255, 255);
    margin-top: 1em;
    padding: 0;
    border: #e7eaf3 solid 1px;

    .ant-form-item {
      margin-bottom: 6px;
    }
  }

  .additional-files-button {
    cursor: pointer;
    padding: 2px;
    color: white;
    width: 2.5em;
    background-color: #f175a5;
    border-radius: 2px;
    margin-left: 2px;
    &__secondary {
      cursor: pointer;
      padding: 5px;
      border: solid 1px #f175a5;
      color: #f175a5;
      border-radius: 2px;
      margin-left: 2px;
    }
    &__basic {
      cursor: pointer;
      padding: 5px;
      border: solid 1px #d9d9d9;
      color: #2b2b2b;
      border-radius: 2px;
      margin-left: 2px;
    }
  }

  .expandable-table .ant-table-row-expand-icon {
    cursor: pointer;
  }
  
  .expandable-table .ant-table-row-expanded > td > span {
    transform: rotate(90deg);
  }

}
