@import '../../../styles/variables.scss';
$in-process-bg: #00000024;
$complete-bg: #47e1976b;
$video-interview-bg: #e85cb061;
$in-process-color: #3b3b3b;
$complete-color: #064426;
$video-interview-color: #5a0b3a;
$main-shadow: 0 6px 10px 0 rgba(128, 98, 96, 0.16);
$neutral-1: #fff;
$neutral-2: #fafafa;
$neutral-4: #f0f0f0;
$character-tertiary: #5e5e5e;

%bg {
  &:nth-child(1) {
    background: $in-process-bg;
    color: $in-process-color;
  }

  &:nth-child(2) {
    background: $complete-bg;
    color: $complete-color;
  }

  &:nth-child(3) {
    background: $video-interview-bg;
    color: $video-interview-color;
  }
}

.gw-pl {
  padding: 2rem;
  background-color: $neutral-2;
  min-height: 100vh;
  position: relative;
  @extend %opacity-animation;

  header {
    height: auto;
    padding: 30px 2em !important;
    background: transparent;
    border-top: 1px solid rgb(236, 236, 236);

    @media screen and (min-width: 600px) {
      padding: 30px 2em !important;
    }

    @media screen and (max-width: 399px) {
      padding-top: 4.5em !important;
    }
  }

  .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
  .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
    animation: none;
    transition: 0s all;
  }

  .ant-tabs-nav-container {
    // tabs
    background: $neutral-1;
    padding: 0 20px;
    border-radius: 1px;

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      // linea de color de tabs
      bottom: 0;
    }

    .ant-tabs-tab {
      padding: 12px 3px;
    }

    .ant-tabs-tab-active.ant-tabs-tab {
      opacity: 1;

      span.ant-typography {
        font-weight: 600;
      }
    }

    .ant-tabs-tab {
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }

      .ant-badge sup {
        color: #6e6e6e;
        background: $main-bg !important;
      }
    }

    .ant-tabs-nav-wrap {
      border-bottom: 2px solid $main-border-color !important;
    }
  }
  .ant-tabs-bar.ant-tabs-top-bar {
    margin: 0;
  }

  button.ant-btn.ant-btn-default {
    background: $main-bg;
    border: none;
    margin-right: 7px;
    border: 1px solid $main-border-color;

    &:hover,
    &:focus {
      color: #3b3b3b;
    }

    &:hover {
      filter: brightness(0.95);
    }
  }

  .ant-collapse-header {
    display: none;
  }

  .ant-collapse,
  .ant-collapse > .ant-collapse-item,
  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-content.ant-collapse-content-active {
    padding: 0 2em;

    @media screen and (min-width: 600px) {
      padding: 0 4em;
    }

    .ant-collapse-content-box {
      padding: 20px 0 0;
    }
  }

  &__filters {
    width: 100%;
    position: relative;

    @media screen and (max-width: 767px) {
      padding-bottom: 1.5em;
    }

    @media screen and (max-width: 500px) {
      padding-bottom: 0.5em;
    }

    .ant-input,
    .ant-input-number,
    .ant-select-selection.ant-select-selection--single,
    .ant-select-selection.ant-select-selection--multiple,
    .ant-select-selection__placeholder {
      // height: 37px;
      color: #727272;
      font-size: 14px;
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      // top: 30%;
    }

    form {
      .ant-row.ant-form-item {
        margin-right: 30px;
        width: 100%;

        @media screen and (max-width: 990px) {
          margin-bottom: 10px;
        }

        .ant-form-item-label {
          line-height: 33px;

          label {
            font-weight: 600;
            color: #424242;

            &::after {
              display: none;
            }
          }
        }

        .ant-form-extra {
          position: absolute;
          right: 0;
          top: -28px;

          a {
            color: $mainColor;
            font-size: 13px;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      %focus {
        color: #333333;
        -webkit-box-shadow: 0 0 0 3px #f775d028 !important;
        box-shadow: 0 0 0 3px #f775d028 !important;
        border-color: #f775d028 !important;
      }

      .ant-select-open .ant-select-selection {
        @extend %focus;
      }

      input,
      .ant-select-selection.ant-select-selection--single,
      .ant-select-open .ant-select-selection {
        &:hover,
        &:active,
        &:focus {
          @extend %focus;
        }

        &:focus {
          border-color: #f775d028 !important;
        }
      }
    }

    &__close {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      cursor: pointer;
      border-top: 1px solid $main-border-color;

      @media screen and (min-width: 768px) {
        display: none;
      }

      &:focus,
      &:hover,
      &:active {
        background: $main-bg;
      }
    }
  }

  &__table {
    position: relative;

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 10px 10px;
    }

    .ant-table-thead > tr > th.ant-table-selection-column,
    .ant-table-tbody > tr > td.ant-table-selection-column {
      padding: 10px 0px 10px 0px;
    }

    .ant-tabs-bar {
      border-top: solid 1px #d9d9d9;
      border-left: solid 1px #d9d9d9;
      border-right: solid 1px #d9d9d9;
    }

    .ant-table {
      border-bottom: solid 1px #d9d9d9;
      border-right: solid 1px #d9d9d9;
      border-left: solid 1px #d9d9d9;
    }
    .ant-table-placeholder {
      background-color: $neutral-1;
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2em;
    }

    &__pipelines {
      display: flex;
      align-items: center;

      &-pip {
        margin-right: 10px;
        height: 25px;
        width: 25px;
        padding: 5px;
        font-size: 12px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %bg;

        @media screen and (min-width: 600px) {
          height: 28px;
          width: 28px;
          font-size: 13px;
        }
      }
    }

    &__actions {
      display: flex !important;

      button {
        border-radius: 0;
        border-color: transparent;
        transition: 0s all;
        padding: 0;
        min-width: 1.5em;

        @media screen and (min-width: 530px) {
          padding: 0 8px;
          min-width: 2.5em;
        }

        i {
          color: #686868;
          margin-top: 3px;
        }

        svg {
          width: 13px;
          height: 13px;

          @media screen and (min-width: 530px) {
            width: 15px;
            height: 15px;
          }
        }

        &:hover,
        &:active,
        &:focus {
          color: $mainColor;
          filter: brightness(0.95);
          border: none;
        }
      }
    }

    &__legends {
      margin-top: 2em;

      @media screen and (min-width: 720px) {
        position: absolute;
        right: 3.5em;
        margin-top: 0;
        bottom: 1em;
      }

      .ant-tag {
        padding: 3px 7px;
        border: none;
        @extend %bg;

        @media screen and (max-width: 430px) {
          font-size: 11px;
          margin-bottom: 7px;
          padding: 1px 5px;
        }
      }
    }

    .ant-table {
      table {
        border-radius: 0px;

        @media screen and (max-width: 530px) {
          border-collapse: separate;
          border-spacing: 0 0.5em;
          width: 100%;
          table-layout: fixed;
          overflow-wrap: break-word;
        }

        thead {
          @media screen and (max-width: 530px) {
            display: none;
          }

          th {
            background-color: $neutral-1;
            font-weight: 600;
            color: black;
          }
        }

        td {
          background-color: $neutral-1;
          cursor: pointer;
          position: relative;
          transition: 0s all !important;
          vertical-align: middle;

          @media screen and (max-width: 530px) {
            width: calc(100vw - 5em) !important;
            display: inline-block;
          }

          &:first-child {
            @media screen and (max-width: 530px) {
              display: none;
            }
          }

          &:nth-child(2) {
            @media screen and (max-width: 530px) {
              border-top: none;
              border-bottom: solid;
              border-left: solid;
              border-right: solid;
              border-width: 1px;
              border-color: $main-border-color;
              padding-top: 0;
            }
          }

          &:last-child {
            min-width: 58px;

            @media screen and (max-width: 530px) {
              width: auto;
              min-width: auto;
              padding: 0;
            }
          }
        }

        tr {
          @media screen and (max-width: 530px) {
            transform: scale(1);
          }
        }

        .ant-table-thead
          > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
            .ant-table-row-selected
          )
          > td,
        .ant-table-tbody
          > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
            .ant-table-row-selected
          )
          > td,
        .ant-table-thead
          > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
          > td,
        .ant-table-tbody
          > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
          > td {
          .gw-pl__table__actions {
            display: flex;
            button {
              border: none;

              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                @media screen and (min-width: 990px) {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .ant-select {
      margin-right: 4px;

      .process-status-label {
        display: none;
      }
    }

    .ant-select-selection {
      background: transparent !important;

      &:hover {
        background: #fff !important;
      }
    }

    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
      border: none !important;
      box-shadow: 0 0 0 0 !important;
    }

    .ant-pagination {
      float: left;

      $hover-color: #616161;

      li {
        &:hover {
          border-color: $hover-color;

          a {
            color: $hover-color;
          }
        }

        @media screen and (max-width: 400px) {
          min-width: 28px;
          height: 28px;
        }

        a {
          @media screen and (max-width: 400px) {
            font-size: 12px;
            line-height: 2.2;
          }
        }
      }

      .ant-pagination-item-active {
        background: $mainColor;
        border: none;

        a {
          color: #fbfbfb !important;
        }
      }

      .ant-pagination-prev:focus .ant-pagination-item-link,
      .ant-pagination-next:focus .ant-pagination-item-link,
      .ant-pagination-prev:hover .ant-pagination-item-link,
      .ant-pagination-next:hover .ant-pagination-item-link {
        border-color: $hover-color;
        color: $hover-color;
      }
    }
  }

  &__stats {
    .ant-statistic {
      margin-right: 50px;
    }
    *.ant-statistic-title {
      font-size: 12px;
    }
  }

  .confidential-process-icon {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 1.5rem;
    height: 1.5rem;
    color: #5e5e5e;
    display: grid;
    place-content: center;
  }
}

.gw-pl-dwn-filters {
  max-height: 300px;
  overflow-y: auto;
  @extend %scroll-bar;

  .ant-checkbox-group {
    padding: 15px 0;
    width: 100%;

    label {
      display: flex;
      align-items: center;
      padding: 9px 15px;
      margin: 0;

      &:hover {
        background: #5757571e;
      }

      span:nth-child(2) {
        width: 97%;

        .chk-label {
          width: 100%;
          display: flex;
          justify-content: space-between;

          div:first-child {
            width: calc(88%);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          div:last-child {
            background: $main-bg;
            padding: 0 5px;
            position: absolute;
            right: 15px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

%modals {
  .headline {
    margin-bottom: 1.5em;
    padding: 30px 30px 0;
    text-align: center;

    h1 {
      font-size: 1.3em;
      margin: 0;
      padding-bottom: 7px;
    }
  }

  .filters {
    padding: 5px 30px 10px;
    border-bottom: 1px solid #f1f1f1;
  }

  .radio_group {
    display: flex;
    justify-content: center;
    justify-items: center;
  }

  .ant-radio-inner::after {
    background-color: $mainColor;
  }

  .ant-divider-horizontal {
    margin: 13px 0 24px;
  }

  .ant-modal-close {
    display: none;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-select {
      height: 25px;
      width: 50%;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }

  .ant-modal-body {
    min-height: 40vh;
    max-height: 70vh;
    overflow: auto;
    padding: 0;
  }

  .ant-modal-content {
    border-radius: 16px;
    padding: 0;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    button {
      height: 37px;
      border-radius: 6px;
      margin-right: 10px;
    }

    button.ant-btn.ant-btn-default:enabled {
      background: $main-bg !important;

      &:hover,
      &:focus,
      &:active {
        border-color: $main-border-color;
        color: #757575;
        filter: brightness(0.9);
      }
    }

    button.ant-btn.ant-btn-primary:enabled {
      background: $mainColor !important;
      border: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .ant-checkbox-group {
    display: grid;
    margin-top: 1em;

    label {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #f1f1f1;

      &:last-child {
        border-bottom: none;
      }

      .chk-label {
        display: flex;

        &__color {
          margin: 0px 10px;
          background-color: #f175a5;
          padding: 10px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-typography {
            color: #ffffff;
          }
        }

        &-name {
          display: grid;
        }
      }
    }
  }
}

.gw-templates-modal {
  max-width: 75% !important;
  @extend %modals;

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn-seconday {
    border-color: $mainColor;
    color: $mainColor;
  }

  .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    // background: #f0f0f0 !important;
  }

  .ant-pagination-item-active {
    border-color: $mainColor;
  }

  .ant-pagination-item-active a {
    color: $mainColor;
  }

  .ant-modal-header {
    background: #fff;
  }

  .ant-modal-content {
    border-radius: 2px;
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #616161;
    // color: #616161;
  }

  .headnav {
    padding: 10px 30px;
  }

  .content {
    padding: 5px 30px;
  }
}

.gw-share-with-collaborators-modal {
  max-width: 450px !important;
  @extend %modals;
}

.gw-new-process-dwn {
  max-width: 370px;

  ul {
    li {
      display: grid;
      height: auto;
      padding: 10px 15px;
      border-bottom: 1px solid rgb(230, 230, 230);

      &:last-child {
        border-bottom: none;
      }

      span {
        white-space: break-spaces;
        line-height: 1.3;
      }

      .ant-tag {
        letter-spacing: 0.5px;
        font-size: 12px;
      }

      span.ant-typography {
        &:first-child {
          padding-bottom: 6px;
        }

        &:last-child {
          color: #afafaf;
          font-size: 13px;
        }
      }
    }
  }
}

.gw-loading-modal {
  width: 100px !important;
  height: 100px !important;

  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__export-excel {
    flex-direction: column;
    align-items: stretch;
  }
}

.gw-hover-actions {
  &-mobile {
    display: block;

    @media screen and (min-width: 990px) {
      display: none;
    }
  }
}

.status {
  width: 100%;
  margin-bottom: 15px;
}

label {
  font-weight: 500;
  color: #797979;
  font-size: 13px;
}

.stat.ant-badge {
  .ant-badge-status-dot {
    width: 13px;
    height: 13px;
  }
}

.job_app-status {
  justify-content: center;
  align-content: center;
}

.job_app-name {
  *.ant-typography {
    margin-bottom: 0px;
  }
  &-text {
    :hover {
      color: #597ef7;
    }
  }
}

.actions-footer {
  width: 80%;
  max-width: 1215px;
  height: 88px;
  position: fixed;
  bottom: 20px;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 7px;
  padding: 5px 25px 12px 10px;
  background: #2e2e2e !important;
  box-shadow: $main-shadow;
  border: 1px solid $main-border-color;
  animation: 0.2s forwards animate-footer;

  @media screen and (max-width: 320px) {
    width: 360px;
    position: fixed;
    border-radius: 0%;
    bottom: 0;
  }

  @media screen and (max-width: 720px) {
    width: 360px;
  }

  @keyframes animate-footer {
    from {
      transform: translateY(100px);
    }

    to {
      transform: translateY(0px);
    }
  }
  &__buttons {
    .ant-btn {
      margin-right: 10px;
    }
  }
}
