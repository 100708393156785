$mainColor: #ea4c89;
$main-shadow: 0 2px 8px #f0f1f2;

.cp-view {
  min-height: 100vh;
  width: 100%;
  margin: 0px;
  padding-top: 3em;
  background: white !important;
  border-top: 1px solid rgb(224, 222, 222);
  box-shadow: $main-shadow;

  display: flex;
  align-items: center;

  &__headline {
    text-align: center;

    h3 {
      color: #292929 !important;
    }

    &__kpis {
      margin: 2em 0;

      h4 {
        color: $mainColor !important;
      }
    }
  }

  .prvw {
    width: 70px !important;
    height: 70px !important;

    .icon {
      height: 61px !important;
      width: 95% !important;
    }

    .aborted.smallHeart {
      width: 27px;
      height: 27px;
    }
  }

  .labelIcon {
    line-height: 1.5;
  }

  .outerCircle {
    max-width: 110px;
    position: relative;
		width: 110px;
		height: 110px;
		border-radius: 100%;
    margin: 0 auto;
    cursor: pointer;
    filter: drop-shadow(2px 4px 6px #6c717b73);

    @media screen and (max-width: 350px) {
      height: 4rem;
      width: 4rem;
    }

    @media screen and (max-width: 400px) {
      filter: none !important;
    }

    @media screen and (min-width: 351px) and (max-width: 500px) {
      width: 6rem;
      height: 6rem;
    }
  }

  .outerCirclrPlayable {
    @extend .outerCircle;

    &:hover {
      filter: drop-shadow(2px 4px 6px #6c717b73) brightness(0.9);
    }
  }

  .icon {
		height: 95px;
    padding: 3px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 92%;
    margin: auto;
    display: flex;
    object-fit: contain;

    @media screen and (max-width: 350px) {
      height: 57px;
    }

    @media screen and (min-width: 351px) and (max-width: 500px) {
      height: 84px;
    }
  }

  .aborted {
    filter: grayscale(100%);
  }

  .smallHeart {
    z-index: 999;
    position: absolute;
    width: 42px;
    height: 42px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
    box-sizing: border-box;
    right: 0;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px){
      width: 25px;
      height: 25px;
    }
  }

  .coins {
		color: #995B3D;
    font-weight: 900;
    font-size: 13px;

    @media screen and (max-width: 500px){
      font-size: 11px;
    }
  }

  .labelIcon {
    @media screen and (max-width: 400px){
      font-size: 14px;
    }
  }

  .mobile-tag {
    font-size: 11px;
    padding: 0 4px;
    line-height: 1.5;
    margin-right: 0;
    margin-top: 4px;
  }

  .title {
    margin-bottom: 18px;

    strong {
      font-size: 20px;
    }
  }

  .ant-page-header {
    max-width: 540px;
    margin: auto;

    @media screen and (max-width: 400px) {
      padding: 10px 0 0;
    }

    span.ant-avatar {
      margin-bottom: 13px;
      margin-right: auto;
      margin-left: auto;
      width: auto !important;
      height: auto !important;

      img {
        width: auto;
        max-width: 300px;
        height: 45px;
        margin: auto;
      }
    }
  }

  .ant-page-header-heading {
    display: grid !important;
    align-items: center !important;
    justify-content: center;

    h3 {
      margin: 0;
      text-align: center;
    }
  }

  .ant-card {
    width: 100%;
    border-radius: 7px;
    border: 2px solid rgb(233, 233, 233);
    margin-bottom: 1.5em;
  }

  button {
    border-radius: 7px;

    &:hover {
      opacity: 0.7;
    }
  }

  p {
    margin-bottom: 6px;
  }

  .total-reward {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .counter {
      strong {
        font-size: 25px;
      }
    }

    img {
      width: 70px;

      @media screen and (max-width: 400px) {
        width: 55px;
      }
    }
  }

  .candidate-stage {
    padding-bottom: 36px;
		padding-left: 20px;
    padding-right: 20px;
    
    @media screen and (max-width: 400px) {
      padding-left: 0px;
      padding-right: 0px;
    }

    .ant-page-header {
      strong {
        color: $mainColor;
      }
    }
  }

  .game-over {
    margin: auto;
    border: 2px solid #e5e5e5;
    border-radius: 16px;
    padding: 15px;
    margin-bottom: 1.5em;

    @media screen and (min-width: 400px) {
      padding: 24px;
    }

    @media screen and (min-width: 765px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .text {
      min-width: 70%;

      @media screen and (min-width: 400px) {
        display: flex;
        align-items: center;
        max-width: 80%;
        padding-right: 4em;
      }

      span {
        color: rgb(129, 129, 129);
        line-height: 1.4;
        font-size: 15px;

        @media screen and (max-width: 400px) {
          font-size: 14px;
        }
      }
    }

    h4 {
      @media screen and (max-width: 400px) {
        font-size: 17px;
        line-height: 1.2;
      }
    }

    span, h4 {
      @media screen and (max-width: 400px) {
        text-align: center;
      }
    }

    img {
      width: 70px;
      margin-right: 1.5em;

      @media screen and (max-width: 400px) {
        margin: auto;
        display: flex;
      }
    }
  }

  .level-locked {
    background: #dadada;
    opacity: 0.5;
    border: 3px solid #c0c0c0;
    border-radius: 7px;
    pointer-events: none;
    padding-top: 1em;
    padding-bottom: 1em;

    div {
      filter: drop-shadow(-3px 0px -3px rgba(102, 102, 102, 0)) !important;
    }
  }

  .item-list {
    line-height: 1.2;
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    color: rgb(124, 124, 124);

    &::after {
      content: " ";
      position: absolute;
      top: 5px;
      left: -20px;
      width: 6px;
      height: 6px;
      background-color: rgb(117, 117, 117);
      border-radius: 50%;
    }
  }

  .stage-title {
    color: #212121;
    font-size: 18px;
  }

  .ant-divider-horizontal.ant-divider-with-text-center, .ant-divider-horizontal.ant-divider-with-text-left, .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 5px 0;
  }

  .castle-img {
    cursor: pointer;
    width: 50px;

    &:hover {
      opacity: 0.7;
    }
  }

  .feedback {
    text-align: center;

    img {
      display: flex;
      margin: auto;
      margin-bottom: 1em;
      width: 6em;
    } 

    span {
      font-size: 16px;
      line-height: 1.3;
    }

    strong {
      font-size: 20px;
    }

    button {
      margin-top: 1em;
    }
  }

  .app-summary {
    span.ant-typography {
      font-size: 20px;
    }

    .levels { 
      display: flex;
      align-items: center;

      .level-one, .level-two {
        margin: auto;
        text-align: center;

        .wrap {
          width: 100%;
          position: relative;

          img {
            width: 40%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            margin: auto;
          }

          .ant-progress {
            width: 100%;
  
            .ant-progress-inner {
              width: 100% !important;
              height: auto !important;
            }
          }
        }

        span.ant-typography {
          font-size: 16px !important;
        }
      }
    }
  }

  .candidate-progress {

    .rewards {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: space-between;

      .reward {
        display: flex;
        align-items: center;
        padding-right: 15px;
        border-radius: 100px;
        font-weight: 600;
  
        img {
          width: 25px;
        }
  
        span {
          font-size: 14px;
          margin-left: 4px;

          strong {
            color: rgb(56, 56, 56);
          }
        }
      }
    }

    .name-and-progess {
      width: 100%;

      span.ant-typography {
        font-size: 20px;
      }

      .ant-divider {
        margin: 20px 0;
      }

      .ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-default {
    
        .ant-progress-bg {
          position: relative;
          background: repeating-linear-gradient(-45deg, #108ee9, #108ee9 20px, #108ee9d1 20px, #108ee9e3 40px);
        }
      }

      .ant-progress-status-success .ant-progress-bg {
        position: relative;
        background: repeating-linear-gradient(-45deg, #87d068, #87d068 20px, #9fd289 20px, #9fd289 40px);
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: #eb3589;
    height: 3px;
  }
  .ant-tabs-tab {
    font-size: 16px;
  }
}

.results-popover, .activity-popover, .stage-popover {
  max-width: 290px;

  .ant-popover-inner { 
    border-radius: 7px !important;
  }

  .ant-popover-inner-content {
    box-shadow: 20px 0px 70px rgba(0,0,0,0.06), -20px 0px 70px rgba(0,0,0,0.06) !important;
  }
}

.stage-popover {
  .ant-popover-arrow {
    border-top-color: #2dc4f1 !important;
    border-left-color: #2dc4f1 !important;
  }
  .ant-popover-inner {
    background: #2dc4f1;
  }
  .ant-popover-inner-content {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

    span {
      color: white;
      text-align: center;

      &:first-child {
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 10px;
        display: inline-block;
      }

      &:last-child  {
        font-size: 16px;
      }
    }
  }
}

.recommendations-modal {
  margin-top: 4em !important;
  padding-top: 4em;
}

.ant-modal-mask {
  background-color: rgba(0,0,0,0.8) !important;
}

.lockedGameModal {
  max-width: 400px !important;

  .ant-modal-content {
    border-radius: 16px !important;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
  }

  .content {
    text-align: center;

    @media screen and (min-width: 600px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    img {
      width: 60px;
      margin-bottom: 1em;
    }

    button {
      border-radius: 7px;

      &:hover {
        opacity: 0.7 !important;
      }
    }
  }
  
}