@import '../../../../../NewProcessForm.scss';

.vi {
  .ant-card {
    position: relative;
    border-radius: 7px;
    padding: 0;
    margin-bottom: 10px;
    box-shadow: $main-shadow;
    border: 1px solid $main-border-color;

    &-body {
      padding-bottom: 0 !important;

      .headline {
        line-height: 1.5;
        margin-bottom: 10px;

        strong {
          font-size: 15px;
          color: black;
        }
      }

      .delete-btn {
        position: absolute;
        top: -7px;
        right: -7px;
        color: #fff;
        height: 20px;
        width: 20px;
        font-size: 12px;
        border-radius: 100px;
        background: $danger-color;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-row.ant-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        justify-content: space-between;
    
        label {
          color: rgb(119, 119, 119);
          font-weight: 500;
        }
      }
    
      .ant-col.ant-form-item-control-wrapper {
        width: 100%;
      }
    
      .ant-form-item-required::before {
        content: '';
      }
    }
  }
}