.genoma-tests-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h2.ant-typography {
    margin: 0;
  }

  .ant-breadcrumb-link {
    cursor: pointer;
  }
}

.genoma-tests-body {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  &__test {
    width: 265px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &__header {
      padding: 0.5rem;
      background: #f175a5;
      min-height: 60px;

      .ant-typography {
        color: #ffffff;
      }
    }

    &__content {
      flex-grow: 1;
      background-color: #ffffff;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      .ant-typography {
        color: #5e5e5e;
      }

      .div-with-icon {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      p,
      span {
        font-size: 14px !important;
        line-height: 1.7 !important;
      }
    }
  }
}

.modal-game {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > img {
      height: 50px;
      width: 50px;
      border-radius: 66%;
      box-shadow: 0px 2px 3px 0px #0000001f;
    }
  }

  &__footer {
    display: flex;
    gap: 2rem;

    > div {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      i {
        color: #597ef7;
      }
    }
  }

  &__traits {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__text {
      padding-inline: 1rem;
      display: block;
      text-align: justify;

      strong {
        margin-left: 0.5rem;
      }
    }
  }
}
