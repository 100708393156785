.container {
  margin: 20px 0;
  padding: 0;
  width: 100%;
}
.custom-multirange-slider {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 2px;
  margin: 0 50px 0 0;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

.thumb--zindex-6 {
  z-index: 6;
}


/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: 20px 0 0 0;
  pointer-events: all;
  // position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 20px;
  pointer-events: all;
  position: relative;
}
