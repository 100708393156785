body {
  font-family: 'Lato', sans-serif;
  background: #ffffff;
}

.root-print-div {
  background: #ffffff;
}

// Force the BG and text color on firefox when printing
.firefox-bg-color {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.footer {
  color: #786d73;
  margin-top: 30px;
}

// Hide the default header and footer in the native print setting usings margins under 10px
@page {
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

@media print {
  .root-print-div {
    background: #ffffff;
    padding: 15px;
  }

  .container {
    margin-top: 20px;
    margin-left: 70px;
    margin-right: 70px;
  }

  // Don t break the page inside a div
  .protected-div {
    page-break-inside: avoid;
  }

  .break-first-page {
    position: relative;
    page-break-inside: avoid;
    height: 98%;
  }

  .break-page {
    position: relative;
    page-break-inside: avoid;
    height: 100%;
  }

  .break-before {
    page-break-before: always;
  }
}

.dividers {
  max-height: 34px;
  background: #fafafa;
  margin: 20px 0px;
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #454545;
  }
}

.trait-container {
  font-family: Lato;
  font-style: normal;
  line-height: 20px;
  color: #5e5e5e;
  &--description {
    font-size: 10px;
  }
  &--title {
    font-size: 12px;
    font-weight: bold;
  }
}

// FIRST PAGE CSS

.first-page-container {
  &__text-pdf {
    font-family: Lato;
    font-style: normal;
    &--title {
      margin-top: 20rem;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #5e5e5e;
    }
    &--subtitle {
      margin-top: 20px;
      margin-bottom: 20rem;
      p {
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        color: #2b2b2b;
      }
    }
    &--note {
      margin-bottom: 20px;
      p {
        font-weight: bold;
        font-size: 9px;
        line-height: 150%;
        color: #a4a4a4;
      }
    }
  }
}

.interview-section-container {
  margin: 20px 0;
  overflow-y: hidden;

  &__icon {
    color: #f175a5;
    font: size 10px;
  }

  &__text {
    &--icon {
      margin-left: 2px;
    }
    &--no-rate {
      margin-bottom: 14px;
    }
  }
}

// SECOND PAGE CSS

.second-page-container {
  &__date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #a4a4a4;
    }
    &--small {
      font-size: 11px;
    }
  }

  &__job-name {
    font-size: 16px;
    margin: 0 0 10px 0;
  }
}

.profiles-container {
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin: 0;
      color: #2b2b2b;
      font: size 12px;
    }
  }
}

// THIRD PAGE CSS

.question-section-pdf {
  margin: 20px 0;
  &__question {
    margin-bottom: 30px;
    position: relative;
    page-break-inside: avoid;
    height: 100%;
  }
}

//4TH PAGE CSS

.personality-container {
  margin: 30px 0;
  &__trait {
    font-family: Lato;
    font-style: normal;
    line-height: 20px;
    color: #5e5e5e;
    &--title {
      font-size: 12px;
      font-weight: bold;
    }
    &--description {
      font-weight: normal;
      font-size: 10px;
    }
  }
  @media all and (max-width: 600px) {
    width: 600px;
  }
}

// 5TH PAGE CSS

.heatmap-title {
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #5e5e5e;
  }
}

.heatmap-container-pdf {
  &__heatmap-questions {
    margin-top: 42px;
    table {
      width: 100%;
    }
    tbody {
      width: 80%;
      display: flex;
      flex-direction: column;
    }
    tr {
      margin: 4px 0;
    }
    td {
      height: 30px;
      display: flex;
      align-items: center;
      margin: 0 22px 0 0;
    }
  }
  &__heatmap-percent {
    table {
      width: 100%;
    }
    thead {
      tr {
        display: flex;
        justify-content: space-evenly;
        width: 80%;
      }
      th {
        margin: 0px 22px;
      }
    }
    tbody {
      width: 80%;
      display: flex;
      justify-content: center;
      tr {
        margin: 10px 0;
        display: flex;
      }
      td {
        margin: 4px 4px;
        height: 30px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }
    }
  }
  @media all and (max-width: 1000px) {
    .heatmap-colors {
      display: none;
    }
  }
}
