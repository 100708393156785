@import '../../../NewProcessForm.scss';

.af {
  padding: 3em 3em 5em !important;
  @extend %opacity-animation;

  @media screen and (max-width: 400px) {
    padding: 3em 2em 5em !important;
  }

  @media screen and (min-width: 768px) {
    padding: 4em 2em 10em !important;
  }

  @media screen and (min-width: 1000px) {
    padding: 2em 4em 10em 6em !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $primary-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  button.ant-btn.ant-btn-link {
    margin: 1.5em 0 3em;
  }

  button.ant-btn.ant-btn-ghost {
    border: none;
    padding: 0;
    color: #1890ff;
    margin-top: 10px;
    background: transparent !important;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
    }
  }

  span.ant-form-item-children {
    button.ant-btn.ant-btn-icon-only {
      border-color: $main-border-color;
      border-radius: 7px;
    }
  }

  .ant-tag {
    font-size: 17px;
    border: none;
  }

  .dnd-row {
    margin-left: 0px;
  }

  .mobile-actions-btn {
    border: none;
    color: rgb(54, 54, 54);
  }

  &__added-questions {
    .headline {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 400px) {
        display: grid;
      }

      a {
        @media screen and (max-width: 400px) {
          margin-top: 8px;
        }
      }

      span.ant-typography {
        font-size: 18px;

        &:last-child {
          color: #8d8d8d;
          margin-left: 6px;
        }
      }

      .ant-btn {
        border-color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 14px;

        .anticon {
          color: $mainColor;
        }
      }

      .ant-btn:hover,
      .ant-btn:active,
      .ant-btn:focus {
        border-color: #fff;
        color: #272727;
      }
    }
  }

  &__dnd {
    position: relative;

    .dnd-item {
      padding: 5px 0 !important;
      background: #fff;

      &:hover {
        .actions {
          display: block;
        }

        .question {
          width: 78%;
        }
      }

      .question {
        display: flex;
        align-items: center;
        width: 100%;

        .ant-tag {
          min-height: 30px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #7583f71c;
          color: $primary-color;
          padding: 7px;
          font-size: 20px;
          margin-right: 13px;
        }

        .ant-typography.ant-typography-ellipsis {
          line-height: 1.2;
          font-size: 15px;
          margin-bottom: 0;
          color: rgb(97, 97, 97);
        }

        a.ant-typography-expand {
          font-size: 13px;
        }
      }

      &__more {
        display: flex;
        align-items: center;

        @media screen and (max-width: 400px) {
          flex-direction: column;
        }

        .ant-btn {
          border-color: rgb(228, 228, 228) !important;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        position: absolute;
        right: 25px;
        //display: none;

        button {
          padding: 0;
          max-height: 33px;

          &:nth-child(1),
          &:nth-child(2) {
            background: $main-bg;
            border: 1px solid $main-border-color;
          }

          &:nth-child(3) {
            border: none;
            color: #fff;
            border-radius: 7px;
            background: $mainColor;
            opacity: 0.8;
            padding: 0 !important;
          }
        }
      }
    }
  }

  &__new-question {
    border-radius: 10px !important;
    margin-bottom: 2.5em;
    background: rgb(255, 255, 255);
    margin-top: 1em;
    box-shadow: $main-shadow;
    padding: 0 !important;
    border: #e7eaf3 solid 1px !important;

    &__header {
      padding: 10px 23px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e0e0e0dd;

      span.ant-typography {
        font-size: 16px;
      }

      button,
      button:hover,
      button:focus,
      button:active {
        border: none;
        color: $primary-color;
      }
    }

    form {
      padding: 20px;
    }

    .ant-select {
      @media screen and (min-width: 600px) {
        max-width: 55%;
      }

      .ant-select-selection--single .ant-select-selection__rendered {
        padding-top: 2px;
      }
    }

    .ant-select-selection.ant-select-selection--single {
      padding-left: 5px;

      .ant-select-selection-selected-value {
        display: flex !important;
        align-items: center !important;
      }
    }

    .ant-card-body {
      padding: 0;
    }

    .ant-input-number {
      width: 100%;
    }

    .alt-q {
      line-height: 1.3;
      font-weight: 500;
    }

    .ant-col.ant-col-sm-6,
    .ant-col.ant-col-sm-1 {
      display: flex;
      align-items: center;

      @media screen and (min-width: 576px) {
        height: 100%;
      }
    }

    label.ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    label,
    .ant-radio-button-wrapper:last-child,
    .ant-radio-button-wrapper:first-child {
      margin-bottom: 10px;
      margin-right: 7px;
      border-radius: 3px !important;
      border: none;
      padding-top: 5px;
      height: auto !important;

      i {
        padding-top: 4px;
        font-size: 20px;
      }
    }

    label:nth-child(5) {
      i {
        padding: 0 !important;
      }
    }

    button {
      &:first-child {
        background: #f2f5fa !important;
        margin-right: 7px;
      }

      @media screen and (max-width: 455px) {
        margin-bottom: 5px;
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }

    .bottom {
      margin-top: 2em;

      span.ant-form-item-children,
      .main-actions {
        display: grid;

        @media screen and (min-width: 455px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .main-actions {
        button:first-child {
          color: #fff;
          background: $primary-color !important;
        }
      }
    }
  }

  &__templates {
    margin: 2em 0;

    .qn-removed {
      background: #ec5e2f27;
      animation: 1.1s animate ease;

      @keyframes animate {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    }

    .headline {
      span.ant-typography {
        font-size: 18px;
      }
    }

    li {
      border: none !important;
      padding: 10px 0;
      border-radius: 7px;

      // &:first-child {
      //   em {
      //     display: none;
      //   }

      //   ul {
      //     margin-right: 42px;
      //     margin-left: 0;

      //     @media screen and (max-width: 600px) {
      //       margin-right: 0;
      //     }
      //   }
      // }
    }

    .ant-tag {
      min-height: 30px;
      border-radius: 6px;
      background: #7583f71c;
      color: $primary-color;
      padding: 7px;
      font-size: 20px;
      margin-right: 13px;
    }

    .add {
      &:hover {
        box-shadow: 0 0 0 0;
      }
    }

    .main-btn {
      box-shadow: 0 0 0 0;
    }

    .add,
    .main-btn {
      padding: 0 !important;
      max-height: 33px;
      transition: all 0s;
      background: $main-bg;
      margin: 0 5px;
    }

    .ant-collapse-header {
      padding-left: 0 !important;
      border-bottom: 1px solid $main-border-color !important;

      span.ant-typography {
        font-size: 18px;
      }
    }

    .ant-collapse-borderless>.ant-collapse-item {
      border: none;
    }

    .bottom {
      display: flex;
      justify-content: center;

      button {
        border: none;
        font-weight: 600;

        &:hover {
          color: #414141;
        }

        i {
          color: $mainColor;
        }
      }
    }
  }

  // ANTD
  button.ant-btn.ant-btn-default {
    height: auto;
    border: none;
    background: transparent !important;
    box-shadow: 0 0 0 0;
    color: #1890ff;
    padding: 2px;
    border-radius: 7px;
    margin-top: 1em;

    &:hover {
      background: #f8f8f8 !important;
    }
  }

  .ant-collapse-item {
    background: rgba(255, 255, 255, 0.623);
    padding-left: 0;
    padding-right: 20px;
    padding-top: 7px;

    &:hover {
      background: rgba(255, 255, 255, 0.938);
    }
  }

  .ant-collapse-header {
    background: transparent;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-item-required::before {
    content: '';
  }

  li.ant-select-selection__choice {
    border-radius: 4px !important;
    border: none !important;
    font-size: 13px !important;
    color: #fff !important;
    background: #ea4c89c4 !important;
    line-height: 1.2 !important;
    height: auto !important;
    padding: 10px 19px 8px 6px !important;
  }

  .ant-select-selection.ant-select-selection--multiple {
    padding-left: 10px !important;
    padding-bottom: 7px !important;
  }
}

.ant-select-dropdown-menu-item-active {
  .ant-select-selected-icon {
    color: #fff !important;
  }
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  color: black;
}

.ant-popover.q-preview-popover {

  .ant-popover-arrow,
  .ant-popover-inner {
    margin-left: 2em !important;
  }

  .ant-typography {
    color: rgb(110, 110, 110);
  }

  .ant-popover-inner {
    box-shadow: 0 8px 23px #efefef !important;
    border-radius: 10px !important;
  }

  .preview-popover {
    max-width: 300px;
  }
}

.new-qn-select-icon {
  margin-right: 6px;

  i {
    font-size: 17px;
  }
}

.new-qn-select-icon-db-check {
  margin-right: 5px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.mobile-action-modal {
  max-width: 300px !important;

  .ant-modal-body {
    padding: 0;

    button {
      height: auto;
      padding: 10px;
      border-radius: 0;

      &:first-child {
        color: $primary-color;
      }
    }

    .ant-btn-danger {
      background: #fff;
      color: $danger-color;
      border-color: $main-border-color;
    }
  }
}