@import '../../CandidateProfilev2/CandidateProfile.scss';

.tools-dashboard {
  .ant-layout-header {
    line-height: 24px;
  }
  padding: 2rem;
}

.rubrics__card {
  h3.ant-typography {
    color: #333333;
  }
}

.card__table {
  .ant-card-body {
    padding: 0;
  }
  h3.ant-typography {
    color: #333333;
  }
  width: 100%;
  border: 1px solid #efefef;
  padding: 0px 0px 20px 0px;
  z-index: 0;
}
.tabs__container {
  color: $mainColor;
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-tab:hover {
    color: $mainColor !important;
  }
  .ant-tabs-ink-bar {
    background-color: $mainColor !important;
  }
  .ant-card-bordered {
    border: 1px solid #d9d9d9;
  }
}

.rubrics__table {
  cursor: pointer;
  position: relative;
  transition: 0s all !important;
  vertical-align: middle;
  table {
    color: #686868;

    .ant-typography {
      color: #686868;
    }
  }
  .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: #f0f5ff !important;
  }

  .ant-table-thead > tr > th {
    background: none;
  }
  .ant-table-body > tr > td {
    padding: 16px 16px;
    overflow-wrap: break-word;
  }
}

.integrations__table {
  cursor: pointer;
  position: relative;
  transition: 0s all !important;
  vertical-align: middle;
  table {
    .ant-table-tbody > tr > td {
      border: none;
    }
  }
}

.integration-image {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 0.5px solid #d9d9d9;
}

.integration-image-table {
  margin-right: 1em;
  margin-left: 1em;
  max-width: 30px;
  max-height: 30px;
  border-radius: 15px;
  border: 0.5px solid #d9d9d9;
}

.token-circle {
  background-color: #fff0f6;
  display: flex;
  margin-left: 0.5em;
  align-items: center;
  justify-content: center;
  border: solid 1px #d9d9d9;
  padding: 10px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
}

.token-circle-mobile {
  background-color: #fff0f6;
  display: flex;
  margin-left: 0.5em;
  align-items: center;
  justify-content: center;
  border: solid 1px #d9d9d9;
  padding: 10px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.integrations-table-row {
  background-color: #fafafa !important;
  margin-bottom: 1em !important;
  height: 3.5em !important;
  border: 0.1em solid #d9d9d9 !important;
  align-content: center !important;
}

.available-integrations {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .ant-tag {
    margin: 0;
  }
}

.get-integration-templates-card {
  width: 16em !important;
  height: 16em !important;
  background-color: #fff0f6 !important;
  color: #f175a5 !important;
  border: 1px solid #f175a5 !important;
}

.disconnected-integration-tag {
  border: 1px solid #ffd591 !important;
  color: #fa8c16 !important;
  background-color: #fff7e6 !important;
}

.connected-integration-tag {
  border: 1px solid #b7eb8f !important;
  color: #52c41a !important;
  background-color: #f6ffed !important;
}


.integration-buttons-row {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.tokens-table-row {
  background-color: #fafafa !important;
  margin-bottom: 1em !important;
  height: 4em !important;
  border: 0.1em solid #d9d9d9 !important;
  align-content: center !important;
}
.genomes-table-row {
  background-color: #fafafa !important;
  margin-bottom: 1em !important;
  height: 10em !important;
  border: 0.1em solid #d9d9d9 !important;
  align-content: center !important;
}
.new-token-info {
  margin-bottom: 0.5em !important;
  margin-top: 1em !important;
  background-color: #e6f7ff !important;
  height: 2em !important;
  border: 1px solid #91d5ff !important;
}
.token-icon {
  font-size: 25px !important;
  color: #f175a5 !important;
}
.open-access-token-modal-button {
  width: 200 !important;
  height: 32 !important;
  font-size: 14 !important;
  color: #454545 !important;
}

.copy-integration-icon {
  font-size: 20px !important;
  color: #597ef7 !important;
}

.token-text {
  color: #597ef7 !important;
}

.expiration-date-text {
  font-size: 10px !important;
  margin-left: 0.2em !important;
}

.integration-card {
  padding: 1rem;
  width: 292px;
  height: 265px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
