@import 'variables';

h1 {
  font-family: $font-family-regular;
  font-size: 3rem;
  color: $color-silver-dark;
  padding-bottom: .6rem;
}

h2 {
  font-family: $font-family-regular;
  font-size: 2.5rem;
  color: $color-silver-dark;
}

h3 {
  font-family: $font-family-regular;
  font-size: 2rem;
  color: $color-silver-dark;
}

h4 {
  font-family: $font-family-regular;
  font-size: 1.5rem;
  color: $color-silver-dark;
}

h5 {
  font-family: $font-family-regular;
  font-size: 1rem;
  color: $color-silver-dark;
}

a {
  font-family: $font-family-regular;
  text-decoration: underline;

  &:hover {
    color: $color-silver-dark;
    cursor: pointer;
  }
}

b, strong {
  font-weight: bold !important;
}

code {
  padding: 2px 4px;
}

label {
  font-family: $font-family-regular;
  color: $color-silver-dark;
}