@import '../../NewProcessForm.scss';

.selected-games {
  min-height: 90vh;
  max-height: 90vh;
  overflow-x: auto;
  @extend %scroll-bar;
  background: #ffffff;

  &__bottom {
    background: #ffffff;
    padding-bottom: 20px;
  }
  
  .ant-card {
    &-body {
      padding: 7px !important;

      strong {
        font-size: 16px;
      }

      ul {
        margin-top: 10px;

        li {
          display: flex;
          align-items: center !important;
          background: #f2f5fa;
          padding-left: 15px;
          padding-right: 10px;
          border-radius: 10px;
          border: none !important;
          margin-bottom: 7px;

          .ant-list-item-meta {
            align-items: center;
          }

          h4 {
            margin-bottom: 0;
            line-height: 1.3;

            span:last-child {
              font-size: 12px;
            }
          }

          button {
            border: none;
            box-shadow: 0 0 0 0;
          }
        }
      }

      button.ant-btn.ant-btn-primary {
        margin-top: 1.5em;
        margin-left: 0;
      }

      button:disabled:hover {
        color: #666 !important;
      }
    }
  }
}
.ant-affix {
  height: auto !important;
}
.js-affix {
  @media screen and (max-width: 767px) {
    position: unset !important;
    top: auto !important;
  }
}

.v2 {
  background: $main-bg !important;
  border-radius: 10px !important;


  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    top: auto;
    right: 0;
    left: 0;
    height: auto !important;
    padding-top: 0 !important;
  }

  .ant-card-body {
    padding: 24px;

    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;

      span.ant-badge-status-text, span.ant-typography, .summary, label {
        display: none !important;
      }

      button {
        width: 100%;
      }
      .ant-select {
        height: 25px;
        max-width: 80px;
        margin: 0 10px 0;
        margin-bottom: 0 !important;
      }
    }

    span.ant-typography {
      color: #181818;
      font-size: 16px;
    }

    button {
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 7px;
      background: white;

      &:hover {
        filter: brightness(0.9);
      }

      &:last-child {
        margin: 20px 0 0 !important;
      }
    }

    .summary {
      display: flex;
      align-items: center;
      margin: 10px 0 15px;

      &__bag {
        width: 97%;
        padding: 5px;
        background: #fff;
        border-radius: 4px;
        text-align: center;
        border: 1px solid $main-border-color;

        h4 {
          margin: 0;
        }

        span.ant-typography {
          color: #6b6b6b;
          font-size: 14px;
        }

        &:first-child {
          margin-right: 5px;
        }
      }
    }

    .status {
      width: 100%;
      margin-bottom: 15px;
    }
    label {
      font-weight: 500;
      color: #797979;
      font-size: 13px;
    }
  }
}

.stat.ant-badge {
  .ant-badge-status-dot {
    width: 13px;
    height: 13px;
  }
}