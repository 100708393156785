.check-icon {
    color: #52c41a !important;
    margin-right: 5px;
}

.tour-modal-check-list {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 0px;
    margin-right: 0px;

    .ant-row-flex {
        flex-flow: row nowrap;
    }
}

.tour-popover-div {
    text-align: left;
    text-justify: inter-word;
    font-size: 14px;
    line-height: 22px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.tour-button {
    border: 1px solid #cccccc;
    border-radius: 1px;
    padding: 8px;
    &__close {
        color: rgb(255, 156, 210);
        font-weight: bold;
        border: 1px solid rgb(255, 156, 210);
        border-radius: 1px;
        padding: 8px;
    }
}
