.new-modal-candidate-bg {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.isolated {
  background: #fafafa;

  // modal height
  > div {
    height: 100%;
    z-index: -1 !important;
  }
}

.not-isolated {
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.new-candidate-modal {
  background-color: #ffffff;
  width: min(100%, 1280px);
  position: relative;
  overflow: auto;

  // try to keep 1 query for all changes
  @media (max-width: 992px) {
    .new-modal-information.ant-col {
      height: fit-content;
    }

    .new-modal-information__postulation {
      justify-content: space-between;
    }

    .new-modal-content {
      height: fit-content;
    }

    .go-to-top-btn {
      display: none;
    }
  }

  > div:not(.ant-alert) {
    padding: 1rem;
  }

  .text-16px {
    font-size: 16px;
  }

  .text-20px {
    font-size: 20px;
  }

  .duplicates-alert {
    .primary-profile-btn {
      background-color: #1890ff;
      border-color: #1890ff;
      color: #ffffff;
    }

    .other-profile-btn {
      background-color: #ffffff;
      border-color: #ffffff;
      color: #454545;
    }
  }
}

.new-modal-information.ant-col {
  height: 100%;
  background: #fbf4f8;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.new-modal-information {
  &__head {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__postulation {
    display: flex;
    gap: 1rem;

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > button {
        padding: 0;
        color: #5e5e5e;
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;

    > button {
      width: fit-content;
      padding: 0;
      color: #f175a5;
    }
  }

  &__tags {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__note {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;

    > textarea {
      padding-bottom: 1.5rem;
    }

    &__btns {
      position: absolute;
      bottom: 0;

      > button {
        font-size: 12px;
      }
    }
  }

  &__swap {
    margin-top: auto;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
  }
}

.new-modal-content {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex !important;
  flex-direction: column;
  gap: 0.5rem;

  &__header {
    width: 100%;
    position: relative;

    &__sub {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
    }

    &__btns {
      justify-content: flex-end;
      flex-grow: 1;
      display: flex;
      gap: 0.5rem;

      .ant-btn-link {
        color: #454545;
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: #f175a5;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 700;
    color: #f175a5;
  }

  [role='tab'].ant-tabs-tab {
    margin: 0;
  }

  &__activities {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__header {
      background: #e6f7ff;
      padding: 6px;
      align-self: center;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 1rem;

      img,
      > div {
        border-radius: 66%;
        height: 40px;
        width: 40px;
      }

      > div {
        display: grid;
        place-items: center;
        background: #597ef7;
      }
    }
  }

  &__background {
    &__section {
      height: 100%;
      background: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  &__evaluations {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .ant-collapse-content .ant-collapse-content-box {
      padding: 1rem;
    }
  }

  &__messages {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__header {
      padding: 1rem;
      background: #fafafa;
      display: flex;
      justify-content: space-between;
    }

    // we have to override the css from antd to adjust the messages to candidate modal
    > div.ant-row-flex {
      height: 100% !important;
      overflow: auto !important;
    }
  }
}

.new-modal-list-item {
  position: relative;
  display: flex;
  gap: 1rem;

  &__lane-svg {
    min-height: 67px;
    max-height: 67px;
  }

  > img:nth-child(1) {
    position: absolute;
    left: -4px;
  }

  > div {
    display: flex;
    flex-direction: column;
  }
}

.change-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &__value {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 0.25rem 0.5rem;
  }
}

.new-modal-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    flex-grow: 1;
    padding: 0.5rem;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
}

.text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.new-modal-videoint {
  opacity: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__rating-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &__spaces {
      display: flex;

      > span {
        flex-grow: 1;
        height: 30px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        display: grid;
        place-items: center;
      }

      .orange-style {
        border-color: #faad14;
        color: #faad14;
        background: #fff1b8;
      }
    }
  }
}

.game-badge {
  position: relative;

  > img {
    height: 24px;
    width: 24px;
    border-radius: 66%;
  }

  > i {
    position: absolute;
    z-index: 5;
    bottom: 1px;
    right: -5px;
  }
}

.new-modal-summary-evals {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    display: flex;
    align-items: center;

    > div {
      gap: 0.5rem;
      display: flex;
    }
  }

  &__refs {
    display: flex;
    gap: 0.5rem;

    > button {
      width: 100%;
    }
  }

  &__switchs {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > button {
      width: 150px;
    }
  }

  &__category {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__title {
      display: flex;
      align-items: center;
      gap: 1rem;

      .ant-divider {
        margin: 0;
        min-width: 0;
      }
    }
  }

  .ant-switch {
    span {
      color: #ffffff;
    }
  }
}

.new-modal-trait {
  padding: 0.5rem;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.new-modal-summary-cutes {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ant-typography p {
    margin: 0;
  }

  &__item {
    min-height: 164px;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    &__info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    // &__img {
    //   height: 100%;
    //   width: 160px;
    //   background: #ffffff;
    //   padding: 0.5rem;
    //   border: 1px solid #d9d9d9;
    //   border-radius: 2px;
    // }

    &__right {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      min-width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }
  }

  &__dat {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    > span {
      padding: 0.5rem;
    }
  }
}

.traits-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.new-modal-collapse {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    padding: 0.5rem 1rem;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &__badge {
      background-color: #597ef7;
      padding: 0.5rem;
      border-radius: 66%;
      border: 2px solid #ffffff;
    }
  }
}

.new-modal-references {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__ref {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    &__header {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__information {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }

      &__questions {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        > div {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
        }
      }

      &__buttons {
        display: flex;
        gap: 0.5rem;
      }
    }
  }
}

.candidate-modal-interviews {
  .ant-divider-horizontal {
    margin: 0;
  }

  .ant-select-selection {
    height: 100%;
  }

  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__questions {
    padding: 1rem;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      span {
        line-height: 22px;
        font-size: 14px;
      }

      .ans-evaluation {
        line-height: 21px;
        color: #a4a4a4;
      }

      .skill {
        padding: 2px 6px;
        width: fit-content;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
      }

      &__answer {
        margin-left: 0.5rem;
        &:before {
          content: '• ';
        }
      }
    }
  }

  &__scorecard {
    padding: 1rem;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}

.new-modal-ikit-evaluation {
  display: flex;

  // single squares
  > div {
    flex-grow: 1;
    border: 1px solid #d9d9d9;
    padding: 0.25rem 0;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
}

//
.custom-tabs-modal {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: fit-content;
  color: #5e5e5e;

  > div {
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
}

.go-to-top-btn {
  position: absolute;
  right: 30;
  bottom: 20;
  z-index: 130;
  border-radius: 66%;
}

.email-view {
  .ant-form-item {
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.5rem;
  }
}

.summary-kos-list {
  max-height: 454px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;

  hr {
    margin: 0;
    color: rgb(217, 217, 217);
  }
}

.new-modal-question {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  &__texts {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.candidate-modal-mails-history {
  padding: 1em;

  .ant-row {
    display: flex;
  }

  &__day {
    margin-bottom: 1em;
    flex-direction: column;
    align-items: center;

    > div {
      flex-direction: column;
      width: 100%;
      margin-top: 1em;
    }

    > span {
      background: #e6f7ff;
      border-radius: 2px;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #454545;
      padding: 0.3em;
      margin-bottom: 0.5em;
    }
  }

  &__mail {
    &__remitent {
      padding: 0.5em 1.5em;

      > div {
        display: flex;
        flex-direction: column;
        margin-right: 2em;

        span {
          margin-bottom: 0.3em;
        }
      }
    }

    &__content {
      flex-direction: column;
      padding: 0.3em 1.5em 1.5em 1.5em;

      > span {
        margin-bottom: 0.5em;
      }
    }
  }
}

.emails-history-custom-card {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 1.3em;
  cursor: pointer;
  flex-direction: column;

  &__header {
    padding: 0.5em 0.7em;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin: 0 !important;
      font-size: 16px !important;
      color: #454545;
      flex-grow: 1;
    }

    span {
      padding: 0.3em;
      line-height: 100%;
      border-radius: 2px;
      color: #ffffff;
    }
  }
}

.empty-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
}

.cultural-fit-report-entry-row {
  width: 100%;
  margin-bottom: 40px;
  row-gap: 24px;
}

.cultural-subtrait-label-container {
  width: 170px;
  padding: 5px 0px;
  background-color: #ffffff;
  text-align: center;
  border: 1px solid #cccccc;
}

.cultural-trait-pole {
  background-color: #d9d9d9;
  color: #5e5e5e;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
  border-radius: 2px;
  &__selected {
    color: white;
  }
}

.cultural-scale-inside-node__selected {
  background-color: white;
  width: 11px;
  height: 11px;
  position: relative;
  top: 11px;
  left: 11px;
  border-radius: 50%;
}

.cultural-scale-node {
  width: 33px;
  height: 33px;
  background-color: #d9d9d9;
  border-radius: 50%;
}

.cultural-node-selected__0 {
  background-color: #ff85c0;
}
.cultural-node-selected__1 {
  background-color: #c41d7f;
}

.cfit-report__page {
  min-height: 99vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 15px;
}
.cfit-report__cover {
  background-color: #f759ab;
  margin-left: 0;
  padding-block-start: 35vh;
  padding-block-end: 35vh;
}

.user-tag {
  padding: 3px 3px 2px;
  margin: 0 1px;
  vertical-align: baseline;
  display: 'inline-block';
  border-radius: 4px;
  background-color: #eee;
  font-size: 0.9em;
  color: #40A9FF;
}

.comment {
  ol {
    list-style: auto;
    margin-left: 15px;
  }
  
  ul {
    list-style: disc;
    margin-left: 15px;
  }
  .ant-comment-content {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 
      "author author" 
      "detail actions";
    .ant-comment-content-author {
      grid-area: author;
    }
    .ant-comment-content-detail {
      grid-area: detail;
    }
    .ant-comment-actions {
      grid-area: actions;
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}

.response-card {
  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
  }
}

.response-header {
  margin-bottom: 10px;
}

.leadership-description {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__category {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__header {
      padding: 0.5rem;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 2.5rem;

    > div {
      display: flex;
      flex-direction: column;
    }

    &__left {
      text-align: justify;
    }

    &__right {
      align-items: center;
    }
  }
}
