.candidate-process-list {
    .process-list {
      width: 100%;
      height: 100%;
    }
  }
  
  .search-and-filter {
    display: flex;
  
    > button {
      margin-left: 0.5rem;
    }
  
    > div {
      display: flex;
  
      > div {
        width: 200px;
        margin-left: 0.5rem;
      }
    }
  
    @media screen and (min-width: 992px) {
      > button {
        display: none;
      }
    }
  
    @media screen and (max-width: 992px) {
      > div {
        display: none;
      }
  
      > span {
        flex-grow: 1;
      }
    }
  }
  
  .process-list-card {
    height: 140px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
    cursor: pointer;

    @media screen and (max-width: 992px) {
        height: 180px
    }
  
    > div {
      height: 100%;
    }
  
    &__left {
      width: 60px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #f0f0f0;
  
      img {
        position: relative;
        width: 34px;
        height: 34px;
        margin-bottom: 6px;
        border-radius: 66%;
      }
  
      span {
        font-size: 12px;
      }
    }
  
    .with-pendient-act {
      &::before {
        position: absolute;
        top: 25px;
        right: calc(50% - 15px);
        height: 10px;
        width: 10px;
        content: '';
        background: #ff4d4f;
        z-index: 5;
        border-radius: 66%;
      }
    }
  
    &__right {
      flex-grow: 1;
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
  
      span {
        font-size: 12px;
      }
    }
  }
  
  .status-process {
    border: 1px solid;
    border-radius: 2px;
    width: 132px;
    padding: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
    
    span {
      flex-grow: 1;
      margin-left: 4px;
    }
  }
  
  .filters-container {
    position: absolute;
    width: 100%;
    top: 0;
    background: #ffffff;
    border-bottom: 1px solid #d9d9d9;
    z-index: 10;
  
    &__filter {
      > div:first-child {
        height: 42px;
        box-shadow: inset 0px -0.5px 0px #f0f0f0, inset 0px 0.5px 0px #f0f0f0;
        padding: 0 1rem;
      }
  
      > div:nth-child(2) {
        padding: 1rem;
      }
    }
  
    .button-filter-col {
      padding: 1rem;
  
      button {
        width: 100%;
      }
    }
  }
  