$color-white: #ffffff;

$color-silver-dark: #828282;
$color-silver: #a5a5a5;
$color-silver-light: #f8f8f8;

$color-red-dark: #d45161;
$color-red: #f27d72;
$color-red-light: #ffafaf;
$color-red-light-2: #ffc5c5;

$color-green-default: #16967a;
$color-green: #89d78f;
$color-green-light: #b3ffd8;

$color-blue: #35A1FB;