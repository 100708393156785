@import '../../CandidateProfilev2/CandidateProfile.scss';

.layoutCustomInterview {
  .beadcrumNav {
    margin: 40px 40px 20px 40px;
    @media all and (max-width: 600px) {
      font-size: 16px;
      margin: 40px 20px 20px 20px;
    }
  }
  .navTitle {
    margin: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h1 {
      margin: 0 20px 0 0;
      padding: 0;
      font-size: 28px;
    }
    svg {
      margin: 0px 0px 0px 40px;
      padding: 0;
    }
    @media all and (max-width: 600px) {
      margin: 10px 20px;
      svg {
        margin: 0 0 0 20px;
      }
    }
  }
  .infoSection {
    margin: 0px 40px;
    @media all and (max-width: 600px) {
      margin: 0px 20px;
    }
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 16px 0px;
  }
  .filterSearch {
    @media all and (max-width: 767px) {
      flex-direction: column;
      .ant-col {
        margin: 5px 0px;
      }
      .filterSearchAction {
        justify-content: center;
      }
    }
  }
  .customInterviewTable {
    margin: 0px 40px;
    @media all and (max-width: 600px) {
      margin: 20px;
    }
    .custom-antd-table {
      background-color: black;
    }
    .ant-tabs, .ant-tabs-top, .ant-tabs-line {
      .ant-tabs-bar, .ant-tabs-top-bar {
        margin: 0px;
        background: white;
        border: 1px solid #D9D9D9;
      }
    }
    .ant-table-pagination {
      margin: 20px;
    }
    table {
      background: white;
      border: 1px solid #D9D9D9;
      border-top: none;
      border-bottom: none;
      th {
        background: white;
        @media all and (max-width: 600px) {
          display: none;
        }
      }
      td {
        &:nth-child(5) {
          button {
            margin: 0 3px;
            font-size: 20px;
          }
        }
        @media all and (max-width: 600px) {
          &:nth-child(3){
            display: none;
          }
          &:nth-child(4) {
            display: none;
          }
          &:nth-child(5) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            button {
              margin: 0;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .newInterviewTable {
    margin: 20px 40px;
    background-color: white;
    border: 1px solid #D9D9D9;
    @media all and (max-width: 600px) {
      margin: 20px 20px;
    }
  }
  .formNewQuestions {
    display: flex;
    margin: 10px 20px;
    .ant-col {
      width: 50%;
    }
    @media all and (max-width: 600px) {
      flex-direction: column;
      .ant-col {
        width: 100%;
        margin: 5px 0px;
      }
    }
  }
  .actionsNewInterview {
    display: flex;
    margin: 10px 40px;
    @media all and (max-width: 600px) {
      margin: 10px 20px;
    }
  }
  .deleteModal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 100px;
    background-color: #2B2B2B;
    padding: 15px;
    @media all and (max-width: 600px) {
      flex-direction: column;
      width: 100%;
      margin: 40px 0px;
      .ant-col {
        margin: 10px 0;
      }
    }
  }
}
