.form-activity {
  background-image: url('https://genoma-assets.s3.us-east-2.amazonaws.com/fondo_cesped.png');;
  background-size: cover;
  min-height: 100vh;

  .form-card {
    box-shadow: 8px 8px 32px 0px #B021641A;
    margin-bottom: 40px;

    .form-card-row {
      display: flex;
      justify-content: center;
      width: 100%;

      .progress-segment {
        &-left {
          .ant-progress-inner {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          .ant-progress-inner>.ant-progress-bg {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &-middle {
          .ant-progress-inner {
            border-radius: 0;
          }
          .ant-progress-inner>.ant-progress-bg {
            border-radius: 0;
          }
        }

        &-right {
          .ant-progress-inner {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          .ant-progress-inner>.ant-progress-bg {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }

    .form-section-row {
      border: 1px solid #D9D9D9;
      margin-bottom: 25px;
      margin-top: 25px;
      padding: 20px;
    }

    .ant-card-body {
      min-height: 85vh;
    }

    .info-card {
      margin-top: 1em;

      .ant-card-body {
        min-height: auto;
      }
    }

    .add-button {
      border-color: #F175A5;
      color: #F175A5;
    }

    .add-button:hover {
      background-color: #F175A5;
      color: white;
      cursor: pointer;
    }
  }
}
