@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=block');

.rich-text-editor {
  background-color: #FAFAFA;
  padding: 10px 20px;
  border-radius: 3px;
  border: solid 1px #D9D9D9;

  ol {
    list-style: auto !important;
    margin-left: 15px !important;
  }
  
  ul {
    list-style: disc !important;
    margin-left: 18px !important;
  }

}

.rteditor {
  &__share-button {
    .ant-checkbox-wrapper {
      width: 100%;
    }
    .ant-popover-inner {
      width: 330px;
    }
    
    .ant-popover-inner-content {
      height: 200px;
      overflow: overlay;
    }
    
    .ant-scroll-number-only-unit {
      color: #FFFFFF;
    }
  }
  
  &__upload-button {
    .ant-upload-list {
      position: absolute;
      left: 0;
      padding: 10px;
    }
  }
  
  &__badge {
    .ant-scroll-number-only-unit {
      color: #FFFFFF;
    }
  }

  &__toolbar {
    position: relative;
    padding: 1px 18px 17px;
    margin: 0 -20px;
    border-bottom: 2px solid #eee;
    margin-bottom: 100px;
  }
  
  &__menu {
    .ant-btn {
      padding: 0 5px;
    }
    & > * {
      display: inline-block;
    }
  
    & > * + * {
      margin-left: 15px;
    }
  }
}