@import './variables.scss';

%center {
  display: flex !important;
  align-items: center !important;
}

.gw-search-field[type='process'] {
  .ant-select-selection__rendered {
    background-color: #ffffff !important;
    border: 1px solid $main-border-color;
  }
}

.gw-search-field {

  .ant-select-selection__rendered {
    border-radius: 7px;
    height: 35px !important;
    background-color: #f2f5fa !important;
  }

  .ant-select-selection.ant-select-selection--single,
  .ant-select-selection.ant-select-selection--single:focus {
    border: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: transparent !important;
    box-shadow: 0 0 0 0 !important;
  }

  input {
    border: none !important;
    height: 35px !important;
    background-color: transparent !important;
  }

  span.ant-input-suffix {
    display: none;
  }

  .ant-select-selection__placeholder {
    padding-left: 18px;
  }

  .ant-select-show-search {
    height: 35px;
    @media screen and (max-width: 330px) {
      width: 160px !important;
    }
  }
}

.main-content {
  height: 100vh;
}

.main-header {
  position: fixed;
  z-index: 999;
  border-bottom: 1px solid $neutral-5;
  background: #fff !important;
  width: 100%;
  height: 55px !important;
  @extend %center;
  padding: 0px 20px 0px 0px !important;

  @media screen and (max-width: 400px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;
    z-index: 999;
  }

  button {
    box-shadow: 0 0 0 0;
    margin-right: 5px;

    @media screen and (max-width: 390px) {
      margin-right: 0 !important;
    }

    @media screen and (max-width: 834px) {
      .menu-button-text {
        display: none;
      }
    }
  }

  @media screen and (max-width: 834px) {
    .gw-search-field {
      display: none;
    }
  }

  .right {

    .ant-avatar {
      color: rgb(43, 43, 43);
      background: $main-bg;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;

      &:hover,
      &:focus {
        filter: brightness(0.9);
      }

      @media screen and (max-width: 400px) {
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 830px) {
      .menu-select-text {
        display: none;
        width: 150px;
      }
    }

    @media screen and (max-width: 834px) {
      .menu-select-image {
        display: flex;
      }
    }
  }

  .left {
    .brand-logo {
      height: 35px;
      width: auto;
      font-size: 13px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      #businessLogo {
        width: auto;
        height: 35px;
        align-self: center;
        @media screen and (max-width: 769px) {
          height: 20px;
        }
      }
    }
    button {
      border: none;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.certain-category-search-dropdown {
  @media screen and (min-width: 300px) {
    width: 220px !important;
  }

  .ant-select-dropdown-menu {
    @media screen and (min-width: 700px) {
      max-height: 300px !important;
    }
  }

  li.ant-select-dropdown-menu-item {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $main-border-color;
    height: 80px;

    &:last-child {
      border-bottom: none;
    }

    .dropdown-icon {
      i.anticon.anticon-user,
      i.anticon.anticon-reload {
        background: $mainColor;
        padding: 4px;
        border-radius: 100%;
        color: #fff;
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .dropdown-item {
      display: grid;
      margin-left: 5px;

      span,
      strong {
        width: calc(100%);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.gw-avatar-dropdown {
  ul {
    padding-top: 10px;

    li {
      &:last-child {
        margin-top: 10px;
        padding: 10px 15px;
        color: $danger-color;
        border-top: 1px solid $main-border-color;
      }

      img {
        width: 11px;
        margin-right: 7px;
      }
    }
  }
}

.gw-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .ant-drawer-body {
    padding-left: 0 !important;
  }

  .ant-list {
    padding-top: 1em;

    @media screen and (max-width: 599px) {
      padding-top: 0.5em;
    }
  }

  .ant-list-item-meta-avatar {
    display: none;
  }

  a {
    text-decoration: none;
  }

  li {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.new-input-navbar-search {
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;

  .ant-input {
    border-radius: 2px !important;
    padding: 5px 10px !important;
  }

  .icon-search {
    height: 25px;
    border-left: 1px solid #d9d9d9;
    padding: 5px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 160px;
  }

  .ant-input:focus,
  .ant-input-focused,
  .ant-input:active,
  .ant-input-number:focus,
  .ant-input-number-focused,
  .ant-input-number:active,
  .ant-select-selection.ant-select-selection--single:focus,
  .ant-select-selection.ant-select-selection--single-focused,
  .ant-select-selection.ant-select-selection--single:active,
  .ant-select-selection.ant-select-selection--multiple:focus,
  .ant-select-selection.ant-select-selection--multiple-focused,
  .ant-select-selection.ant-select-selection--multiple:active {
    border-radius: 2px !important;
  }
}

.sider-container {
  height: 100vh;
  z-index: 998;
  position: fixed;

  // lang select
  .ant-select > .ant-select-selection--single {
    padding: 0;

    .ant-select-selection__rendered {
      margin: 0;

      .ant-select-selection-selected-value {
        width: 100%;
        padding: 0 6px;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .ant-layout-sider-children {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > img {
      padding: 1rem;
    }
  }

  &__links {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-layout-sider-trigger {
    box-shadow: inset 0px 1px 0px #f0f0f0;
  }

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff0f6 !important;
    .menu-button-text {
      color: #f175a5 !important;
    }
    .anticon {
      color: #f175a5 !important;
    }
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #f175a5;
  }
  .ant-menu-submenu-title {
    .menu-button-text {
      color: black !important;
    }
    .anticon {
      color: black;
    }
  }
  .ant-menu-item {
    .menu-button-text {
      color: black !important;
    }
    .anticon {
      color: black;
      &.logout-button {
        color: #ff4d4f;
      }
    }
    .logout-button {
      color: #ff4d4f;
    }
  }
}


.footer-main-container {
  &.ant-layout-footer {
    z-index: 999;
    position: fixed;
    padding: 0px !important;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    background: #f0f2f5;
  }

  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 0px;
  }
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #f175a5 !important;
  }
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin: 0px 3px 0px 0px;
  }

  .ant-menu-submenu-popup {
    // top: 0px !important;
    // right: 5% !important;
    // left: auto !important;
    // width: 400px;
    position: fixed !important;
    bottom: 47px !important;
    right: 1px !important;
    top: auto !important;
    left: auto !important;
  }

  .footer-button-text {
    font-size: 10px;
    line-height: 1.4 !important;
  }
}
