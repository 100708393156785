.reactour__helper {
  border-radius: 2px !important;
  
}

.reactour__helper div[data-tour-elem='controls'] {
  gap: 0.5rem !important;
  justify-content: flex-end !important;
}

.reactour__helper div[data-tour-elem='controls'] button {
  margin: 0 !important;
}

.reactour__close {
  color: #ffffff !important;
}