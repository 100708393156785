.cute-answer {
  padding: 0 0.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-height: 30px;

  // managing overflow
  overflow: auto;
  text-overflow: ellipsis;
  width: 100%;

  &__correct {
    border-color: #52c41a;
  }

  &__wrong {
    border-color: #ff4d4f;
  }

  .cute-divider {
    width: 1px;
    align-self: stretch;
  }
}
