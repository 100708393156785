.genome-config {
    .distribution-description {
        min-height: 114px;
        background: #fafafa;
        /* Neutral / 5 */

        border: 1px solid #d9d9d9;
        border-radius: 2px;
        padding: 20px;
        margin-bottom: 18px;
        > :nth-child(1){
            margin-bottom: 8px;
        }
    }
    .genome-config-section {
        > :nth-child(1) {
            background-color: #e6f7ff;
            color: red;
        }
        > :nth-child(2) {
            background-color: #efdbff;
        }
        > :nth-child(3) {
            background-color: #fff2e8;
        }
    }
}
