.evaluations-container {
  .ant-breadcrumb-link {
    cursor: pointer;
  }

  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h2.ant-typography {
    margin: 0;
  }

  .ant-input-search {
    width: min(360px, 100%);
  }
}

.games-categories {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__category {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > span {
      font-size: 16px;
    }

    &__games {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }

  &__game {
    padding: 0.75rem;
    width: 265px;
    min-height: 136px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    &:hover {
      transform: scale(1.025); // Expands the card
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 1rem;

      img {
        width: 40px;
        height: 40px;
        border-radius: 66%;
      }
    }
  }
}

.complementary-evaluations {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  &__card {
    display: flex;
    flex-direction: column;
    width: 280px;
    min-height: 215px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background: #ffffff;

    > div {
      padding: 0.75rem;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      border-bottom: 1px solid #d9d9d9;
      font-size: 16px;
    }

    &__body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > button {
        width: fit-content;
      }
    }
  }
}
