.confidential-brand {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1.5rem;

  &__section {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    // selecting all <Text> components
    span.ant-typography {
      font-size: 16px;
    }
  }

  .italic-comment {
    font-weight: 600;
    font-style: italic;
  }

  .ant-divider {
    margin: 0;
  }

  .processes-image {
    display: flex;
    gap: 3rem;

    .ant-upload {
      margin: 0;
    }

    .ant-upload-picture-card-wrapper {
      width: fit-content;
    }
  }
}

.confidential-colors {
  display: flex;
  gap: 2.5rem;

  &__left {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      width: fit-content;
      align-self: center;
    }
  }

  &__picker {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;

    &__inputs {
      display: flex;
      gap: 0.75rem;

      input[type='color'] {
        height: 32px;
        width: 32px;
      }

      .ant-input {
        width: 90px;
      }
    }
  }
}
