@import '../../../styles/variables.scss';
$mainColor: #f175a5;
$cognit: #ffa388;
$reasoning: #c17ee6;
$personality: #ff82a0;
$white: #ffffff;
$low: #f5222d;
$medium: #e8e526;
$high: #52c41a;
$subtitle: #030303b9;
$shadow: 0 2px 10px 0 rgba(7, 31, 49, .06);
$hover: rgba(230, 38, 112, .04);

.details-modal-divider.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

span.detail-label {
  display: block;
  font-size: 15px;
  font-weight: bold;
}

button.ant-btn.ant-btn-primary:enabled {
  background: $mainColor;
  border-color: $mainColor;
  color: white;
}

.rc-slider-handle.rc-slider-handle-1:hover {
  z-index: 99999999999;
}


.ant-drawer-content-wrapper {
  width: 400px;

  @media screen and (max-width: 400px) {
    width: 100% !important;
  }
}

.table__modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 30px;
    }

    .ant-modal-footer {

      .secondary:hover,
      .secondary:focus,
      .secondary:active {
        border-color: $mainColor;
        color: $mainColor;
      }
    }
  }
}

.cl {
  @media screen and (max-width: 400px) {
    padding-top: 10px;
  }

  height: 100vh;
  background: white !important;

  .main-content {
    height: 100vh;
    overflow-y: auto;
  }

  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: $mainColor !important;
  }

  .ant-progress-inner,
  .ant-progress-bg {
    height: auto !important;
  }

  .ant-row-flex {
    height: 100vh;
  }

  a {
    text-decoration: none;
  }

  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    height: 100vh;
    position: sticky;
    top: 0;
  }

  .ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-left-content {
    padding-left: 0;
  }

  .ant-switch-checked {
    background-color: $high;
  }

  .ant-tag {
    //border: none;
  }

  .rc-slider-handle.rc-slider-handle-1 {
    span {
      top: 50% !important;
      font-size: 9px !important;
    }
  }

  &__filters {
    @media screen and (min-width: 500px) {
      .filters__close {
        display: none;
      }
    }

    margin-bottom: 2em;

    .ant-collapse-header {
      display: none;
    }

    .ant-collapse-content.ant-collapse-content-active {
      border: none;
    }

    .ant-form-item-label>label {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 700;
    }

    span.ant-typography {
      line-height: 29px;
      font-size: 14px;
    }

    .ant-input:focus {
      border-color: $mainColor;
    }

    .ant-input-number:hover,
    .ant-input-number:focus {
      border-color: $mainColor;
    }

    .ant-select-selection:active {
      border-color: $mainColor;
      -webkit-box-shadow: 0 0 0 2px #eb358a31;
      box-shadow: 0 0 0 2px #eb358a31;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $mainColor;
      border-color: $mainColor;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus+.ant-checkbox-inner {
      border-color: $mainColor;
    }

    .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled),
    .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: $mainColor;
      -webkit-box-shadow: 0 0 0 2px #eb358a31;
      box-shadow: 0 0 0 2px #eb358a31;
    }

    .ant-input:focus,
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      border-color: $mainColor !important;
      -webkit-box-shadow: 0 0 0 2px #eb358a31;
      box-shadow: 0 0 0 2px #eb358a31;
    }

    .ant-input:hover,
    .ant-select-selection:hover,
    .ant-radio-input:focus+.ant-radio-inner,
    .ant-radio-checked .ant-radio-inner,
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
      border-color: $mainColor;
    }

    .ant-radio-inner::after {
      background: $mainColor;
    }

    .ant-btn.ant-btn-secondary:hover {
      border-color: $mainColor;
      color: $mainColor;
    }

    .ant-checkbox-group {
      display: grid;

      label {
        margin: 0 0 5px;
      }
    }

    span.ant-input-group {
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -moz-flex-flow: row wrap;

      span.ant-typography,
      .ant-row.ant-form-item input,
      span.ant-form-item-children,
      .ant-input-number {
        width: 100%;
      }

      .ant-row.ant-form-item {
        width: 40%;
        margin-right: 15px;
      }
    }
  }

  .filter_button {
    margin-left: 20px;
    margin-bottom: 20px;
  }

  &__candidate-summary {
    padding: 20px 24px;
    background: #ffffff;
    box-shadow: 0px 10px 10px 0 rgba(7, 31, 49, 0.06);
    border-radius: 0px !important;
    overflow: auto;

    button:hover,
    button:focus {
      color: $mainColor;
      border-color: $mainColor;
    }

    &_personal-info {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 2em;

      &__tags {
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-tag {
          margin-top: 8px;
        }
      }

      .phone {
        background: #41dc41;
        border-color: #41dc41;
        border-radius: 40px;
        padding: 4px 7px;
        color: white;
        margin-right: 7px;
        margin-top: 10px;
      }

      .mail {
        background: #ec6b6b;
        border-color: #ec6b6b;
        color: white;
        padding: 4px 7px;
        border-radius: 40px;
        margin-right: 7px;
        margin-top: 10px;
      }

      h4.ant-typography {
        margin: 12px 0 0 0 !important;
      }

      .ant-progress {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      div {
        width: 97%;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }

      .candidate-warning .ant-alert-message div {
        text-align: left !important;
      }

      .close-btn {
        border: none;
        position: absolute;
        right: 0;
        top: -35px;
      }
    }

    &__top-traits {
      .ant-tag {
        font-size: 11px;
      }
    }

    .list {
      margin-top: 15px;

      li.ant-list-item {
        display: flex;
        align-items: flex-start;

        .index {
          margin-right: 7px;
          color: rgb(129, 129, 129);
        }
      }
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
      padding: 5px 35px;
      font-size: 13px;
      color: rgba(71, 71, 71, 0.85);
    }

    .ant-alert {
      margin-bottom: 10px;
    }

    .btn-group {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;

      button {
        width: 49%;

        a {
          font-size: 15px !important;
        }
      }
    }

    .icons {
      .ant-tabs-tab-active.ant-tabs-tab {
        span {
          color: $mainColor;
        }
      }

      .ant-tabs-tab span {
        display: flex;
        align-items: center;
        color: #888888;

        i {
          margin-right: 4px;
        }
      }
    }
  }

  &_states {
    .ant-card {
      height: 100%;
      border-right: 1px solid rgb(228, 228, 228) !important;

      ul {
        padding: 0;

        .active-step {
          background: rgba(255, 192, 203, 0.527);
          color: $mainColor;
          box-shadow: $shadow !important;
        }

        li button {
          border: none;
          height: auto;
          color: #868686;
          box-shadow: 0 0 0 0 !important;

          &:hover {
            color: $mainColor;
          }

          .wrap {
            display: flex;
            padding: 12px 0;
            font-weight: 600;
            justify-content: space-between;

            .ant-avatar {
              border: 1px solid rgb(243, 243, 243);
              color: #eb358ab6;
              background: white;
            }
          }
        }
      }
    }
  }

  &_header {
    background: transparent;
    padding: 40px;
  }

  &_content {
    height: 100%;
    padding: 0px;

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: white;
      border: none;
    }

    .ant-radio-button {
      border: none;
    }

    .ant-radio-button-wrapper:first-child {
      border: none;
    }

    &__header {
      padding-bottom: .5em;

      &_content {
        display: flex;
        align-items: center !important;
        padding: 20px;
        margin-top: 1.5em;
        margin-bottom: 0.5em;

        @media screen and (max-width: 400px) {
          padding-top: 30px;
        }

        @media screen and (min-width: 990px) {
          align-items: flex-end;
          justify-content: space-between;
        }

        &-title {
          display: flex;
          align-items: center;

          @media screen and (min-width: 990px) {
            display: flex;
          }

          h4 {
            margin: 0;
            text-transform: uppercase;

            @media screen and (max-width: 1250px) {
              font-size: 13px;
            }
          }

          img {
            width: 80px;
          }

          .headline {
            display: flex;
            align-items: center;
            width: 100%;

            @media screen and (max-width: 500px) {
              flex-direction: column;
              align-items: flex-start;

              .ant-select {
                display: none;
              }
            }

            @media screen and (max-width: 600px) {
              .ant-select {
                margin-left: 0px !important;
              }
            }

            span.ant-badge-status-text,
            i {
              display: none;
            }

            .ant-badge-status-dot {
              width: 13px;
              height: 13px;
              margin-right: 5px;

              @media screen and (max-width: 500px) {
                width: 14px;
                height: 14px;
              }
            }

            .ant-select-selection.ant-select-selection--single {
              width: 100px;
              height: 35px;
              display: flex;
              align-items: center;

              .anticon,
              .anticon-down,
              .ant-select-arrow-icon {
                color: #cccccc;
              }
            }

            .ant-select {
              margin-left: 10px;
              margin-right: 10px;
            }

            .ant-select-selection__rendered {
              margin-left: 8px;
            }

            .ant-select,
            .ant-select-selection {
              background-color: #ffffff !important;
              border-radius: 6px;

              @media screen and (min-width: 500px) {
                &:hover {
                  background: $main-bg;
                }
              }

              @media screen and (max-width: 500px) {
                background: $main-bg;
                max-height: 32px;

                i {
                  font-size: 11px;
                }
              }

              i {
                display: block;
                color: $mainColor;
              }
            }
          }
        }

        .actions {

          button:hover,
          button:focus {
            color: $mainColor;
            border-color: $mainColor;
          }

          &__desktop {
            @media screen and (min-width: 991px) {
              display: flex;
            }

            @media screen and (max-width: 1150px) {
              .ant-btn {
                font-size: 13px;
              }
            }

            @media screen and (max-width: 990px) {
              display: none !important;
            }
          }

          &__mobile {
            a {
              color: grey;
              font-size: 12px;
            }

            @media screen and (min-width: 990px) {
              display: none !important;
            }

            @media screen and (max-width: 991px) {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-left: 10px;
            }
          }
        }

        span.ant-typography {
          font-size: 14px;
        }

        .ant-switch::after {
          top: 0;
        }

        .ant-switch-small {
          height: 14px;
        }

        button {
          margin-right: 7px;
        }
      }
    }

    &__candidate-list {
      background: #fbfbfb;
      padding-bottom: 4em;

      .activeRow>td {
        background: $hover !important;
      }

      tr.ant-table-row:hover>td {
        background: rgb(247, 247, 247) !important;
      }

      tr.ant-table-row {
        cursor: pointer;
      }

      .ant-pagination-prev:focus .ant-pagination-item-link,
      .ant-pagination-next:focus .ant-pagination-item-link,
      .ant-pagination-prev:hover .ant-pagination-item-link,
      .ant-pagination-next:hover .ant-pagination-item-link {
        border-color: $mainColor !important;
        color: $mainColor !important;
      }

      .ant-pagination-item-active,
      .ant-pagination-item-active:focus,
      .ant-pagination-item-active:hover,
      .ant-pagination-item:focus,
      .ant-pagination-item:hover {
        border-color: $mainColor !important;
      }

      .ant-pagination-item-active a,
      .ant-pagination-item-active:focus a,
      .ant-pagination-item-active:hover a,
      .ant-pagination-item:focus a,
      .ant-pagination-item:hover a {
        color: $mainColor !important;
      }

      .ant-table-pagination.ant-pagination {
        float: left;
      }

      .tab-title {
        text-align: center;
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        .total {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          margin-left: 20px;
          font-size: 12px;
        }

        .total-inactive {
          background: rgb(240, 240, 240);
          color: #666;
          @extend .total;
        }

        .total-active {
          background: rgb(255, 216, 222);
          color: $mainColor;
          @extend .total;
        }
      }

      .discard-text-info {
        padding: 20px 50px;
      }

      .cv-btn {
        border: none;
        border-radius: 50px;
        padding: 0 8px;
        font-size: 15px !important;
      }

      .candidate-name {
        color: rgb(17, 17, 17);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: $mainColor;
        }
      }

      .ant-collapse {
        border: none !important;
      }

      .ant-tabs-tab {
        font-size: 13px;
        font-weight: 500;
        color: #a0a0a0;
        text-transform: uppercase;
      }

      .ant-tabs-nav .ant-tabs-tab:hover {
        color: black !important;
      }

      .ant-tabs-nav .ant-tabs-tab-active {
        color: black;
      }

      .ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-top-content {
        background: #fbfbfb;
      }

      .ant-collapse>.ant-collapse-item {
        border-bottom: none;
      }

      .ant-tabs-ink-bar {
        height: 3px;
      }

      table tr:nth-child(odd) td {
        background: white;
      }

      table tr:nth-child(even) td {
        background: white;
      }

      i.anticon.anticon-star {
        font-size: 17px;
      }

      .actions-with-collapsed-sider {
        width: calc(100% - 350px);

        .table__footer {
          z-index: 2;
          width: calc(94% - 350px);
          height: 88px;
          margin: 0 auto 0 3em;
        }
      }

      .table {
        padding: 20px 0px 20px 20px;


        &__actions {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

        }

        &__container {
          overflow: auto;
        }

        &__footer {
          width: 80%;
          max-width: 1215px;
          height: 88px;
          position: fixed;
          bottom: 20px;
          right: 0;
          left: 0;
          margin: auto;
          border-radius: 7px;
          padding: 5px 25px 12px 10px;
          background: #2e2e2e !important;
          box-shadow: $main-shadow;
          border: 1px solid $main-border-color;
          animation: .2s forwards animate-footer;

          @media screen and (max-width: 320px) {
            width: 360px;
            position: fixed;
            border-radius: 0%;
            bottom: 0;
          }

          @media screen and (max-width: 720px) {
            width: 360px;
          }

          @keyframes animate-footer {
            from {
              transform: translateY(100px);
            }

            to {
              transform: translateY(0px);
            }
          }

          .table__actions {
            display: flex !important;
            align-items: center;
            justify-content: space-between;

            .ant-btn {
              padding: 0px 5px 0px 5px;
              min-width: 33px;
              max-width: 210px;
              }
            
            .left {
              display: flex;
              padding: 0px 10px 40px 0px;

              button {
                border: none;
                background: transparent;
                box-shadow: 0 0 0 0;
                padding: 0px 0px 0px 10px;
                color: #ffffff;

                @media screen and (max-width: 340px) {
                  margin: 0px;
                  padding: 0px;
                }

                &:hover {
                  background: #ffffff27;
                }
              }

              span.ant-typography {
                color: rgb(233, 233, 233);
                letter-spacing: 0.5px;

                @media screen and (max-width: 583px) {
                  strong {
                    display: flex;
                    align-items: center;
                    font-weight: 500 !important;
                  }

                  .ant-avatar {
                    background: transparent;
                    border: none;
                    width: 8px;

                    span {
                      color: rgb(235, 235, 235) !important;
                    }
                  }
                }
              }
            }

            .right {
              position: absolute;
              margin-top: 25px;
              display: flex;
              min-width: 100%;
              justify-content: center;

              .ant-btn .anticon {
                width: 21px;
                height: 21px;
              }

              @media screen and (max-width: 720px) {
                display: flex;
              }

              @media screen and (max-width: 583px) {
                display: flex;
                width: 100%;
              }

              @media screen and (max-width: 320px) {
                justify-content: flex-start;
                padding: 15px;
              }

              .ant-btn .ant-btn-primary{
                  max-width: 190px ;
                  width: 100%;
                  min-width: 35px;
              } 

              button {

                &:nth-child(2) {
                  @media screen and (max-width: 700px) {
                    display: none;
                  }
                }

                &:nth-child(1) span {
                  @media screen and (max-width: 700px) {
                    display: none;
                  }
                }
              }
            }
          }

          .show-label{
            @media only screen and (max-width:1410px) and (min-width: 1280px) {
              display: none;
            }
          }

          .responsive-labels{
            @media screen and (max-width: 1279px) {
              display: none;
          }
          }

          .ant-avatar {
            background: #fff;
            color: #424242;
            border: 1px solid $main-border-color;
            margin-right: 7px;
          }

          button {
            height: 36px;
            margin-left: 10px;
            max-width: 220px;
            font-size: 16px;
            color: #292929;
            border-color: $main-border-color;

            &:hover {
              color: #292929;
              filter: brightness(0.9);
            }
          }

          button.ant-btn-primary {
            display: inline-flex;
            align-items: center;

            &:hover {
              color: #fff;
              opacity: 0.8;
            }

            img {
              width: 13px;
              margin-right: 4px;

              @media screen and (max-width: 583px) {
                position: absolute;
              }
            }
          }
        }
      }

      span.ant-table-header-column {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #7b7b7b;
      }

      .ant-tabs-ink-bar {
        background-color: #eb3589;
      }

      span.ant-statistic-content-value-int {
        font-size: 13px !important;
      }

      span.ant-statistic-content-suffix {
        font-size: 11px;
      }

      span.ant-badge.ant-badge-status.ant-badge-not-a-wrapper {
        display: flex;
        align-items: center;
      }

      .ant-statistic-content {
        line-height: 13px;
      }

      .ant-badge-status-dot {
        top: 2px;
      }

      .ant-table-thead>tr>th {
        background: #f1f1f1;
      }

      .ant-table-tbody>tr>td {
        padding: 21px 16px;
      }

      .ant-tabs-nav {
        color: #757575;
      }

      .ant-tabs-bar.ant-tabs-top-bar {
        padding-bottom: 1em;
      }

      .ant-tabs-bar.ant-tabs-top-bar {
        box-shadow: $shadow;
        padding: 0;
        background: white;
        border-bottom: none;

        @media screen and (min-width: 990px) {
          padding: 0 20px;
        }
      }

      .ant-tabs.ant-tabs-top.ant-tabs-line.ant-tabs-no-animation {
        background: #fbfbfb;
      }

      h4.ant-list-item-meta-title {
        margin-bottom: 0;
      }

      .ant-rate {
        font-size: 20px !important;
      }

      li.ant-list-item {
        padding: 22px;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        justify-content: space-between;

        &:hover {
          box-shadow: $shadow;
        }

        a {
          color: $subtitle;
          text-decoration: none;
          font-size: 14px;

          &:hover {
            text-decoration: underline;
          }
        }

        a,
        .ant-rate {
          margin-left: 7px;
        }
      }

      .buttons_group {
        padding: 10px 0px 10px 20px;

        .ant-btn-primary:hover {
          opacity: 0.7;
        }

        .ant-btn-secondary {
          margin-right: 10px;
        }

        .ant-btn-secondary:hover {
          color: $mainColor;
          border-color: $mainColor;
        }
      }
    }

    &__candidate {
      height: 100%;

      .ant-card {
        height: 100%;
        border-radius: 4px;
        padding: 30px 50px;
      }

      &__actions {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        button {
          margin-left: 7px;
        }
      }
    }
  }
}

.filter_cv {
  .ant-drawer-body {
    height: 80%;
  }

  .tag_container {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .checkbox_select_all {
    margin-top: 5px;
  }

  .checkbox_container {
    margin-top: 5px;
    margin-bottom: 35px;
  }

  .ant-select {
    width: 100%;
  }

  .select-row {
    margin-bottom: 20px;
  }
}

.modal-email-container {

  .preview-to {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-btn {
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  .ant-modal-footer {
    border: none;
  }

  .ant-btn-primary:hover {
    opacity: 0.7;
  }

  .ant-btn-secondary {
    margin-right: 5px;
  }

  .ant-btn-secondary:hover,
  .ant-btn-secondary:active,
  .ant-btn-secondary:focus {
    color: $mainColor;
    border-color: $mainColor;

  }

  .email-from {
    .ant-form-item-control {
      line-height: 19px;
    }

    &__helper-text {
      font-size: 12px;
      margin-left: 5px;
      color: #757575;
    }
  }
}

.ant-input-number:focus {
  box-shadow: 0 0 0 2px #eb358a31;
}

.mail-template-submenu {
  max-height: 30vh;
  overflow-y: auto;
}
