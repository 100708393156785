$mainColor: #ea4c89;

.feedback-main-container {
  border-top: 1px solid rgb(236, 236, 236);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 254, 254);

  @media screen and (max-width: 699px) {
    .ant-card {
      width: 100% !important;
    }
  }
}

.candidate-intro {
  .ant-card-head-title {
    padding-top: 20px;
    padding-bottom: 0px;

    @media screen and (max-width: 400px) {
      font-size: 12px;
      padding-top: 70px;
    }
  }

  .ant-card-body {
    padding-top: 15px;
  }

  .ant-card-meta-title {
    padding-bottom: 10px;
  }

  .ant-btn:focus,
  .ant-btn:hover {
    color: $mainColor;
    border-color: $mainColor;
  }

  @media screen and (max-width: 599px) {
    font-size: 13px;
  }
}

.chapter-header {
  .ant-card-head-title {
    color: $mainColor;
    font-weight: 600;

    @media screen and (max-width: 800px) {
      display: flex;
      justify-content: center;
    }

    @media screen and (max-width: 499px) {
      font-size: 12px;
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .chapter-header-description {
      margin-right: 10%;
      margin-bottom: 30px;

      @media screen and (max-width: 899px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
      }

      @media screen and (max-width: 599px) {
        font-size: 13px;
      }
    }

    .chapter-header-image {
      @media screen and (max-width: 799px) {
        margin-top: 20px;

        img {
          width: 90%;
        }
      }
    }

    @media screen and (max-width: 899px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
    }
  }
}

.feedback-game-results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 70%;
  background-color: white;
  padding-bottom: 20px;
}

.game-results {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  background-color: #fff5fb;

  @media screen and (max-width: 499px) {
    width: 300px !important;
  }

  .ant-card-body {
    @media screen and (max-width: 499px) {
      font-size: 12px;
      display: flex;
      flex-direction: column !important;
      justify-content: center;

      .ant-card-meta {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
      }
    }
  }

  &__container {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 499px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0px;
    }

    .title {
      font-size: 14px;
    }

    .game-results-boxes {
      font-weight: bold;

      @media screen and (max-width: 499px) {
        display: flex;
        flex-direction: column;
      }
    }

    .game-results-you {
      background-color: #ffdaef;
    }

    .game-results-average {
      background-color: #81ecec;
    }
  }

  .ant-card-grid,
  .ant-card-grid-hoverable {
    display: flex;
    flex-direction: column;
  }

  .ant-card {
    max-width: 50%;
  }

  .ant-card-body {
    display: flex;
    flex-direction: row;

    .ant-card-meta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ant-card-meta-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }

      .ant-card-meta-title {
        font-weight: bold;

      }
    }
  }
}

.feedback-slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cognitive-slider {

  .ant-card-head {
    .ant-card-head-wrapper {
      .ant-typography {
        @media screen and (max-width: 499px) {
          font-size: 12px;
        }
      }
    }
  }

  .ant-card-body {
    .ant-slider-rail {
      height: 5px;
      background-color: #ffdaef;
    }

    .ant-slider-track {
      height: 5px;
      background-color: transparent !important;
    }

    .ant-slider-handle-1 {
      width: 20px;
      height: 20px;
      background-color: #ea4c89;
      border-color: #ea4c89 !important;
    }

    .ant-slider-handle-2 {
      width: 20px;
      height: 20px;
      background-color: #81ecec;
      border-color: #81ecec !important;
    }

    .cognitive-slider-descriptions {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 499px) {
        font-size: 10px;
      }
    }
  }
}

.ant-tooltip-wide {
  min-width: 50vw;
}

.slider-profile-list {

  .ant-card-meta-detail {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ant-card-meta-description {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      margin-right: 50px;

      .slider-profile-container {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
      }

      .ant-badge-status-dot {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.reasoning-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-card-body {
    @media screen and (max-width: 599px) {
      font-size: 13px;
    }
  }
}

.personality-slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.survey-elements {

  .ant-btn:hover,
  .ant-btn-sm:hover,
  .ant-btn:focus,
  .ant-btn:active {
    color: $mainColor;
    border-color: $mainColor;
  }
}

.ant-tooltip-inner {
  max-width: 700px !important;
  color: #fff5fb;
  background-color: #636363 !important;
}

.custom-slider {
  display: block;
  margin: 0;
  .ant-slider-handle.ant-slider-handle-2 {
    border: none !important;
    background: linear-gradient(
      -90deg,
      #81ecec 50%,
      #ea4c89 50%
    );
  }
}

.intt-report-page{
  min-height: 100vh;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #E6F7FF;
  row-gap: 20px;
  margin: auto;

  .title {
    font-size: 14px;
    font-weight: 700;
    color: #454545;
    margin-bottom: 8px;
    line-height: 130%;
    &__pink {
      color: #EF3C96;
    }
  }

  .genoma-logo {
    padding: 10px 10px;
    filter: brightness(10);
  }

  .cover__ellipse__1 {
    background: radial-gradient(49.96% 49.96% at 49.06% 50.04%, #FF8CCB 0%, #FF5AB4 100%);
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
    padding-top: 50px;
    z-index: 1;
    row-gap: 24px;
    height: 500px;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    .ant-typography {
      color: white;
    }
  }

  .cover__ellipse__2 {
    display: block;
    background-color: #FF9CD2;
    width: 100%;
    height: 200px;
    border-bottom-left-radius: 50% 43%;
    position: relative;
    border-bottom-right-radius: 50% 43%;
    bottom: 166px;
    z-index: 0;
  }

  .text-box {
    &__header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: #40A9FF;
      text-align: center;
      padding: 10px;
      border-radius: 10px 10px 0px 0px;
    }

    &__body {
      display: flex;
      background-color: white;
      padding: 10px 20px;
      row-gap: 10px;
      flex-direction: column;
      border-radius: 0px 0px 10px 10px;
    }
  }

  .progress-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    background-color: white;
    border-radius: 5px;

    &__item {
      padding: 10px 30px;
      text-align: center;
    }
  }

  .intt-strat-entry__number-container {
    color: white;
    background-color: #FF5FB6;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    max-width: 30px;
    max-height: 30px;
  }
}