$mainColor: #ea4c89;

.pf {
  border-top: 1px solid rgb(238, 238, 238) !important;
  background: white !important;
  height: 100%;

  h2 {
    color: #3a3a3a !important;
    margin: 0;
  }

  h4 strong {
    color: #1f1f1f;
  }

  label span, label, label span strong {
    color: rgb(13, 12, 34) !important;
    font-weight: 600 !important;
  }

  button.ant-btn.ant-btn-primary {
    @extend .main-btn;
    color: #fff !important;
    background: $mainColor;

    &:hover {
      color: #fff !important;
      opacity: 0.7 !important;
      filter: none;
    }
  }

  button.ant-btn.ant-btn-link {
    height: auto;
    color: $mainColor !important;
    background: #fff !important;
    padding: 2px !important;
    margin-top: 1em;

    &:hover {
      background: #f3f3f4 !important;
      filter: none !important;
    }

    i {
      margin-left: 0;
      margin-right: 3px;
    }
  }

  .main-btn {
    background: #f3f3f4;
    color: #0d0c22;
    display: inline-block;
    padding: 10px 16px;
    border: none;
    text-decoration: none;
    
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    outline: none;
    font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    border-radius: 8px !important;

    &:hover {
      opacity: 1 !important;
      filter: brightness(0.9);
    }
  }

  button.ant-btn.ant-btn-default {
    @extend .main-btn;

    &:hover {
      color: #0d0c22 !important;
    }
  }

  .ant-alert.ant-alert-error.ant-alert-banner {
    background: #e73a1cbb;
    border-radius: 7px;
    font-size: 16px;
    padding: 15px 40px;
    margin-bottom: 1em;

    span, i {
      color: white !important;
    }

    i {
      top: 17.5px !important;
    }
  }

  .content {
    max-width: 908px;
    padding: 0 0 32px;
    margin: 2em auto;
    min-height: 90vh;

    @media screen and (max-width: 900px) {
      padding: 0 2em 2em;
    }
  }

  &__title {
    margin-bottom: 15px;

    h4 {
      font-size: 1.6em !important;
    }
  }

  &__is-loading {
    display: flex;
    margin: auto;
    padding-right: 2em;
    padding-top: 1em;

    .ant-spin.ant-spin-spinning {
      display: flex;
      margin: auto;
    }
  }

  &__divider {
    padding-top: 2em;
    padding-bottom: 2em;

    .ant-divider.ant-divider-horizontal {
      height: 0;
    }
  }

  &__toolbar {
    @media screen and (min-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .breadcrumb {
      span {
        color: rgb(199, 199, 199);
        margin-left: 5px;
        margin-right: 5px;
      }
  
      h4 {
        margin-bottom: 0 !important;
      }
    }

    &__reward {
      padding: 15px 30px;
      border-radius: 7px;
      text-align: center;
      border: 2px solid rgb(231, 231, 231);
  
      .sub {
        display: flex;
        align-items: center;
        justify-content: center;
  
        span {
          color: #fda500;
          font-size: 20px;
          text-transform: uppercase;
        }
  
        img {
          width: 25px;
          margin-right: 10px;
        }
      }
    }
  }

  &__aside-menu {
    padding-right: 20px;

    @media screen and (max-width: 768px) {
      display: none !important;
    }

    .aside-menu {
      margin-top: 1em;
  
       ul {
         min-width: 220px;
       }
  
       .ant-menu-inline, .ant-menu-vertical, 
       .ant-menu-vertical-left, .ant-menu-vertical .ant-menu-item::after, 
       .ant-menu-vertical-left .ant-menu-item::after, 
       .ant-menu-vertical-right .ant-menu-item::after, 
       .ant-menu-inline .ant-menu-item::after {
        border: none !important;
      }
  
      .ant-menu-item {
        display: flex;
        font-weight: 600;
        align-items: center;
        white-space: normal;
        line-height: 1.5;
        color: rgb(100, 100, 100);
        padding-left: 2px !important;
        padding-right: 0 !important;
        transition: none !important;

        div span {
          color: red;
        }
      }
  
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, 
      .ant-menu-item:active, .ant-menu-submenu-title:active {
        color: rgb(100, 100, 100);
        background: transparent;
      }
  
      .ant-menu-item:hover, 
      .ant-menu-item-active, 
      .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
      .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
        background: transparent;
        color: black !important;
        font-weight: 600;
      }

      .check {
        min-width: 15px;
        display: flex;
        align-items: center;
        margin-right: 7px;

        img {
          width: 100%;
        }
      }
    }
  }

  &__alert-message {
    line-height: 1.5;
    color: black;
    margin-top: 1em;
    margin-bottom: 1em;
    border-radius: 8px;
    padding: 20px;
    font-weight: 500;
    background: linear-gradient(0deg, rgba(243,243,244,0.8), rgba(243,243,244,0.5)),#fff;

    a {
      font-size: 14px;
      text-decoration: none;

      &:hover {
        opacity: 0.7;
      }
    }

    span {
      color: $mainColor;
    }
  }

  &__form-wrapper {  
    background: white;
    border-radius: 0px !important;
    margin: auto;
    width: 100%;
    max-width: 1550px;

    form {
      margin-top: 1.5em;
    }

    &__discapacity-field {
      .ant-col.ant-form-item-label {
        line-height: 14px !important;
      }
  
      a {
        font-size: 14px;
        color: $mainColor;
        text-decoration: underline;
      }
    }

    &__phone-field { 
      .ant-form-explain {
        padding-left: 11em;
      }
      
      span.ant-form-item-children {
        display: flex;
      }
  
      .phone-prefix.ant-select.ant-select-enabled {
        max-width: 8rem;
        margin-right: 10px;
      }

      .ant-select-selection__rendered {
        position: absolute;
        top: 50%;
        right: 9px;
        left: 0;
        max-width: 100%;
        height: 30px;
        margin-top: -10px;
        overflow: hidden;
        line-height: 20px;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
      }
    }

    &__cv-field {
      .pf__title {
        margin-bottom: 25px !important;
      }
    }

    &__submit {
      padding-top: 3em;
      padding-bottom: 7em;
      display: flex;
      margin-top: 3em;
  
      @media screen and (max-width: 600px) {
        display: grid !important;
        justify-content: center !important;
        grid-template-rows: repeat(1, 1fr);
        grid-template-columns: repeat(1, 1fr);
      }

      button {
        @media screen and (max-width: 600px) {
          width: 100%;
          max-width: 25em;
        }
      }

      button:first-child {
        margin-right: 15px;

        @media screen and (max-width: 600px) {
          margin-bottom: 15px;
          margin-right: 0;
        }
      }
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
      .cloud-container .upload-shot-cloud {
        color: $mainColor;
      }
    }

    &__cloud {
      padding: 10px;
      .cloud-container {
        position: relative;
        width: 161px;
        margin: 0 auto 20px;
        -webkit-transform: translateY(5px);
        -ms-transform: translateY(5px);
        transform: translateY(5px);
        display: none;
      }
    
      .cloud-container {
        display: block;
      }
    
      .cloud-container .upload-shot-cloud {
        color: #dbdbde;
        -webkit-transition: color 0.1s ease-in;
        transition: color 0.1s ease-in;
      }

      .fill-current {
        fill: currentColor;
      }

      .fill-current {
        fill: currentColor;
      }

      .cloud-container .cloud-arrow-container {
        position: absolute;
        left: 50%;
        bottom: 27px;
        z-index: 1;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        color: #fff;
      }

      .cloud-container .cloud-arrow-stripes {
        position: absolute;
        width: 27px;
        top: 90px;
        left: 50%;
        -webkit-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: translateX(-50%) scaleY(0);
        -ms-transform: translateX(-50%) scaleY(0);
        transform: translateX(-50%) scaleY(0);
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        overflow: hidden;
      }

      .cloud-container .cloud-arrow-container:after {
        content: '';
        position: absolute;
        top: 17px;
        left: 30.8px;
        width: 13px;
        height: 21px;
        background: #fff;
        transition: -webkit-transform 0.07s ease-in;
        -webkit-transition: -webkit-transform 0.07s ease-in;
        transition: transform 0.07s ease-in;
        transition: transform 0.07s ease-in, -webkit-transform 0.07s ease-in;
        -webkit-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
      }

      .cloud-container .cloud-arrow-stripes .cloud-arrow-stripe {
        width: 9px;
        height: 45px;
        display: inline-block;
        float: left;
        -webkit-animation: animate-gradient-stripe 0.5s linear infinite;
        animation: animate-gradient-stripe 0.5s linear infinite;
        background-size: 100% 400%;
      }
    }
  }

  &__table-actions {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 450px) {
      justify-content: flex-start;
      margin-top: 10px;
    }

    button {
      @media screen and (max-width: 450px) {
        padding: 0 10px !important;
      }
    }

    button:first-child {
      margin-right: 7px;
    }

    button:last-child {
      &:hover {
        background: tomato;
        filter: none !important;

        i {
          color: white;
        }
      }
    }
  }

  &__table-list {
    height: 100%;

    li {
      @media screen and (max-width: 450px) {
        display: grid;
      }
    }
  }

  /*-------------------------------------------------
  * ANTD classes
  *--------------------------------------------------*/

  .ant-spin.ant-spin-spinning.ant-spin-show-text {
    .ant-spin-text {
      color: $mainColor;
      font-weight: 800;
    }
  }

  .ant-select-lg.ant-select.ant-select-enabled{
    margin-right: 10px;
  }

  span.ant-radio-inner {
    width: 20px;
    height: 20px;
  }

  .ant-radio-inner::after {
    width: 12px;
    height: 12px;
  }

  span.ant-typography.ant-typography-secondary {
    font-weight: 400 !important;
    font-size: 16px;
  }
  input:disabled {
    opacity: 0.5;
  }

  span.ant-upload-list-item-card-actions {
    display: none;
  }

  input {
    color: black;
    background: #F3F7F6 !important;
  }

  .ant-select-selection.ant-select-selection--single {
    background: #F3F7F6 !important;
  }

  .ant-page-header.ant-page-header-ghost {
    background: white;
    padding-top: 2em;
    padding-left: 0;

    i {
      color: black !important;
    }

    span.ant-page-header-heading-title {
      font-size: 15px;
      color: black;
    }
  }

  .ant-form-explain {
    text-align: left !important;
    margin-top: 0 !important;
    font-size: 13px !important;
  }

  .ant-input:focus, 
  .ant-select-focused .ant-select-selection, 
  .ant-select-selection:focus, 
  .ant-select-selection:active,
  .ant-select-selection.ant-select-selection--single:focus {
    border-color:#eb358a2d;
    -webkit-box-shadow: 0 0 0 4px #eb358a1c;
    box-shadow: 0 0 0 4px #eb358a1c;
  }

  .ant-input:hover, 
  .ant-select-selection:hover,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner,
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: $mainColor;
  }

  .ant-radio-inner::after {
    background: $mainColor;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: $mainColor;
  }

  .ant-row.ant-form-item {
    .ant-col.ant-form-item-label {
      font-size: 15px !important;
    }
    .ant-form-item-label > label {
      color: rgba(70, 70, 70, 0.85);
    }
  }

  .ant-col.ant-form-item-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 33px;
  }

  .ant-form-item-label {
    white-space: normal;
  }

  span.ant-input-group-addon {
    padding: 0;
  }

  div#phone-prefix-input {
    margin: 0 !important;
    border: none;
  } 
  
  .ant-input,label.ant-radio-button-wrapper, 
  .ant-select-selection.ant-select-selection--single {
    font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 40px;
    padding: 10px 16px;
    outline: none;
    border-radius: 8px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #0d0c22;
    background-color: #f3f3f4 !important;

    &:focus {
      background-color: white !important;
    }
  }

  i.anticon.anticon-down.ant-select-arrow-icon {
    color: black;
  }

  .ant-input {
    border: none;
    border-radius: 8px !important;
  }

  .ant-select-selection.ant-select-selection--single {
    border: 0;
  }
 
  .ant-select-lg.ant-select.ant-select-disabled {
    .ant-select-selection.ant-select-selection--single {
      background: rgba(226, 226, 226, 0.26) !important;
    }
  }
  .ant-select-selection.ant-select-selection--single, .ant-input {
    padding: 22px 10px;
    display: flex;
    align-items: center;
  }

  .ant-select-lg.ant-select.ant-select-enabled {
    @media screen and (max-width: 350px) {
      margin-bottom: 10px;
    }
  }

  .ant-input:focus, 
  .ant-radio-button-wrapper:active, 
  .ant-radio-button-wrapper:focus, 
  .pf .ant-select-focused .ant-select-selection, 
  .pf .ant-select-selection:focus, 
  .pf .ant-select-selection:active,
  .ant-select-selection.ant-select-selection--single:focus {
    border-bottom-color: #f168a8 !important;
  }
  span.ant-input-group.ant-input-group-compact {
    @media screen and (min-width: 350px) {
      display: flex;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $mainColor;
    border-top: 1px solid #e4e3e3 !important;
    border-right: 1px solid #e4e3e3 !important;
    border-bottom-color: $mainColor !important;
    outline: none;
  }

  label.ant-checkbox-wrapper {
    line-height: 1.5;
  }

  .ant-radio-button-wrapper:hover {
    color: $mainColor;
  }

  .ant-select-selection.ant-select-selection--single:focus {
    border-radius: 0;
    border-bottom-color: $mainColor;
  }

  .ant-form-item-required::before {
    content: '';
  }

  button.ant-modal-close {
    z-index: 9999;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $mainColor !important;
    border-color: $mainColor !important;
  }
  
  .ant-checkbox-checked::after {
    border: 1px solid $mainColor !important;
  } 
  
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $mainColor !important;
  }
}

.careerModal, .experienceModal { 
  width: 700px !important;
  border-radius: 20px !important;
  padding: 0 !important;
  margin: 3em 0 !important;

  .header {
    z-index: 99;
    background: white;
    padding-bottom: 15px;
    min-height: 52px;

    h2 {
      font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
    }
  }

  .ant-modal-body {
    padding: 40px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .footer {
    background: white;
    padding-top: 15px;
  
    .ant-form-item-control {
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: 500px) {
        justify-content: flex-start;
      }
    }
  
    button:first-child {
      &:hover, &:focus, &:active {
        color: $mainColor;
      }
    }
  
    .ant-row.ant-form-item {
      margin: 0;
    }
  }
}

.experienceModal {
  .date-fields {
    .ant-row.ant-form-item {
      width: 50%;

      div#experience-modal_month_start, 
      div#experience-modal_year_start,
      div#experience-modal_month_end, 
      div#experience-modal_year_end {
        width: 100%;
      }
    }
  }

  .ant-select-selection.ant-select-selection--single {
    width: 96% !important;
  }
}

.ant-modal-mask {
  background: rgba(30,30,30,0.9) !important;
}
