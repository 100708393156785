@import '../../CandidateProfilev2/CandidateProfile.scss';

.tools-dashboard-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h1.ant-typography {
    padding: 0;
    margin: 0;
  }

  .ant-tabs-bar {
    margin: 0;
  }

  .ant-table-pagination.ant-pagination {
    margin: 0;
    padding: 1rem;
  }

  .ant-layout-header {
    line-height: 24px;
  }

  &__card-table {
    background-color: #ffffff;
  }

  &__tabs-container {
    &.ant-tabs {
      border: 1px solid #d9d9d9;
    }
    // color: $mainColor;
    // .ant-tabs-nav .ant-tabs-tab-active,
    // .ant-tabs-tab:hover {
    //   color: $mainColor !important;
    // }
    // .ant-tabs-ink-bar {
    //   background-color: $mainColor !important;
    // }
  }

  &__rubrics-table {
    table {
      th.ant-table-row-cell-break-word {
        background-color: #ffffff;
      }
    }

    .custom-cell {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .ant-table-column-title {
    font-weight: bold;
    color: #7b7b7b;
  }

  .ant-btn-secondary:active,
  .ant-btn-secondary:focus,
  .ant-btn-secondary:hover {
    color: $mainColor;
    border-color: $mainColor;
  }

  .ant-table-column-title {
    font-weight: bold;
    color: #7b7b7b;
  }

  &__bulk-actions {
    height: 5.5rem;
    width: 38.688rem;
    background-color: #454545;
    border-radius: 4px;
    top: -10vh;
    left: 25vw;
  }

  &__personal-form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

.new-rubric-btn {
  display: flex;
  justify-content: flex-end;
}

.rubrics__card {
  h3.ant-typography {
    color: #333333;
  }
}

.custom-pform-header {
  padding-bottom: 0.25rem;
  margin-bottom: 1rem;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  display: flex;
  align-items: center;
  gap: 1rem;

  > div {
    flex-grow: 1;
  }

  > span {
    font-size: 16px;
  }
}

.custom-pform-content {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;

  &__item {
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-select {
    width: 140px;
    margin: 0;
  }

  .ant-select-enabled {
    margin: 0;
  }
}
