@import '../../styles/variables.scss';

.edit-business-user-profile {
  font-family: "Lato";
  height: 100%;

  // Main Card style
  .main-card {
    background-color: $neutral-2;
    *.ant-card-head{
      border: none;

      &-title {
        font-weight: 700;
        font-size: 1.9em;
      }
    }

    *.ant-card-body {
      padding-top: 0;
      font-weight: 400;
    }
  }
}

.options {
  .ant-avatar {
    color: $mainColor;
    background-color: $neutral-3;
    box-shadow: 1px 1px 4px 0px #4040401A inset;
  }

  .ant-card {
    padding: 0;
    border: 1px solid $neutral-5;
    min-height: 110px;

    *.ant-card-meta-title {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 0.5em;
    }

    *.ant-card-body {
      justify-content: space-around;
      align-items: center;
      padding: 0;

      *.ant-card-meta {
        margin: 0;
        padding: 16px;
      }
    }
  }
}

.ant-card-bordered.change-password {
  border: 1px solid $neutral-5;
}

.change-password {
  *.ant-card-body {
    padding: 0;
    
    h1{
      &.ant-typography {
        color: $character-tertiary;
        font-weight: 700;
        font-size: 18px;
        margin: 0;
      }
    }

    *.ant-form {
      .ant-input-password {
        .ant-input {
          height: 32px;
        }
      }
    }
  }
}
