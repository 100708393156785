.candidate-interview {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  align-items: flex-start;

  > button {
    color: #5e5e5e;
    font-size: 18px;
    padding: 0;
  }

  > header {
    display: flex;
    gap: 1rem;

    > img {
      height: 80px;
      width: 80px;
      object-fit: cover;
      border: 0.5px solid #d9d9d9;
      border-radius: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }

  &__text-row {
    display: flex;
    gap: 1.5rem;
    color: #5e5e5e;

    &:nth-child(2) {
      margin: 0.5rem 0;
    }

    &:nth-child(3) {
      margin: 0.5rem 0;
    }

    &:nth-child(4) {
      margin: 0.5rem 0;
    }
  }
}
