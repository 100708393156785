@import '../../../../../../../../styles/variables.scss';

.af__switch {
  margin-top: 3.5em;
  border-radius: 7px;

  .required, .optional {
    font-size: 14px;
  }
  .required {
    color: $primary-color;
  }
  .optional {
    color: #a1a1a1;
  }
  h4.ant-list-item-meta-title {
    span {
      font-size: 15px !important;
    }
    .optional {
      color: #666;
    }
    .required {
      font-weight: 600;
    }
  }

  li {
    span.ant-typography.ant-typography-secondary {
      @media screen and (max-width: 400px) {
       display: none;
      }
    }
  }

  .ant-collapse-header {
    padding-left: 25px !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 0;
  }

  .headline {
    .text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 400px) {
        display: grid;
      }

      label {
        font-weight: 500;

        @media screen and (max-width: 400px) {
          margin-top: 8px;
        }
      }
      span.ant-typography {
        font-size: 18px;
      }
    }
  }

  li {
    border: none !important;

    h4 {
      font-size: 15px;
    }
  }

  .ant-switch {
    margin-left: 10px;
  }

  @media screen and (max-width: 600px) {
    .ant-switch {
      height: 18px;
      min-width: 35px;

      &::after {
        width: 14px;
        height: 14px;
      }
    }
  }

  //collapse
  .ant-collapse-header, .ant-collapse-borderless > .ant-collapse-item {
    padding-left: 0;
    .ant-collapse-header {
      color: #272727 !important;
      border-bottom: none !important;
      font-weight: 600;

      i {
        color: $mainColor !important;
      }
    }
    h4.ant-list-item-meta-title {
      font-weight: 600;
    }
    span {
      font-size: 14px;
    }
    span, h4.ant-list-item-meta-title {
      color: $primary-color;
    }
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background: #fff;
  }

  //switch
  @media screen and (min-width: 600px) {
    .ant-switch:not(.ant-switch-disabled):active::before, 
    .ant-switch:not(.ant-switch-disabled):active::after {
      width: 50%;
    }

    .ant-switch {
      min-width: 70px;
      text-align: right !important;
      border-radius: 4px;
      height: 25px !important;
      padding-top: 7px;
      padding-bottom: 5px;
      background: #ececec;
      font-size: 14px;
      padding-right: 5px;
      margin-left: 7px;

      &::after {
        width: 50%;
        height: 88%;
        border-radius: 3px;
      }
    }
  }

  .ant-switch-checked {
    background-color: $primary-color;
  }


  &__values-list {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .ant-spin-nested-loading {
      width: 100%;
    }
  }
}