.jag {
    background: white !important;

    &__cover {
        @media screen and (max-width: 720px) {
            height: 50vh;
        }

        &__img {
            width: 100%;
        }

        &__centered-text {
            margin: auto;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: min-content;
            padding-left: 20px;
            padding-right: 20px;

            h1, h1 p  {
                color: white !important;
                margin-bottom: 0 !important;
                max-width: 600px;
                margin: auto;
                line-height: 1em;

                @media screen and (min-width: 770px) {
                    font-size: 3.5em !important;
                }

                @media screen and (min-width: 990px) {
                    font-size: 76px !important;
                }
            }
            h2, h2 p  {
                color: white !important;
                margin-bottom: 0 !important;
                max-width: 600px;
                margin: auto;
                line-height: 1em;

                @media screen and (min-width: 770px) {
                    font-size: 2.5em !important;
                }

                @media screen and (min-width: 990px) {
                    font-size: 60px !important;
                }
            }

            .total-jobs {
                margin-bottom: 3em;
                text-transform: uppercase;

                span {
                    color: white;
                    font-size: 1.3em;
                    text-transform: initial;

                    @media screen and (min-width: 700px) {
                        font-size: 1.8em;
                    }
                }
            }

            button {
                background-color: white;
                color: #f84dab !important;
                font-size: 24px;
                letter-spacing: -0.03em;
                height: auto;
                padding: 9px 20px;
                margin-top: 1em;
                border: none;

                @media screen and (min-width: 700px) {
                    font-size: 29px;
                }
            }
        }
    }

    &__welcome {
        max-width: 800px;
        margin: auto;
        padding-top: 1em;
        padding-bottom: 3em;
        padding-left: 1em;
        padding-right: 1em;
        text-align: center;

        p {
            color: rgb(43, 43, 43);
        }
        p, p span, p strong {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
        }
    }

    &__rrss {
        display: flex;
        justify-content: flex-end;
        padding-right: 4em;
        padding-top: 2em;

        a {
            background: #8a9ca57a;
            border-radius: 50px;
            margin-right: 30px;
            text-decoration: none;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background: #627884;
            }

            img {
                width: 15px;
                height: 15px;
                color: white;  
            }
        }
    }

    &__jobs {
        max-width: 800px;
        margin: auto;
        padding-top: 3em;
        padding-bottom: 7em;
        padding-left: 1em;
        padding-right: 1em;

        &__filters {
            display: flex;
            align-items: center;
            margin-bottom: 3em;

            span.ant-typography {
                color: rgb(117, 117, 117) !important;
            }

            .ant-select {
                margin-left: 20px;
            }
            .ant-select:hover {
                border-color: #ff18ba !important;
            }
        }

        &__list {
            margin-bottom: 4em !important;

            .ant-row-flex {
                width: 100%;

                .ant-col {
                    &:nth-child(2), &:nth-child(3) {
                        text-align: end;
                    }
                }
            }

            &-item {
                padding: 25px 0 !important;

                .ant-typography {
                    font-size: 17px;
                }

                button, button:hover, button:focus {
                    background: #f770a4;
                    border: none;
                    color: white;
                }

                .location {
                    display: block;

                    @media screen and (max-width: 766px) {
                        display: none;
                    }
                }
            }
        }
    }

    footer {
        display: flex;
        justify-content: center;

        a {
            color: #666;
            text-decoration: none;

            &:hover {
                color: #f1309b;
                text-decoration: underline;
            }
        }
    }

    .ant-tabs-tab {
        font-size: 17px;
    }

    .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-tab:hover {
        color: #ff18ba !important;
    }

    .ant-tabs-ink-bar {
        background-color: #ff18ba;
    }

    .ant-tabs-bar.ant-tabs-top-bar {
        margin-bottom: 3em;
    }

    .ant-list-split .ant-list-header {
        border-bottom: none;
    }

    .ant-list-split .ant-list-header h2 {
        @media screen and (max-width: 700px) {
            font-size: 24px !important;
        }
    }

    .ant-select-selection:hover {
        border-color: #ff18ba;
    }

    .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
        border-color: #ff18ba;
        -webkit-box-shadow: 0 0 0 2px #ff18ba46;
        box-shadow: 0 0 0 2px #ff18ba3f;
    }
}

.jag-flag {
    height: 21px;

    img {
        width: 20px;
        margin-right: 4px;
    }
}

.location-with-flag {
    display: flex;
    align-items: center;
}