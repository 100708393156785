@import '../../styles/variables.scss';

.references-table {
  .ant-table-thead {
    th {
      background-color: white;
    }
  }
  .ant-table-body {
    tr {
      background-color: white;
    }
  }
  .reference-templates-actions {
    *i:hover {
      cursor: pointer;
    }
  }
}

.reference-templates-form {
  margin: 40px;

  &-title {
    margin-bottom: 20px;

    .anticon-arrow-left {
      font-size: 20px;
      margin-right: 11px;
      color: $character-tertiary;
    }

    .ant-typography {
      margin: 0;
      color: #2b2b2b;
    }
  }

  &-tabs {
    .reference-templates {
      background: white;

      .ant-tabs-bar {
        margin-bottom: 0px;
      }

      .ant-tabs-nav .ant-tabs-tab-active,
      .ant-tabs-tab:hover {
        color: $mainColor !important;
      }

      .ant-tabs-nav {
        margin-left: 20px;
      }

      .ant-tabs-ink-bar {
        background-color: $mainColor !important;
      }

      .ant-tabs-tabpane {
        background-color: white;
        height: auto;

        .form-content {
          margin: 20px;

          .reference-question,
          .reference-question-alternative {
            .ant-select {
              width: 100%;
            }

            .anticon {
              color: #a4a4a4;
              font-size: 20px;
            }
          }

          .add-question,
          .add-alternative {
            .ant-col {
              cursor: pointer;
              width: min-content;
              min-width: fit-content;

              .anticon,
              .ant-typography {
                margin-right: 6.25px;
                color: $mainColor;
              }
            }
          }
        }
      }
    }
  }
}

.references-form {
  margin: 40px;

  span {
    font-size: 14px;
  }

  .ant-radio-group,
  .ant-checkbox-group {
    label {
      display: block;
      margin-left: 0;
    }
  }

  &__is-loading {
    display: flex;
    margin: auto;
    padding-right: 2em;
    padding-top: 1em;

    .ant-spin.ant-spin-spinning {
      display: flex;
      margin: auto;
    }
  }

  .send-questionnaire {
    border: 1px solid $mainColor;
    background-color: $mainColor;
    color: white;

    &:hover {
      background: #fff0f6;
      color: $mainColor;
    }
  }
}

.table__footer {
  animation: 0.2s forwards animate-footer;
  background: #2e2e2e !important;
  border: 1px solid $main-border-color;
  border-radius: 7px;
  bottom: 20px;
  box-shadow: $main-shadow;
  height: 88px;
  left: 0;
  margin: auto;
  max-width: 1215px;
  padding: 5px 25px 5px 25px;
  position: fixed;
  right: 0;
  width: calc(94% - 350px);
  z-index: 1000;

  display: flex !important;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 320px) {
    width: 360px;
    position: fixed;
    border-radius: 0%;
    bottom: 0;
  }

  @media screen and (max-width: 720px) {
    width: 360px;
  }

  @keyframes animate-footer {
    from {
      transform: translateY(100px);
    }

    to {
      transform: translateY(0px);
    }
  }

  .table__actions {
    width: 100%;

    .left {
      display: flex;

      .close-footer {
        border: none;
        background: transparent;
        box-shadow: 0 0 0 0;
        padding: 0;
        color: #ffffff;

        &:hover {
          background: #ffffff27;
        }
      }

      span.ant-typography {
        color: rgb(233, 233, 233);
      }
    }

    .right {
      display: flex;

      @media screen and (max-width: 720px) {
        display: flex;
      }

      @media screen and (max-width: 583px) {
        display: flex;
        width: 100%;
      }

      @media screen and (max-width: 320px) {
        justify-content: flex-start;
        padding: 15px;
      }
    }
  }

  button {
    border-color: $main-border-color;

    &:hover {
      color: #292929;
      filter: brightness(0.9);
    }
  }

  .ant-btn-primary {
    display: inline-flex;
    align-items: center;

    &:hover {
      color: #fff;
      opacity: 0.8;
    }

    img {
      width: 13px;
      margin-right: 4px;

      @media screen and (max-width: 583px) {
        position: absolute;
      }
    }
  }
}

.references-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1.ant-typography {
    margin: 0;
  }
}
