$mainColor: #ea4c89;

.login {
  .ant-card {
    @media screen and (max-width: 630px) {
      width: 100% !important;
      padding: 0;
    }
  }

  @media screen and (max-width: 400px) {
    padding-top: 50px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  &__div_forgot {
    display: flex;
    justify-content: center;
  }

  &__text_forgot {
    font-size: 13px;
  }
  &__text_forgot:hover {
    color: $mainColor;
  }
}

.login-form {
  max-width: 300px;
}

.login-form-button {
  width: 100%;
}

.social-login-buttons-group {
  padding-top: 15px;

  > button {
    font-size: 16px;
    line-height: 22px;
    color: #786d73 !important;
  }

  @media screen and (max-width: 930px) {  
    width: 100%;
  }

  button.ant-btn:nth-child(1) {
    margin-bottom: 10px;
  }
}

.ant-divider {
  border: none !important;
}
