.ant-steps-item-process .ant-steps-item-icon {
  background: #597EF7;
  border-color:#597EF7;
}

.share-card-icon{
  font-size: 50px;
  color: #597EF7 !important;
  padding: 20px 20px;
  border: 2px solid #597EF7;
  border-radius: 50%;
  background-color: #F0F5FF;
}

.share-card-button:not(:disabled) {
  background-color: #597EF7;
  color: #fff;
}

.inverse-primary{
  background-color: white !important;
  color: #F175A5 !important;
  border-color: #F175A5 !important;
}

.qr-background{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 10px;
  row-gap: 12px;
  background: linear-gradient(131.39deg, rgba(255, 255, 255, 0.4) 16.98%, rgba(255, 255, 255, 0.1) 80.03%);
  backdrop-filter: blur(20px);
  border: 2px solid;
  border-image: linear-gradient(123.71deg, rgba(255, 255, 255, 0.6) , rgba(26, 167, 247, 0.6) , rgba(26, 207, 247, 0.3) , rgba(229, 0, 234, 0.2) , rgba(229, 0, 234, 0.6)) 30;
}