.fit-img {
  max-width: 100%;
  max-height: 100%;
}

.bg-img {
  height: 160px;
  background-position: center;
  margin: -12px -12px 0px -12px;
  padding: 0px;
  background: rgb(255, 240, 246) none repeat scroll 0% 0%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.home-container {
  // height: calc(min(1080px, 100vh));
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .ant-card {
    padding: 0px;
  }
}

.home-content {
  width: 100%;
}

.home-right {
  display: flex !important;
  flex-direction: column;
  gap: 10px;

  > div {
    flex-grow: 1;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
  }
}

.home-left {
  display: flex !important;
  flex-direction: column;
  gap: 10px;

  > div {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }

  &__interviews {
    background-color: #ffffff;

    h4.ant-typography {
      margin: 0;
    }

    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .empty-ints {
      height: 100%;
      display: grid;
      place-items: center;

      span {
        color: #a4a4a4;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__interview {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  &__processes {
    &__with-ints {
      overflow-y: scroll;
      max-height: 375px;
    }

    .ant-divider {
      margin: 0.75rem 0;
    }
  }

  > div:last-child {
    flex-grow: 1;
  }

  &__metrics {
    // new code
    background: #233776;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h3.ant-typography {
      margin: 0;
    }

    h3.ant-typography,
    span:not(button span),
    p:not(button p) {
      color: #ffffff;
    }

    &__metric {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      span {
        font-size: 1.75rem;
      }
    }
  }
}

.div-headers {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 17px;
    font-weight: bold;
    color: #454545;
  }

  a {
    font-size: 16px;
    //font-weight: bold;
    align-items: center;
    color: #40a9ff;
  }

  @media screen and (max-width: 1200px) {
    p {
      font-size: 15px;
    }

    a {
      font-size: 13px;
      font-weight: bold;
      align-items: center;
      color: #40a9ff;
      text-align: right;
    }
  }
}

.post-body {
  font-size: 15px;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 0px;
}
.new-processes-home-list {
  //new code
  padding: 1rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h4.ant-typography {
    margin: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__process {
      display: flex;
      gap: 1rem;
      position: relative;
      padding-left: 1rem;
      cursor: pointer;

      .confidential-icon {
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        height: fit-content;
        padding: 2px 4px;
        background-color: #fafafa;
      }

      p {
        color: #a4a4a4;
        font-size: 14px;
      }

      &:hover {
        span {
          color: #597ef7;
        }
      }

      &::before {
        position: absolute;
        content: '•';
        color: #52c41a;
        left: 0;
        top: -2px;
      }
    }
  }

  &__no-data {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

    span {
      font-size: 60px;
    }

    p {
      text-align: center;
    }
  }
}

.header-button {
  // new code
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
