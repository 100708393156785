@import "../../../../styles/variables.scss";
$mainColor: #f175a5;

.ant-upload.ant-upload-drag:hover,
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: $mainColor;
}

.ant-switch-checked {
  background-color: $mainColor;
}

tr.ant-table-row:hover > td {
  background: rgb(247, 247, 247) !important;
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus,
.ant-btn-secondary:active {
  border-color: $mainColor;
  color: $mainColor;
}

.ant-pagination {
  float: left;

  $hover-color: #616161;

  li {
    &:hover {
      border-color: $hover-color;

      a {
        color: $hover-color;
      }
    }

    @media screen and (max-width: 400px) {
      min-width: 28px;
      height: 28px;
    }

    a {
      @media screen and (max-width: 400px) {
        font-size: 12px;
        line-height: 2.2;
      }
    }
  }

  .ant-pagination-item-active {
    background: white;
    border-color: #1890ff;

    a {
      color: #1890ff !important;
    }
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: $hover-color;
    color: $hover-color;
  }
}

.ant-modal {
  width: 800px; // !important TECHDEBT: aplicar solo a modales del componente o encapsular en otra clase

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    // border: none;
  }
}

.ant-btn-primary:hover {
  opacity: 0.7;
}

.modal-title-text {
  margin-left: 5px;
  margin-bottom: 10px;
}

.modal-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.import-main {
  margin-bottom: 10rem;
}

.clickable {
  :hover {
    cursor: pointer;
  }
}

.import__footer {
  width: 70%;
  position: fixed;
  bottom: 30px;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 7px;
  padding: 20px 25px 20px 10px;
  background: #2e2e2e !important;
  box-shadow: $main-shadow;
  border: 1px solid $main-border-color;

  @media screen and (max-width: 340px) {
    padding: 13px 15px 13px 0 !important;
  }

  @media screen and (max-width: 583px) {
    width: 100%;
    bottom: 0;
  }

  @media screen and (max-width: 700px) {
    padding: 16px 25px 16px 10px;
  }

  .actions {
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 583px) {
      justify-content: flex-start;
    }

    button {
      height: 36px;
      margin-left: 7px;
      color: #292929;

      @media screen and (max-width: 340px) {
        margin-right: 0;
      }

      &:hover {
        background: $mainColor;
        border-color: $mainColor;
        color: #ffffff;
      }
    }

    .left {
      display: flex;
      align-items: center;

      span.ant-typography {
        color: rgb(233, 233, 233);
        letter-spacing: 0.5px;

        @media screen and (max-width: 583px) {
          strong {
            display: flex;
            align-items: center;
            font-weight: 500 !important;
          }

          .ant-avatar {
            background: transparent;
            border: none;
            width: 8px;

            span {
              color: rgb(235, 235, 235) !important;
            }
          }
        }

        @media screen and (max-width: 415px) {
          display: none;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: end;

      .import-button {
        background-color: $mainColor;
        color: white;
        border-color: $mainColor;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }
}
