.candidate-list-container {
  background: #fafafa;
  padding: 1.25rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ant-typography-edit {
    color: #2b2b2b;
    font-size: 15px;
  }

  .ant-select-selection.ant-select-selection--multiple {
    height: 32px;
  }

  .ant-typography strong {
    font-size: 24px;
    font-weight: 600;
  }

  .ant-btn-link {
    color: #2b2b2b;
  }

  .ant-select:focus,
  .ant-select-focused,
  .ant-select:active {
    background-color: transparent !important;
  }

  // IM TRYING TO REMOVE WEIRD THIS MARGINS, WE SHOULD SPACE ITEMS WITH GAP in flex or grid :)

  // &__process-info {
  //   margin-top: 2.688rem;
  // }

  &--icon {
    margin-right: 0.375rem;
  }

  // &--button {
  //   margin-left: 0.5rem;
  // }

  // .ant-btn,
  // .ant-input {
  //   border-radius: 2px;
  //   border: 1px solid #d9d9d9;
  //   margin-left: 0.5rem;
  // }

  &__select-options {
    .ant-select-selection.ant-select-selection--single,
    .ant-select-selection.ant-select-selection--multiple {
      background-color: transparent !important;
      border: none;
      i.anticon.anticon-down.ant-select-arrow-icon {
        color: #2b2b2b;
      }
    }
  }

  &__stages-container {
    margin-bottom: 1.2rem;
    height: 3.125rem;
    border-radius: 2px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    display: flex;

    &-scroll-left,
    &-scroll-right {
      height: 100%;
      overflow-x: auto;
      display: flex;
      justify-content: flex-start;
      white-space: nowrap;
    }
    &-scroll-left {
      max-width: 40%;
    }

    .total {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-left: 20px;
      font-size: 12px;
    }

    &--total-inactive {
      background: rgb(240, 240, 240);
      color: #666;
      @extend .total;
    }

    &--total-active {
      background: rgb(255, 216, 222);
      color: #f175a5;
      @extend .total;
    }

    &--tab-title {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button {
      width: 20%;
      width: 150px;
      height: 100%;
      cursor: pointer;
      background: none;
      border: none;
      line-height: 26px;
      position: relative;
      margin-right: 10px;
    }

    .active::before,
    .active::after {
      border-right: 1px solid #2f54eb;
      background: #f0f5ff57;
      content: '';
      display: block;
      height: 50%;
      position: absolute;
      left: 0.7rem;
      right: 0;
      top: 0;
      transform: skewX(45deg);
    }
    .active::after {
      border-bottom: 1px solid #2f54eb;
      bottom: 0;
      top: auto;
      transform: skewX(-45deg);
    }
    .active::before {
      border-top: 1px solid #2f54eb;
    }
    .active {
      background: #f0f5ff57;
      color: #2f54eb;
      border-left: 1px solid #2f54eb;
      border-radius: 0% 50% 50% 0%;
    }
  }

  .ant-table {
    outline: 1px solid #d9d9d9;

    .ant-table-content {
      position: relative;
    }

    .ant-table-tbody {
      height: 360px;
    }

    .ant-table-placeholder {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      border: none;
    }
  }
}

// trying to refactor things
// not sure if gonna work
.new-stage-selector-container {
  position: relative;
  display: flex;
  width: fit-content;
  max-width: 100%;
  overflow-x: scroll;
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;
  scroll-behavior: smooth;

  // fake borders :)
  gap: 1px;

  &::-webkit-scrollbar {
    display: none;
  }

  // individual boxes
  &__selector {
    display: flex;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    background: #ffffff;

    > p {
      width: fit-content;
      white-space: nowrap;
    }

    &__count {
      background: #fafafa;
      padding: 0 6px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }

  // box selected
  .category-selected {
    background: #f0f5ff;
    border: 1px solid #2f54eb;

    > p {
      font-weight: 600;
      color: #2f54eb;
    }

    > div {
      border: none;
      background-color: #f175a5;
      color: #ffffff;
    }
  }

  // buttons when overflow
  &__nav-btn {
    position: sticky;
    display: grid;
    place-items: center;
    padding: 0 0.25rem;
    cursor: pointer;
    background-color: #ffffff;
  }

  .nav-btn-left {
    left: 0;
    // border-right: inherit;
  }
  .nav-btn-right {
    right: 0;
    // border-left: inherit;
  }
}

.candidate-list-collabs {
  display: flex;
  gap: 0.5rem;

  &__list {
    display: flex;
  }
}

.add-collab {
  height: 32px;
  width: 32px;
  display: grid;
  place-items: center;
  border-radius: 66%;
  border: 1px dashed #bfbfbf;
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    border-color: #2f54eb;
    background: #f0f5ff;

    i {
      color: #2f54eb;
    }
  }
}

.collab-badge {
  height: 32px;
  width: 32px;
  background: #f5f5f5;
  border-radius: 66%;
  border: 1px solid #d9d9d9;
  display: grid;
  place-items: center;
  position: relative;
}

.add-collab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__collab {
      padding-bottom: 0.5rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;

      &__personal {
        display: flex;
        flex-direction: column;
      }

      &__buttons {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
      }
    }
  }
}

.candidate-listv3-table {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.table-filters-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: #00000080;
}

.table-filters {
  margin-left: auto;
  background-color: #ffffff;
  height: 100vh;
  width: 350px;
  display: flex;
  flex-direction: column;

  &__buttons {
    display: flex;
    gap: 0.5rem;
    padding: 1rem;
  }

  &__drawer-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    > div {
      padding: 1rem;
      border-bottom: 1px solid #d9d9d9;
    }
    //
    &__list-empty {
      height: 100%;
      padding-inline: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.5rem;
      text-align: center;
      font-size: 18px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      &__header {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    //align content
    .ant-calendar-picker-input {
      display: flex;
    }
  }
}

.candidate-list-actions {
  background: #d9d9d9;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  gap: 1px;

  button {
    border: none;
    border-radius: 0;
  }
}

.table-props-popover-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.table-invitation-card {
  background: #fafafa;
  width: 320px;
  height: 250px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  &__icon {
    background: #ffffff;
    width: 60px;
    height: 60px;
    border: 1px solid #d9d9d9;
    border-radius: 66%;
    display: grid;
    place-content: center;

    i {
      font-size: 2rem;
      color: #f175a5;
    }
  }
}

.add-candidate-table-modal {
  .ant-form-item-label {
    line-height: 25px;
  }

  .ant-form-item {
    margin-bottom: 0.5rem;
  }
}

.move-modal-common-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.bordered-text {
  padding: 0.25rem 0.5rem;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.docs-modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ant-card-bordered {
    border: 1px solid #d9d9d9;
  }
}

.docs-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > .ant-divider {
    width: 100%;
    margin: 0;
  }

  &__header {
    height: fit-content;
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    align-items: center;

    &__texts {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__doc {
      margin-left: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }
}

.cv-skills-div-button {
  position: relative;
  display: flex;
  align-items: center;

  &__delete {
    display: flex;
    padding: 0.125rem;

    position: absolute;
    background: #fafafa;
    top: -0.5rem;
    right: -0.125rem;
    z-index: 100;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    cursor: pointer;

    &:hover {
      color: #597ef7;
      border-color: #597ef7;
    }
  }
}

.process-is-confidential {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: #ffffff;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  padding: 0.25rem 0.75rem;
}

.table-tour-modal-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  span {
    color: #454545;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    p {
      font-weight: 700;
      font-size: 16px;
      color: #2b2b2b;
    }
  }

  &__news {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__item {
      display: flex;
      gap: 0.5rem;
      align-items: flex-start;
    }

    i {
      color: #52c41a;
      font-size: 18px;
    }
  }
}

.table-tour-div {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.column-filter-container {
  padding: 1rem;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__buttons {
    display: flex;
    gap: 0.5rem;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .ant-checkbox-wrapper {
      margin: 0;
    }
  }

  .ant-radio-inner,
  .ant-radio-inner::after {
    border-radius: 2px;
  }

  &__numbers {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.passive-candidate-form-modal {
  .ant-modal-footer {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
  }
}
