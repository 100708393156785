body,
h1,
h2,
h3,
h4, 
h5 {
  font-family: Lato !important;
}

.candidate-login {
  height: 100vh;
  display: flex;

  @media screen and (max-width: 930px) {
    justify-content: center;
  }

  input,
  button {
    height: 38px;
  }

  &__left-side {
    background-color: #fff;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;

    @media screen and (min-width: 576px) and (max-width: 800px) {
      //width: 438px;
    }  

    &__header {
      width: 438px;
      font-family: Lato;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0.8em;

      &::after,
      &::before {
        content: none;
      }

      button {
        text-align: left;
        padding: 0;
        color: #786d73;
      }

      h4 {
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: #8c8c8c;
        padding: 0;
        font-weight: normal;
      }

      h1 {
        margin: 0;
        font-size: 30px;
        line-height: 30px;
        color: #1a000e;
      }

      div.ant-typography {
        font-size: 18px;
        line-height: 26px;
        color: #786d73;
        margin: 0;
      }
    }

    &__buttons {
      height: 38px;
      width: 100%;
      margin-bottom: 1em;
    }

    &__login-form {
      width: 438px;
    }

    &__social-login {
      width: 438px;
    }

    @media screen and (max-width: 930px) {
      &__login-form,
      &__header,
      &__social-login {
        width: 90%;
      }
    }
  }

  &__right-side {
    background-color: #efefef;
    background-size: cover;
    height: 100%;

    .branded-img {
      height: 99.83%;
      width: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 930px) {
      display: none;
    }

    > div.candidate-right-def {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__logo {
      img {
        width: 269px;
      }
    }
    &__image {
      img {
        width: 100%;
      }
    }
    &__text {
      width: 467px;
      font-size: 30px;
      text-align: center;

      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 44px;
        color: #786d73;
      }
    }
  }

  div.ant-form-explain {
    margin: 0.3em 0;
  }

  .ant-select-lg .ant-select-selection__rendered {
    line-height: 24px;
  }

  .ant-form-item-label {
    line-height: 30px;
  }
}

.login-footer {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
}

.login-alert {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  &__error {
    border-radius: 10px;
    font-size: 13px !important;
    color: #ec5f2f;
    padding: 10px;
  }
}

.ant-form-item {
  margin-bottom: 0.8em;
}

.business-login-right {
  background-color: #b4015f;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1em 4em 1em 4em;

  > div {
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > img {
      margin-bottom: 4em;
    }
  }

  .business-welcome-text {
    height: 45%;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    color: #ffffff;
    text-align: center;
    width: 80%;
  }

  > img {
    width: 90%;
  }
}

.footer-login {
  position: absolute;
  bottom: 40px;
  width: 490px;
  font-size: 14px;

  button {
    color: #f175a5;
    padding: 0 0.2em;
  }

  @media screen and (max-width: 510px) {
    display: flex;  
    flex-direction: column;
    align-items: center;
    
    button {
      height: fit-content;
    }

    > span {
      margin-bottom: 0.2em;
    }
  }
}
