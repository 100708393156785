$neutral-3: #f5f5f5;
$neutral-5: #D9D9D9;
$neutral-6: #bfbfbf;

.candidate-profile-header {
  .privacy-div {
    line-height: 21px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 2px;
    border: 1px dashed #d9d9d9;
  }
}

.candidate-profile-nav {
  position: fixed;
  .nav-btn {
    width: 100%;
    &:hover,
    &:focus {
      color: #f175a5 !important;
      border-radius: 0px !important;
      border-color: #d9d9d9 !important;
      border-left: 3px solid #f175a5 !important;
    }
  }
  .nav-btn {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      border-bottom: 0px;
    }
  }

  .current,
  .current:hover {
    color: #f175a5 !important;
    border-radius: 0px !important;
    border-color: #d9d9d9 !important;
    border-left: 3px solid #f175a5 !important;
  }
}

.circle-candidate-avatar {
  border-radius: 50%;
  width: 92px;
  height: 92px;
  background: #FFF;
  border: 2px solid $neutral-5;

  display: flex;
  justify-content: center;
  align-items: center;
}

.text-candidate-avatar {
  font-size: 38px;
  color: #454545;
  font-weight: 400;
}

.candidate-avatar {
  height: 179px;
  width: 179px;
}

.candidate-form-section {
  background-color: white;
}

.candidate-form-section-inner{
  margin: 0px 10px 0 10px;
}

.label-required {
  margin: 0 4px 0 0;
  color: #f175a5;
}

.bold-option {
  font-weight: 700;
}

.add-button {
  color: #f175a5;
  cursor: pointer;
  svg {
    margin: 0 5px 0 0;
  }
}

.upload-Cv {
  cursor: pointer;
  margin: 10px 0 0 0;
  background: #fafafa;
  border: 1.5px dashed #d9d9d9;
  box-sizing: border-box;
  border-radius: 5px;
  height: 120px;
  & .underline-span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    text-decoration-line: underline;
    color: #5e5e5e;
  }
}

.save-changes {
  margin: 20px 0 30px 0;
}

.button-group {
  width: 100%;
  label {
    text-align: center;
    width: 50%;
  }
}

.careerModal, .experienceModal { 
  width: 700px !important;

  .header {
    z-index: 99;
    background: white;
    padding-bottom: 15px;
    min-height: 52px;

    h2 {
      font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
    }
  }

  .ant-modal-body {
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .footer {
    background: white;
    padding-top: 15px;
  
    .ant-form-item-control {
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: 500px) {
        justify-content: flex-start;
      }
    }
  
    button:first-child {
      &:hover, &:focus, &:active {
        color: #f175a5;
      }
    }

    .ant-row.ant-form-item {
      margin: 0;
    }
  }
}


@media (max-width: 576px) {
  .desktop-view {
    display: none;
  }
  .candidate-avatar {
    height: 79px;
    width: 79px;
  }
}
