.job-application-form {
    .breadcrumbs-button {
        cursor: pointer;
    }
}

.genoma-steps {
    width: 100%;
    @mixin centered-step {
        height: 44px;
        min-height: 44px;
        line-height: 40px;
        width: 44px;
        min-width: 44px;
        border-radius: 50%;
        text-align: center;
        font-size: 21px;
    }
    .genoma-step {
        @include centered-step;
        color: #597ef7;
        border: 2px solid #597ef7;
        cursor: pointer;
    }

    .genoma-step-active {
        @include centered-step;
        color: #ffffff;
        background-color: #597ef7;
        border: 2px solid #597ef7;
    }
    .centered-step {
        display: flex;
        justify-content: center;
        align-items: center;
        .step-link {
            align-self: center;
            width: 50%;
            height: 2px;
            border: none;
            background-color: #597ef7;
        }
        .empty-link {
            align-self: center;
            width: 50%;
            height: 2px;
            border: none;
        }
    }
    @mixin step-title-base {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        color: #5e5e5e;
        padding: 12px;
        text-align: center;
    }
    .genoma-step-title {
        @include step-title-base;
    }
    .genoma-step-title-active {
        @include step-title-base;
        font-weight: 700;
    }
}

.genoma-step-content {
    width: 100%;
}
