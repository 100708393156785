@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.jl {
  font-family: 'Lato', sans-serif;
  background: #ffffff !important;

  &__cover-page-container {
    position: relative;

    &:hover .jl__overlay {
      opacity: 1;
    }
  }

  &__img-container {
    height: 0;
    width: 100%;
    padding-top: 48%;
    position: relative;
  }

  &__background-img {
    position: absolute;
    height: 100%;
    max-height: 90vh;
    object-fit: cover;
    width: 100%;
    top: 0px;
    left: 0px;
    filter: brightness(50%);

    @media screen and (max-width: 450px) {
      height: 30vh;
    }

    @media screen and (max-width: 400px) {
      height: 35vh;
    }

    @media screen and (max-width: 300px) {
      height: 30vh;
    }
  }

  &__overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @media screen and (max-width: 450px) {
      top: 60%;
    }

    @media screen and (max-width: 400px) {
      top: 88%;
    }

    @media screen and (max-width: 300px) {
      top: 95%;
    }
  }

  &__centered-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .ant-typography {
      font-family: 'Lato', sans-serif;
      font-size: 70px;
      color: #ffffff !important;
      min-width: 90%;
      line-height: 1em;

      @media screen and (max-width: 800px) {
        font-size: 30px !important;
      }

      @media screen and (max-width: 450px) {
        margin-bottom: 0;
      }
    }

    &__btns {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .ant-btn {
        margin: 0px 10px 0px 10px;
        width: 180px;

        @media screen and (max-width: 500px) {
          font-size: 13px;
          margin-bottom: 5px;
          height: 30px;
        }
      }

      .ant-btn:hover {
        opacity: 0.8;
      }
    }

    .total-jobs {
      margin-bottom: 3em;
      text-transform: uppercase;

      span {
        color: white;
        font-size: 1.3em;
        text-transform: initial;

        @media screen and (min-width: 700px) {
          font-size: 1.8em;
        }
      }
    }
  }

  &__welcome {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 18px;
    max-width: 800px;
    margin: auto;
    padding-top: 1em;
    padding-bottom: 3em;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;

    .ant-btn {
      margin-top: 30px;
    }

    p {
      color: rgb(43, 43, 43);
    }

    p,
    p span,
    p strong {
      font-family: 'Lato', sans-serif !important;
    }
  }

  &-description {
    @media screen and (max-width: 450px) {
      padding-top: 4em !important;
    }

    @media screen and (max-width: 350px) {
      padding-top: 5em !important;
    }

    &__textEditor {
      text-align: center;
      margin: 0em 1em 3em 1em;
      line-height: 1.5;
    }
  }

  &-video {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 4em;

    &__frame {
      iframe {
        @media screen and (max-width: 750px) {
          width: 560px !important;
          height: 315px !important;
        }

        @media screen and (max-width: 459px) {
          width: 300px !important;
          height: 169px !important;
        }
      }
    }
  }

  &__rrss {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px;
    padding-top: 2em;

    @media screen and (max-width: 500px) {
      font-size: 12px !important;
      padding-top: 0px;
    }

    .ant-typography {
      margin-bottom: 10px;
      margin-right: 20px;
      font-size: 17px;

      @media screen and (max-width: 500px) {
        font-size: 13px !important;
      }
    }

    &-box {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        background: #8a9ca57a;
        border-radius: 50px;
        margin: 5px;
        text-decoration: none;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background: #627884;
        }

        img {
          width: 15px;
          height: 15px;
          color: white;
        }
      }
    }
  }

  &__jobs {
    border-top: 1px solid rgb(236, 236, 236);
    max-width: 900px;
    margin: auto;
    padding-top: 3em;
    padding-bottom: 7em;
    padding-left: 1em;
    padding-right: 1em;

    &__filters {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 3em;

      @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      span.ant-typography {
        color: rgb(117, 117, 117) !important;
        margin-right: 20px;

        @media screen and (max-width: 500px) {
          margin: 5px 0 5px 0;
        }
      }

      .ant-select {
        margin-left: 20px;

        @media screen and (max-width: 500px) {
          margin: 5px 0 5px 0;
        }
      }

      .ant-select:hover {
        border-color: #ffdaef !important;
      }
    }

    &__list {
      margin-bottom: 4em !important;

      .ant-list-header {
        h2.ant-typography {
          font-family: 'Lato', sans-serif;
          color: #454545;
        }
      }

      .ant-row-flex {
        width: 100%;

        @media screen and (max-width: 500px) {
          width: 90%;
        }

        .ant-col {
          &:nth-child(2),
          &:nth-child(3) {
            text-align: end;
          }
        }
      }

      &-item {
        padding: 30px 0 !important;

        .ant-typography {
          font-size: 17px;

          @media screen and (max-width: 500px) {
            font-size: 15px;
          }
        }

        .ant-btn:hover {
          opacity: 0.7;
        }

        .ant-btn-primary {
          background: #f770a4;
          border: none;
          color: white;
          line-height: 35px !important;

          &:hover,
          &:active,
          &:focus {
            background: #f770a4;
            border: none;
            color: #ffffff;
            opacity: 0.7;
          }
        }

        .location {
          display: block;

          @media screen and (max-width: 766px) {
            display: none;
          }
        }
      }
    }
  }

  &__personalized {
    &:hover {
      opacity: 0.7;
    }
  }

  footer {
    display: flex;
    justify-content: center;

    a {
      color: #666;
      text-decoration: none;

      &:hover {
        color: #f1309b;
        text-decoration: underline;
      }
    }
  }

  .ant-tabs-tab {
    font-size: 17px;
  }

  .ant-tabs-bar.ant-tabs-top-bar {
    margin-bottom: 3em;
  }

  .ant-list-split .ant-list-header {
    border-bottom: none;
  }

  .ant-list-split .ant-list-header h2 {
    @media screen and (max-width: 700px) {
      font-size: 24px !important;
    }
  }

  .ant-select-selection:hover {
    border-color: #ff18ba;
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: #ff18ba;
    -webkit-box-shadow: 0 0 0 2px #ff18ba46;
    box-shadow: 0 0 0 2px #ff18ba3f;
  }
}

.jl-flag {
  height: 21px;

  img {
    width: 20px;
    margin-right: 4px;
  }
}

.location-with-flag {
  display: flex;
  align-items: center;
}

// .ant-tabs-nav .ant-tabs-tab-active,
// .ant-tabs-tab:hover {
//   color: #292929 !important;
// }

// .ant-tabs-ink-bar {
//   background-color: #7583f7 !important;
// }

.description-company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 870px;
  margin: auto;
  padding: 2em 0;

  @media screen and (max-width: 1390px) {
    padding-top: 3em;
  }

  @media screen and (max-width: 899px) {
    padding: 2em 1em;
  }

  @media screen and (max-width: 899px) {
    padding: 2em 1em;
  }

  @media screen and (max-width: 500px) {
    padding: 40px 5px 5px 10px;
  }

  &__welcome {
    display: flex;
    justify-content: start;
    line-height: 28px;

    @media screen and (max-width: 500px) {
      padding: 5px;
      font-size: 13px;
    }
  }

  &__about {
    margin-bottom: 3em;

    h2.ant-typography {
      color: #454545;
      font-family: 'Lato', sans-serif;

      @media screen and (max-width: 500px) {
        font-size: 15px;
      }
    }
  }
}

.rps {
  display: flex;
  padding-bottom: 30px;
  width: 100%;

  &__job-title {
    @media screen and (max-width: 400px) {
      margin-top: 70px;
    }
  }

  &__job-btns {
    margin: 30px 0 10px 0;

    .ant-btn {
      margin-right: 10px;
    }

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;

      .ant-btn {
        margin-bottom: 10px;
      }
    }
  }

  &__job-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 30px 0 0 0;

    img {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    &-text {
      // border-top: 1px solid #dadada;
      .instructions-text {
        background-color: rgb(255, 130, 160);
        line-height: 20px;
        padding: 30px;
        border-radius: 30px;

        .ant-typography {
          color: white !important;
        }
      }
    }
  }

  &__job-descriptions {
    margin: 30px 0 10px 0;

    h4.ant-typography {
      color: #454545;
    }

    .ant-input {
      margin-bottom: 10px;
    }

    .ant-btn {
      margin-left: 5px;
    }
  }

  &__job-add {
    margin-left: 5px;

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      border-color: #f770a4;
      color: #f770a4;
    }
  }

  &__rrss {
    h4.ant-typography {
      color: #454545;
    }

    .ant-form-item-label {
      color: #454545;
    }
  }

  &__update-btn {
    margin: 20px 0 30px 0;
  }
}

.branding-tabs {
  background-color: #fff;
  border: 1px solid #d9d9d9;

  // deleting tab default margin because design is not consistent
  // and code gets weird if i use padding: 0 1rem 1rem 1rem in content
  .ant-tabs-bar {
    margin: 0;
  }
}
