.schedule-modal {
  .ant-form-item-label {
    label {
      font-weight: 400 !important;

      &::after {
        content: none;
      }
    }
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > div {
      border: 1px solid #d9d9d9;
      padding: 1rem;

      .ant-select {
        width: 100%;
      }
    }
  }

  .ant-select-disabled {
    > .ant-select-selection {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .single-or-multi-radio.ant-form-item {
    border: none;
    padding: 0;
    margin: 0 0 0 auto;
  }
}

.schedule-attendees-form {
  &__attendee {
    padding: 1rem;

    gap: 1rem;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid #d9d9d9;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;
    }

    &__avatar {
      height: 44px;
      width: 44px;
      border-radius: 66%;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
    }

    &__hours {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      span,
      i {
        color: #a4a4a4;
      }
    }

    &__texts {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    &__type {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 0.5rem;
      gap: 0.5rem;
      align-items: center;
      display: flex;
      min-width: 120px;

      i {
        font-size: 20px;
      }
    }
  }
}

// .custom-calendar-container {
//   display: flex;
//   flex-direction: column;
//   gap: 0.5rem;

//   &__selectors {
//     display: flex;
//     gap: 0.5rem;

//     &__week {
//       display: flex;
//       width: 100%;
//       align-items: center;
//       justify-content: space-evenly;
//       border: 1px solid #d9d9d9;
//       border-radius: 2px;

//       button {
//         color: #2b2b2b;
//       }
//     }
//   }
// }

// .calendar-with-table {
//   border: 1px solid #f0f0f0;

//   thead {
//     th {
//       padding: 0.5rem 1rem;
//       text-align: center;
//       cursor: pointer;

//       &:not(:last-child) {
//         border-right: 1px solid #f0f0f0;
//       }
//     }
//   }

//   tbody {
//     tr {
//       height: 60px;
//       border-top: 1px solid #f0f0f0;
//     }

//     td {
//       &:not(:last-child) {
//         border-right: 1px solid #f0f0f0;
//       }
//     }
//   }

//   &__hour {
//     padding-top: 6px;
//     text-align: center;
//     width: 80px;
//   }
// }

.attendees-content {
  h4.ant-typography {
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.meetings-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__meeting {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &__candidates {
      display: flex;
      gap: 0.75rem;
    }
  }
}

.meeting-interval {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .ant-divider {
    margin: 0;
    flex-grow: 1;
    min-width: 20px;
  }

  > div {
    white-space: nowrap;
  }
}

.schedule-details-form {
  h4.ant-typography {
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 1rem;
}
