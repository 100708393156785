.vi {
  .genomin-message {
    position: absolute;
    right: 4em;
    top: 2.75em;
    z-index: 9;

    @media screen and (max-width: 690px) {
      left: 1.7em;
      top: 0;
      right: auto;
    }

    img {
      cursor: pointer;
      transition: .2s all;
  
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
}

.vi-modal-preview {
  width: 800px !important;
  margin-top: 2em;
  margin-bottom: 1em;

  .ant-modal-content {
    padding: 20px 2.5em;
    background-color: #fafafa;

    h4 {
      margin-top: 0.3em;
      margin-bottom: 1.5em;
    }
  
    span.ant-typography {
      color: #585858;
      font-size: 15px;
    }

    .wrap-list {
      margin-top: 1em;

      .pd__list {
        padding-left: 1.5em;
        margin-top: 7px;
    
        &__item {
          position: relative;
          margin-bottom: 3px;
          color: #585858;
          font-size: 15px;
    
          &:after {
            content: " ";
            position: absolute;
            top: 7px;
            left: -20px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #f875b2;
          }
        }
      }
    }
  
    .img-preview {
      img {
        width: 90%;
        margin: 3em auto 0;
        display: flex;
        min-height: 400px;
      }
    }
  }

  .ant-modal-footer {
    border: none;
  }
}