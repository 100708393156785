.message {
    margin: 10px 0px 20px 0px;
    padding: 10px;
    border: 2px solid #d9d9d9;
    word-wrap: break-word;
    background: #FAFAFA;

}

.message-content {
    white-space: pre-wrap;
    &__subject{
        white-space: pre-wrap;
        font-size: 14px;
    }
}

.message.start {
    border-radius: 0px 14px 14px 14px;
    margin-left: 5px
}

.message.end {
    border-radius: 14px 0px 14px 14px;
    margin-right: 5px
}

.msg-signature {
    margin-top: 10px;
    font-size: 12px;
}

.msg-logo {
    max-width: 40px;
    margin: 10px;
}

.msg-footer {
    span {
        font-size: 14px;
    }
    border-radius: 2px !important;
    margin: 20px 0px 0px 0px;
    width: 100%;
}

.msg-candidate-avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #fff0f6;
    border: 2px solid #f175a5;
    font-size: 18px;
    color: #f175a5;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
