@import '../../../NewProcessForm.scss';

.npjd {
  height: 100%;
  padding: 3em 3em 5em !important;
  @extend %opacity-animation;

  @media screen and (max-width: 400px) {
    padding: 3em 2em 5em !important;
  }

  @media screen and (min-width: 768px) {
    padding: 4em 2em 10em !important;
  }

  @media screen and (min-width: 1000px) {
    padding: 2em 4em 10em 6em !important;
  }

  .ant-form-extra::first-letter {
    text-transform: capitalize;
  }
  
  .active {
    border: 2px solid rgb(204, 204, 204) !important;
  }

  iframe {
    margin-bottom: 1.5em;
  }

  .btns-container {
    @media screen and (max-width: 400px) {
      margin-bottom: 0 !important;
    }
    .website-btn {
      @media screen and (max-width: 400px) {
        width: 50px !important;
      }
      img { 
        @media screen and (max-width: 400px) {
          width: 20px !important;
        }
      }
      &:nth-child(1) {
        img { 
          content: url('https://genoma-assets.s3.us-east-2.amazonaws.com/youtube.png');
  
          @media screen and (max-width: 400px) {
            content: url('https://i.imgur.com/7xVyyoO.png');
          }
        }
      }
      &:nth-child(2) {
        img { 
          content: url('https://genoma-assets.s3.us-east-2.amazonaws.com/vimeo.png');
  
          @media screen and (max-width: 400px) {
            content: url('https://i.imgur.com/HcbR6Y3.png');
          }
        }
      }
      &:nth-child(3) {
        img { 
          content: url('https://dashboard.myinterview.com/static/img/sites/dropbox.png');
  
          @media screen and (max-width: 400px) {
            content: url('https://i.imgur.com/nZJ3jhZ.png');
          }
        }
      }
    }
  }
}

a {
  text-decoration: none !important;
}