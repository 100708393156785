@import '../../../NewProcessForm.scss';

$cognit-b: #ffa48844;
$reasoning-b: #9d14e73d;
$personality-b: #ff829f49;

//indeal genome v1
.ig-content {
  @extend %opacity-animation;

  &__steps {
    padding: 3em 3em 5em !important;
    max-height: 100%;

    @media screen and (min-width: 768px) {
      padding: 0 4em 6em 6em !important;
    }

    footer {
      border: none !important;
      margin: 0 !important;
      padding: 0 !important;
      background: white !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: unset !important;
  
      button {
        border: none;
        color: #303030;
        font-size: 16px;
        padding: 0;

        i {
          color: $mainColor;
        }

        &:hover {
          color: #575757;
        }
        &:focus, &:active {
          color: #303030;
        }
        &:disabled {
          background: transparent;
          i {
            opacity: 0.6;
          }
        }
      }
    }
  }

  input {
    height: 35px !important;
  }
  .ant-affix {
    z-index: 999;
    background: #fff;
    border-radius: 10px;
    padding: 0 15px 0;
    border: 1px solid $main-border-color;
    opacity: 0;
    box-shadow: $main-shadow;
    animation: animateNav .3s forwards;

    @keyframes animateNav {
      from { opacity: 0; }
      to { opacity: 1; }
    }
  }

  .cp-view { // preview resumen del candidato
    pointer-events: none;
  }
}

.ig {
  padding-top: 2em;

  .ant-tabs-bar.ant-tabs-top-bar {
    background: #fff;
    padding: 15px 30px 0;
    border-radius: 20px;
    border-bottom: none !important;
  }
 

  .ant-tabs-tab-active.ant-tabs-tab {
    margin-bottom: 8px;
  }

  .ant-collapse-item.ant-collapse-item-active {
    border: none;
    border-radius: 10px;
    box-shadow: 0 6px 10px 0 rgba(128,98,96,.16) !important;
  }

  .ant-collapse {
    margin-top: 2em;
    border: none;
    background: #fff;

    .ant-collapse-item {
      margin-bottom: 2em;
      border-bottom: none;
      border-bottom-right-radius: 10px !important;
      border-bottom-left-radius: 10px !important;

      &:nth-child(1) {
        .ant-collapse-header {
          background: #4e4cac;
        }
      }

      &:nth-child(2) {
        .ant-collapse-header {
          background: #9698d5;
        }
      }

      &:nth-child(3) {
        .ant-collapse-header {
          background: #f07f8b;
        }
      }

      &:nth-child(4) {
        .ant-collapse-header {
          background: #ca646ead;
        }
      }

      .ant-collapse-header {
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        &:hover {
          opacity: 0.9;
        }

        i, span.ant-typography {
          color:#fff;
        }

        .ant-collapse-arrow {
          top: 27px;
        }
      }

      .panel-content {
        padding: 20px;
      }

      .ant-collapse-content-box {
        padding: 0;
      }
  
      h4 {
        margin: 0;
        color: #fff !important;
      }

      .close-panel {
        height: auto;
        border-radius: 0;
        padding: 10px 5px;
        border-top: 1px solid rgb(236, 236, 236) !important;
        border: none;

        &:hover, &:focus, &:active {
          color: #666;
          border-color: #666;
          filter: brightness(0.9);
        }
      }
    }
  }
}

// ideal genome v2
.ig {
  .personality-form {
    .bag {
      text-align: center;
      display: grid;
      width: 80px;
      margin-top: 20px;

      img {
        width: 80px;
        margin: auto;
        border-radius: 100px;
        margin-bottom: 5px;
      }

      span {
        font-size: 15px;
      }
    }

    h4 {
      margin: 1em 0 2em;
    }

    .trait-row {
      margin-bottom: 3.5em;

      .lower-label, .upper-label {
        margin-top: 7px;
        
        span {
          color: rgb(63, 63, 63);
        }
      }

      .upper-label {
        text-align: right;
      }
    }
  }

  .cognitive-form {
    .ant-tabs-bar.ant-tabs-top-bar {
      display: none;
    }

    .ant-radio-group.ant-radio-group-outline {
      display: flex;
    }

    .package-options {
      margin-top: 1.5em;
      margin-bottom: 1.5em;

      h4 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      label {
        width: 95% !important;
        margin-right: 20px;
        text-align: center;
        min-height: 100px;
        display: grid;
        min-width: 200px;
        line-height: 1.3;
        border: 1px solid rgb(245, 245, 245) !important;

        img {
          //display: none;
          margin-bottom: 10px;
        }

        .ant-tag {
          border: none;
          margin-top: 5px;
          cursor: pointer;
          margin-bottom: 5px;
          background: #f0f0f0;
        }

        span:nth-child(5) {
          color: rgb(202, 202, 202);
        }

        &:hover {
          box-shadow: 0 9px 20px #e6e6e6;
        }
      }

      label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        position: relative;
        background: white !important;
        box-shadow: -1px 0 0 0 $mainColor;
        color: $mainColor !important;
        outline: none !important;
        box-shadow: 0 7px 15px 0 rgba(0,0,0,.05) !important;
        border: 1px solid rgb(223, 223, 223) !important;

        .check-icon {
          width: 17px;
          display: block;
          position: absolute;
          top: 7px;
          right: 7px;
        }
      }

      button {
        margin: 2em 0;
      }
    }

    .package-headline {
      margin: 0 0 2.5em;

      button {
        background: white !important;
        box-shadow: 0 0 0 0 !important;
        margin: 1em 0;
      }
    }

    .package-wrapper {
      .game-row {
        display: flex;
        margin-bottom: 30px;

        &-bag {
          min-height: 110px;
          text-align: center;

          img {
            width: 80px;
            border-radius: 100px;
          }
        }

        &-slider {
          width: 100%;
          margin-left: 4em;
          margin-top: 10px;

          .ant-slider-track {
            background: transparent;
          }

          .ant-slider-rail, .ant-slider:hover .ant-slider-rail {
            background-image: linear-gradient(90deg, #fabdbd 33.33%, #fcfcb8 33.33%, #fcfcb8 66.66%, #93d6b5 66.66%);
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        &-percent {
          margin-top: 18px;
          margin-left: 20px;

          .ant-avatar {
            font-weight: 600;
            color: #fff;
            box-shadow: 0 2px 8px #f0f1f2;
          }
        }
      }
    }
  }

  label.ant-checkbox-wrapper {
    width: 100%;
    padding: 15px;
    text-align: center;
    font-size: 13px;
    font-weight: 400 !important;
    border: 1px solid rgb(201, 201, 201);

    &:hover {
      border-color: rgb(22, 206, 114);
    }

    input, span.ant-checkbox-inner, span.ant-checkbox.ant-checkbox-checked {
      display: none;
    }
  }

  .checkedValue {
    label.ant-checkbox-wrapper {
      background: rgb(22, 206, 114);
      color: white;
    }
  }

  .uncheckedValue {
    label.ant-checkbox-wrapper {
      background: rgb(255, 255, 255);
      color: rgb(189, 189, 189);
    }
  }

  .back-btn {
    position: absolute;
    top: 1em;
    left: -50px;
    z-index: 9999999999;

    i {
      font-size: 23px;
      cursor: pointer;
      color: #333333;
    }
  }

  //tabs
  .ant-tabs-nav .ant-tabs-tab:hover, 
  .ant-tabs-nav .ant-tabs-tab-active {
    color: $mainColor;
  }

  .ant-tabs-ink-bar {
    height: 3px;
    background: $mainColor;
    border-radius: 4px;
  }
}

.ant-tabs {
  overflow: unset !important;
}


.ig-summary {
  z-index: 10;
  overflow: hidden;
}

.trait-list-item { 
  .ant-input-number, .ant-input, span.ant-input-affix-wrapper {
    max-width: 90px;
    height: 35px !important; 
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .trait-name {
    margin-bottom: 7px;

    span.ant-typography {
      font-size: 15px;

      i {
        margin-left: 4px;
        border-radius: 100%;
        color: #696969;
        background: $main-bg;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  .trait-slider { 
    margin-left: 10px;
    
    &__action {
      display: flex;
      align-items: center;

      .slider {
        width: 100%;
      }
    }
    .ig-percent {
      display: grid;
      margin-left: 15px;
      margin-bottom: 1.5em;

      span.ant-typography {
        &:first-child {
          font-size: 14px;
        }
      }
      span.ant-typography.ant-typography-secondary {
        font-size: 12px;
      }
    }
  }

  .ideal-genome {
    text-align: right;
    display: grid;

    span.ant-typography.ant-typography-secondary {
      &:last-child {
        color: #a8afbb;
        font-size: 13px;
        position: relative;
        margin-right: 8px;
        margin-top: 3px;

        img {
          width: 9px;
          position: absolute;
          right: 62px;
          top: 0;
        }
      }
    }
  }

  .no-data {
    margin-bottom: 2em;
    span.ant-typography {
      font-size: 15px;
    }
  }
}
