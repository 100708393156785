.new-analytics {
  height: 100%;
  border-top: 1px solid #d9d9d9;
  scroll-behavior: smooth;

  // config grouped bar header color
  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-tbody > tr:not(:last-child) > td {
    border-bottom: 1px solid #d9d9d9;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #d9d9d9;
    padding: 0;

    span {
      width: 100%;
    }
  }

  > div {
    padding: 1.5rem;
  }

  .search-button-2 {
    position: relative;
    margin-bottom: 15px;

    > div {
      min-width: 100%;
      background-color: #ffffff;
      z-index: 5;
      max-height: 400px;
      overflow-y: scroll;
      position: absolute;
      top: 40px;
      border: 1px solid #d9d9d9;

      > div {
        padding: 0.7rem;
        border-bottom: 1px solid #d9d9d9;
        font-size: 14px;

        &:hover {
          cursor: pointer;
          background-color: #dddddd;
        }
      }
    }

    i {
      position: absolute;
      top: 9px;
      right: 6px;
      z-index: 15;
      cursor: pointer;
    }
  }

  &__sidebar {
    background: #ffffff;
    display: flex;
    flex-direction: column;

    > h3 {
      margin: 0;
      padding: 0;
    }

    > div {
      margin: 0.7rem 0;
    }

    .sidebar-filters {
      .ant-row::before,
      .ant-row::after {
        content: none;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          color: #000000a6;
          padding: 0;
        }
      }

      &__items {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1em;

        > span {
          display: flex;
          align-items: center;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          font-size: 13px;
          padding: 0.5rem;
          max-height: 22px;
          width: fit-content;
          margin: 0 0.7rem 0.7rem 0;
          cursor: pointer;

          svg {
            margin-left: 0.5em;
          }
        }
      }

      &__filter {
        border-top: 1px solid #d9d9d9;
        padding: 0.7em 0;

        > div:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }

        &:last-child {
          border-bottom: 1px solid #d9d9d9;
        }

        &__options {
          display: flex;
          flex-direction: column;
          padding: 0 1.4em;

          .filter-selected {
            font-weight: bold;
            color: #f175a5 !important;
          }

          span {
            margin-top: 0.6em;
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
    }

    .sidebar-sections {
      > div {
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
      }

      button {
        color: #000000a6;
        text-align: left;
        height: 44px;
        font-size: 16px;

        &:focus {
          background: #fff0f6;
          box-shadow: inset 5px 0px 0px #f175a5;
          color: #f175a5;
          border-radius: 0;
        }
      }
    }
  }

  &__content {
    background-color: #fafafa;
    display: flex;
    flex-direction: column;

    @media print {
      .break-page {
        position: relative;
        page-break-inside: avoid;
        height: 100%;
      }
      .card-print {
        @media print {
          margin: 0 20px 0 20px;
        }
      }
    }

    &__general,
    &__stages {
      > span:first-child {
        color: #454545;
        display: block;
        line-height: 32px;
        font-size: 17px;
        font-weight: 600;
      }

      > span:nth-child(2) {
        color: #5e5e5e;
      }
    }

    > button {
      margin: 1.2em 0;
      color: #5e5e5e;
      font-weight: bold;

      span {
        text-decoration: underline;
      }
    }

    &__header {
      > div:first-child {
        display: flex;

        > div {
          flex-direction: column;
          margin-left: 16px;

          > span:first-child {
            font-size: 18px;
            color: #2b2b2b;
          }

          > span:last-child {
            font-size: 14px;
            color: #454545;
          }
        }
      }

      > div:last-child {
        > div {
          height: 72px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 0 1rem;
          border-right: 1px solid rgba(0, 0, 0, 0.06);

          > span {
            text-align: right;
          }

          > span:first-child {
            font-size: 14px;
            color: #2b2b2b;
          }

          > span:last-child {
            font-size: 24px;
            color: #5e5e5e;
          }
        }

        @media screen and (max-width: 768px) {
          margin-top: 2rem;
        }
      }

      img {
        height: 72px;
      }
    }

    &__general {
      margin-top: 1.5rem;
    }

    &__stages {
      padding-top: 1.5rem;

      > div {
        background-color: #ffffff;
        height: 370px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        margin-top: 1rem;
      }
    }

    .general-item {
      > div {
        height: 135px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.2rem;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        font-size: 14px;

        h4 {
          margin: 0 !important;
          font-size: 30px;
          font-weight: bold;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }

        > div:last-child {
          svg {
            color: #52c41a;
            margin-right: 0.5em;
          }
        }
      }
    }
  }

  input:not[type='range'] {
    height: 32px;
    border: 1px solid #d9d9d9 !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    background-color: #ffffff !important;
  }

  .ant-calendar-picker-input {
    padding: 0;
  }

  .candidates-caracteristics {
    margin-top: 10px !important;
    height: 100%;
    background-color: inherit;
    border: none;

    &__card {
      display: flex;
      flex-direction: column;
      height: 460px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      background: #ffffff;

      header {
        height: 49px;
        border-bottom: 1px solid #d9d9d9;
        padding: 1rem;

        span {
          font-weight: bold;
        }
      }

      > div {
        flex-grow: 1;
        padding: 1rem;
        max-height: 409px;
      }
    }

    .center-caracts,
    .center-caracts > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .traits-category {
    > div {
      padding: 0;
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;
        flex-grow: 1;
        padding: 1em;
        max-height: 450px;
        overflow-y: scroll;
      }
    }

    .trait-active {
      color: #1890ff;
      border: 2px solid #1890ff !important;
    }

    header {
      border-bottom: 1px solid #d9d9d9;
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 60px;

      span {
        font-weight: bold;
      }

      > div {
        button {
          padding: 5px 16px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          font-size: 14px;
          line-height: 22px;
          color: #2b2b2b;
          cursor: pointer;

          &:nth-child(2) {
            border-left: none;
            border-right: none;
          }
        }
      }
    }
  }

  .questions-container {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: inherit;
    border: none;
    padding: 0;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 49%;
      height: 475px;
      padding: 1.5em 2em;
      margin-bottom: 20px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }

  .single-svg-trait {
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-top: 30px;

    span {
      font-size: 14px;
    }
  }

  .custom-range {
    .ant-calendar-picker-input {
      padding: 0;
      background: inherit !important;
      display: flex;
      justify-content: space-between;
      height: fit-content;
      border: none !important;
    }

    .ant-calendar-picker-icon {
      display: none;
    }

    .ant-calendar-range-picker-separator {
      position: absolute;
      top: 50%;
      transform: translateY(25%);
    }

    .ant-calendar-picker-input {
      > span {
        position: relative;
      }
    }
  }

  .empty-caract {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      height: 200px;
    }

    span {
      margin-bottom: 10px;
    }
  }
}

.bottom-refs-buttons {
  flex-wrap: wrap !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 79px;
  background-color: #ffffff;
  z-index: 100;

  button {
    padding: 0 5px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #5e5e5e;
  }
}

.content-loading {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 20;
  height: 100%;
}

.analytics-filters-and-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

  > span {
    width: 100%;
  }

  .ant-select-selection.ant-select-selection--single {
    height: 32px;
  }

  .ant-select-lg .ant-select-selection__rendered {
    line-height: 32px;
  }

  .ant-select-selection__placeholder {
    font-size: 14px;
  }
}

.rangepicker-div {
  position: relative;
}

.span-justified {
  display: block;
  text-align: justify;
}

.center-text {
  text-align: center;
}
