$mainColor: #f175a5;

.ant-btn-secondary:hover,
.ant-btn-secondary:active,
.ant-btn-secondary:focus {
  color: $mainColor !important;
  border-color: $mainColor !important;
}

.ant-input:hover,
.ant-select-selection:hover,
.ant-radio-input:active,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner {
  border-color: $mainColor;
}

.ant-radio-inner::after {
  background: $mainColor !important;
}

.availability {
  .ant-checkbox-wrapper {
    margin: 0;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $mainColor;
      border-color: $mainColor;
    }
  }
}
