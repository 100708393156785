.cp-candidate-progress {
    .progress-title {
        text-align: center;
        font-weight: 300;
        font-size: 45;
        padding-top: 10;
    }
    .container {
        padding-inline: 15;
        border-radius: 7px;
        border: 2px solid rgb(233, 233, 233);
        margin-bottom: 1.5em;
    }
    .cp-rewards {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: space-between;

      .cp-reward {
        display: flex;
        align-items: center;
        padding-right: 15px;
        border-radius: 100px;
        font-weight: 600;
  
        img {
          width: 25px;
        }
  
        span {
          font-size: 14px;
          margin-left: 4px;

          strong {
            color: rgb(56, 56, 56);
          }
        }
      }
    }


    .cp-name-and-progess {
      width: 100%;

      span.ant-typography {
        font-size: 20px;
      }

      .ant-divider {
        margin: 20px 0;
      }

      .ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-default {
    
        .ant-progress-bg {
          position: relative;
          background: repeating-linear-gradient(-45deg, #108ee9, #108ee9 20px, #108ee9d1 20px, #108ee9e3 40px);
        }
      }

      .ant-progress-status-success .ant-progress-bg {
        position: relative;
        background: repeating-linear-gradient(-45deg, #87d068, #87d068 20px, #9fd289 20px, #9fd289 40px);
      }
    }
  }
  .cp-total-reward {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .counter {
      strong {
        font-size: 25px;
      }
    }

    img {
      width: 70px;

      @media screen and (max-width: 400px) {
        width: 55px;
      }
    }
  }