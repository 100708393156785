@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
$mainColor: #ff85c0;

.start-view {
  .protected {
    min-height: calc(100vh - 65px);
    height: 100% !important;
    background-color: #ffffff;

    .main-layout-cards {
      height: 100vh;

      &__img {
        width: 100%;
        display: flex;

        justify-content: center;
        @media screen and (min-width: 768px) {
          align-items: center;
        }
        @media screen and (max-width: 769px) {
          align-items: start;
          margin-top: 50px;
        }
      }

      &__bg-bar {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 50%;
        min-height: 100%;
      }

      &__cols {
        height: 100%;
        background: white;
        display: flex;
        justify-content: center;
        position: relative;

        &__component {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: white;
          height: 100%;
          width: 100%;
          padding: 127px;
          @media all and (max-width: 1000px) {
            padding: 60px;
          }
          @media all and (max-width: 768px) {
            padding: 20px;
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
    color: $mainColor;

    &:hover {
      opacity: 0.8;
      color: $mainColor;
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  span.ant-checkbox.ant-checkbox-checked {
    &::after {
      border: 1px solid $mainColor;
    }

    span.ant-checkbox-inner {
      background-color: $mainColor !important;
      border-color: $mainColor !important;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $mainColor !important;
  }

  input,
  .ant-select-selection__placeholder,
  .ant-select-selection-selected-value {
    font-family: "Open Sans", "Arial", sans-sefif;
    font-size: 14px;
  }

  label.ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    &span:last-child {
      line-height: 20px;
    }
  }

  .password-card {
    .top {
      margin-bottom: 2em;
      margin-top: 18;
      display: flex;
      justify-content: center;
      text-align: center;

      .wrapper {
        // TODO: Sacar este padding-left, lo agregué solo porque o si no cuando solo aparecen botones
        // para iniciar sesión con rrss luego de ingresar mail y no aparece campo de contraseña,
        // el título se veía corrido
        //padding-left: 3rem;

        h3 {
          margin-bottom: 3px !important;

          @media screen and (max-width: 430px) {
            margin-top: 0.5em !important;
          }
        }

        .email {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .bottom {
      margin-bottom: 1em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 430px) {
        display: inline;
      }

      .forgot-password {
        width: 40em;

        @media screen and (max-width: 430px) {
          width: auto;
        }
      }

      button {
        @media screen and (max-width: 430px) {
          width: 100% !important;
          margin-top: 0.5em;
        }
      }
    }
  }
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #7583f7 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 24, 216, 0.2) !important;
  box-shadow: 0 0 0 2px rgba(255, 24, 205, 0.2) !important;
}

.jd {
  border-top: 1px solid #f3f3f4;
  font-family: "Roboto", sans-serif;
  background: white !important;
  height: 100%;
  p,
  span,
  strong {
    font-family: "Roboto", sans-serif !important;
  }

  p {
    color: rgb(32, 32, 32) !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px;
  }

  a {
    text-decoration: none;
  }

  .btn-large {
    @media screen and (min-width: 300px) {
      min-width: 156px !important;
    }
  }

  .space-around {
    @media screen and (max-width: 899px) {
      padding-left: 75px;
    }
  }

  .title-m {
    @media screen and (max-width: 499px) {
      font-size: calc(25vw * 100 / 375) !important;
      line-height: calc(30vw * 100 / 375) !important;
    }

    @media screen and (max-width: 959px) and (min-width: 499px) {
      font-size: calc(20px * 1.5) !important;
      line-height: calc(24px * 1.5) !important;
    }

    @media screen and (max-width: 1439px) and (min-width: 959px) {
      font-size: calc(32vw * 100 / 1440) !important;
      line-height: calc(38vw * 100 / 1440) !important;
    }

    @media screen and (min-width: 1439px) {
      font-size: 32px !important;
    }
  }

  .title-l {
    font-size: 54px !important;
    line-height: 1 !important;

    @media screen and (max-width: 499px) {
      font-size: calc(32vw * 100 / 375) !important;
      line-height: calc(34vw * 100 / 375) !important;
    }

    @media screen and (max-width: 959px) and (min-width: 499px) {
      font-size: calc(24vw * 100 / 375) !important;
      line-height: calc(29vw * 100 / 375) !important;
    }

    @media screen and (max-width: 1439px) and (min-width: 959px) {
      font-size: calc(48vw * 100 / 1440) !important;
      line-height: calc(56vw * 100 / 1440) !important;
    }

    @media screen and (min-width: 1439px) {
      font-size: 48px !important;
    }
  }

  button.ant-btn.ant-btn-primary {
    &:hover {
      opacity: 0.7 !important;
      filter: none;
    }
  }

  button.ant-btn.ant-btn-icon-only {
    color: $mainColor !important;
    &:hover {
      opacity: 0.7 !important;
      filter: none;
    }
  }

  footer {
    padding: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;

    button {
      height: auto;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      border-radius: 0 !important;
      background: #e44991 !important;
    }
  }

  button {
    display: inline-block;
    padding: 10px 16px;
    cursor: pointer;
    text-decoration: none;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    outline: none;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    border-radius: 8px !important;

    &:hover {
      opacity: 1 !important;
      filter: brightness(0.9);
    }
  }

  &__cover {
    // margin: 0 0 15px;
    // padding-top: 40px;
    // position: relative;
    height: 100%;
    @media screen and (min-width: 899px) {
      // padding-left: 75px;
      // margin: 0 0 50px;
    }

    .wrapper {
      height: 100%;
      @media screen and (min-width: 899px) {
        // display: -ms-flexbox;
        display: flex;
        // -ms-flex-pack: justify;
        justify-content: space-between;
        // -ms-flex-align: start;
        align-items: flex-start;
      }

      .content {
        margin-top: 0;
        // margin-right: 60px;
        padding-right: 0;
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 400px) {
          min-width: 300px;
        }

        @media screen and (max-width: 899px) {
          padding-left: 2em;
          display: flex;
          flex-direction: column;
        }
      }

      .bg-image {
        width: 100%;
        height: 100%;

        @media screen and (max-width: 899px) {
          margin: 1em 0;
        }

        @media screen and (min-width: 899px) {
          display: flex;
          -ms-flex-order: 2;
          order: 2;
          -ms-flex-positive: 1;
          flex-grow: 1;
          display: -ms-flexbox;
          // max-width: 722px;
          // padding-right: 40px;
        }

        .main-image {
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          top: 40px;
          // z-index: 1;

          @media screen and (max-width: 899px) {
            height: 16em;
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          @media screen and (min-width: 899px) {
            min-width: 346px;
            max-width: 578px;
            min-height: 250px;
            max-height: 335px;
          }
        }

        .bg {
          // position: relative;
          width: 100%;
          height: 100%;
          background: #f3f3f4;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          // z-index: 2;
          // top: 50px;
          // left: -100px;

          @media screen and (max-width: 899px) {
            top: 0;
            left: 0;
          }

          @media screen and (min-width: 899px) {
            min-width: 346px;
            max-width: 578px;
          }
        }
      }
    }

    &__text {
      max-width: 500px;
      margin-top: 1em;
      margin-bottom: 1em;

      h1,
      h2 {
        margin: 0 !important;
      }
    }

    &__logo {
      width: 94px;
      height: auto;

      img {
        max-width: 100%;
        max-height: 100%;
        background-color: #fff;
      }
    }

    &__actions {
      button {
        &:first-child {
          background: #f3f3f4;
          color: #0d0c22;
          margin-right: 15px;
        }
      }
    }

    &__location {
      margin-top: 10px;
      margin-bottom: 50px;
    }
  }

  &__rrss {
    padding-top: 30px;
    padding-left: 2em;
    padding-right: 2em;

    @media screen and (min-width: 899px) {
      padding-left: 75px;
      padding-right: 75px;
    }

    @media screen and (max-width: 899px) {
      padding: 4em;
    }

    &__links {
      display: flex;
      flex-wrap: wrap;

      a {
        color: #666;
        font-weight: 600;
        margin-right: 25px;
        display: flex;
        margin-bottom: 10px;

        span {
          @media screen and (max-width: 899px) {
            display: none;
          }
        }

        &:hover {
          color: $mainColor;
        }

        i {
          margin-right: 6px;

          @media screen and (max-width: 899px) {
            font-size: 20px;
          }
        }
      }
    }
  }

  .start-btn {
    padding-left: 2em;
    padding-right: 2em;

    @media screen and (min-width: 899px) {
      padding-left: 75px;
    }
  }

  &__steps {
    padding: 2em;
    padding-bottom: 4em !important;
    background: $mainColor;
    margin-top: 3em !important;

    @media screen and (min-width: 899px) {
      padding: 30px;
    }

    &__text {
      margin-top: 2em;
      margin-bottom: 2em;
      text-align: center;

      h2,
      span {
        text-align: center;
      }

      h2 {
        margin-top: 0 !important;
      }

      span.ant-typography {
        font-size: 16px !important;
        line-height: 1.3 !important;
      }
    }

    &__levels {
      width: 100%;
      max-width: 70vw;
      margin: auto;

      .level {
        position: relative;
        z-index: 1;
        display: block;
        max-width: 200px;
        margin: auto;
        text-align: center;

        .block {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 100px;
          height: 100px;
          margin: 46px auto 40px;
          background: #fff;
          border-radius: 100%;

          @media screen and (min-width: 899px) {
            width: 120px;
            height: 120px;
          }
        }

        h3 {
          color: #fff;
          font-weight: 400;
          font-size: 16px !important;
          line-height: 1.3 !important;

          @media screen and (min-width: 899px) {
            font-size: 20px !important;
            line-height: 26px !important;
          }
        }
      }
    }
  }

  &__more-jobs {
    min-height: 23rem;
    background: #f3f3f4;
    padding: 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      position: relative;
      width: 100%;
      max-width: 1200px;
      margin: auto;
    }

    @media screen and (min-width: 899px) {
      padding: 85px;
    }

    h2 {
      margin-bottom: 1em !important;
    }
  }

  .content-space {
    padding: 1em 2em 1em;

    @media screen and (min-width: 899px) {
      padding: 1em 75px 1em;
    }
  }

  &__job-description {
    @extend .content-space;
  }
}

.cookiesConsent {
  height: "25vh";
  .ant-drawer-mask {
    background-color: transparent;
  }
  .ant-drawer-wrapper-body {
    background-color: #fafafa;
  }
  .cookies-button {
    justify-content: center;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    width: 90%;
    margin-top: 10px;
  }

  .reject-cookies-button {
    border-color: #595959;
    &:hover {
      border-color: $mainColor;
      color: $mainColor;
    }
  }
  .accept-cookies-button {
    background-color: $mainColor;
    border: none;
    color: #fff;
    &:hover {
      background-color: $mainColor;
      color: #fff;
    }
  }
  .paragraph {
    white-space: normal;
    display: flex;
  }
}
