@import '../../../../../styles/variables.scss';

.np__wizard {
  background: rgb(255, 255, 255);
  padding: 1em;
  display: grid;
  align-items: center;
  left: 0;
  box-shadow: $main-shadow;

  @media screen and (max-width: 350px) { 
    padding: 1em 1em 0 !important;
  }

  @media screen and (max-width: 400px) {
    padding: 17px 2em 0 !important;
  }

  @media screen and (min-width: 991px) {
    min-height: 93vh;
    margin-left: 2em;
    box-shadow: 0 0 0 0;
  }
  @media screen and (max-width: 991px) {
    top: 0;
    right: 0;
    z-index: 99;
    height: auto;
    padding: 17px 3em 0;
  }

  button.ant-btn.main-btn {
    position: absolute;
    top: 10vh;
    left: 2em;
    padding: 0 7px;
    font-size: 14px !important;
    background: #fff !important;

    @media screen and (max-width: 765px) {
      display: none;
    }
  }

  .ant-steps-item-container {
    max-width: 150px;
    @media screen and (max-width: 991px) { 
      text-align: center !important;
      display: grid;
      margin: auto;
    }
  }

  .ant-steps-vertical .ant-steps-item-content {
    min-height: auto;
  }
  .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }

  .step-completed {
    .ant-steps-item-icon {
      background: $main-bg !important;
      padding-bottom: 2px;

      img {
        width: 12px;
      }
    }
  }

  .ant-steps-item.ant-steps-item-process.ant-steps-item-active {
    .ant-steps-item-title, .ant-steps-item-icon {
      cursor: pointer;
    }
    
    .ant-steps-item-icon {
      background: $primary-color !important;

      i, span {
        color: #fff !important;
        cursor: pointer;
      }
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .ant-steps-item {
    margin-bottom: 10px;
    padding: 5px 10px;
    min-height: 45px;
    font-weight: 500 !important;

    .ant-steps-item-icon {
      width: 30px !important;
      height: 30px;
      margin: auto;
      border-radius: 100%;
      padding-top: 4px;
      background: $main-bg;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      @media screen and (max-width: 350px) { 
        width: 23px !important;
        height: 23px;
      }

      @media screen and (max-width: 991px) { 
        margin: auto;
      }

      img {
        @media screen and (max-width: 350px) { 
          width: 11px;
        }
      }

      i {
        color: $primary-color;
      }

      i, span {
        font-size: 13px;
        cursor: pointer;
        color: #4d4d4d;

        @media screen and (max-width: 350px) { 
          font-size: 12px;
        }
      }
    }

    .ant-steps-item-title {
      line-height: 1.2 !important;
      font-size: 13px;
      margin-top: 5px;
      padding-right: 0;

      @media screen and (max-width: 645px) { 
        display: none;
      }

      span {
        color: #636363;
      }
    }
  }
  
  .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: transparent;
    border: none;
  }

  button {
    margin: 0 0 3em;
    font-size: 13px !important;
    background: white !important;
    box-shadow: 0 0 0 0 !important;
    color: rgb(59, 59, 59) !important;

    @media screen and (max-width: 765px) {
      margin: 0;
    }

    &:hover {
      background: #F3F7F6 !important;
    }
  }
}