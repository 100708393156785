@import '../../../NewProcessForm.scss';

.vi {
  position: relative;
  padding: 2em 4em 2em 6em !important;
  @extend %opacity-animation;

  @media screen and (max-width: 600px) {
    padding: 2em !important;
  }

  .ql-editor {
    height: 270px;
  }

  .ant-tabs-bar {
    margin-bottom: 2em;
  }

  .ant-tabs {
    animation: none !important;
  }

  button.ant-btn.add-btn.ant-btn-link {
    margin: 0;
  }

  .dnd-wrap {
    align-items: flex-start !important;

    img {
      margin-top: 5px;
    }
  }

  .dnd-row {
    margin-left: -34px;
  }

  .added-questions {

    .ant-col.ant-form-item-label {
      width: 200px;
    }
  }

  .add-btn {
    margin-left: 0;
  }

  footer {
    left: 0;
    right: 0;
    margin: 0 !important;
    padding: 15px !important;

    .wrap {
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (min-width: 990px) {
        width: 70.83333333%;
        padding-left: calc(205px + 4em);
      }

      i {
        margin-right: 3px;
      }

      span.ant-typography {
        @media screen and (max-width: 990px) {
          display: none;
        }
      }
    }

    button {
      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
  }
}

.rubricas {

  .delete-btn:hover,
  .delete-btn:focus,
  .delete-btn:active {
    border-color: $mainColor;
    color: $mainColor;
  }
}

.ant-table-wrapper {
  .action-btn {
    background-color: #ecf1fa;
    border-color: #ecf1fa;
    color: #7583f7;
  }

  .action-btn:active,
  .action-btn:hover,
  .action-btn:focus {
    background-color: #7583f7;
    border-color: #7583f7;
    color: #ecf1fa;
  }

  .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
  .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
  .ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background-color: #f0f0f0 !important;
  }


}

.animate-qn-card {
  animation: linear 1s attention;

  @keyframes attention {
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
  }
}

.main-btn {
  background: #f3f3f4;
  color: #0d0c22;
  display: inline-block;
  padding: 10px 16px;
  border: none;
  text-decoration: none;

  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  outline: none;
  font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  border-radius: 8px !important;

  &:hover {
    opacity: 1 !important;
    filter: brightness(0.9);
  }
}