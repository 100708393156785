.cp-game-over {
    margin: auto;
    border: 2px solid #e5e5e5;
    border-radius: 16px;
    padding: 15px;
    margin-bottom: 1.5em;

    @media screen and (min-width: 400px) {
      padding: 24px;
    }

    @media screen and (min-width: 765px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .text {
      min-width: 70%;

      @media screen and (min-width: 400px) {
        display: flex;
        align-items: center;
        max-width: 80%;
        padding-right: 4em;
      }

      span {
        color: rgb(129, 129, 129);
        line-height: 1.4;
        font-size: 15px;

        @media screen and (max-width: 400px) {
          font-size: 14px;
        }
      }
    }

    h4 {
      @media screen and (max-width: 400px) {
        font-size: 17px;
        line-height: 1.2;
      }
    }

    span, h4 {
      @media screen and (max-width: 400px) {
        text-align: center;
      }
    }

    img {
      width: 70px;
      margin-right: 1.5em;

      @media screen and (max-width: 400px) {
        margin: auto;
        display: flex;
      }
    }
  }

  .instructions-card-cp {
    margin-top: 20;
    padding: "18px 20px 28px 20px";
    border-radius: '8px';
    background: 'linear-gradient(0deg, rgba(243,243,244,0.8), rgba(243,243,244,0.5)),#fff';
}


.cp-item-list {
    line-height: 1.2;
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    color: rgb(124, 124, 124);

    &::after {
      content: " ";
      position: absolute;
      top: 5px;
      left: -20px;
      width: 6px;
      height: 6px;
      background-color: rgb(117, 117, 117);
      border-radius: 50%;
    }
  }

  .cp-instruction-title {
    margin-bottom: 18px;

    strong {
      font-size: 20px;
    }
  }
  .cp-app-summary {
    padding-left: 15;
    padding-right: 15;
    border-radius: 7px;
    border: 2px solid rgb(233, 233, 233);
    margin-bottom: 1.5em;

    span.ant-typography {
      font-size: 20px;
    }
    
    .levels { 
      display: flex;
      align-items: center;

      .level-one, .level-two {
        margin: auto;
        text-align: center;

        .wrap {
          width: 100%;
          position: relative;

          img {
            width: 40%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            margin: auto;
          }

          .ant-progress {
            width: 100%;
  
            .ant-progress-inner {
              width: 100% !important;
              height: auto !important;
            }
          }
        }

        span.ant-typography {
          font-size: 16px !important;
        }
      }
    }
  }
  

