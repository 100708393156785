@import '../../../NewProcessForm.scss';

.pj {
  padding: 2em 4em 2em 6em !important;
  @extend %opacity-animation;

  @media screen and (max-width: 765px) {
    padding: 2em !important;
  }

  h4 {
    font-size: 17px;
  }

  button.ant-btn.ant-btn-primary.ant-btn-lg.ant-btn-block {
    font-size: 17px;
    font-weight: 500 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    height: auto;
    margin-top: 1.5em;
  }

  .ant-divider {
    margin-top: 4em;
  }

  .cp-view { // recorrido del candidato
    pointer-events: none;
    box-shadow: 0 0 0 0 !important;
  }

  .website-btn {
    cursor: pointer;
    transition: .7s all;
    display: flex !important;
    align-items: center;
    height: auto !important;
    padding: 15px;
    filter: none !important;
    justify-content: space-between;

    &:hover {
      background: $main-bg !important;
    }

    &:active {
      transition: .1s all;
      transform: translateY(0px);
    }

    .share-icon {
      width: 15px !important;
    }

    img {
      position: unset !important;
      transform: none !important;
    }
  }

  &__job-url {
    // descripción
    div.ant-typography {
      line-height: 1.3;
      font-size: 14px !important;
      max-width: 80%;

      @media screen and (max-width: 600px) {
        max-width: 100%;
      }
    }
    .ant-col.ant-col-md-20 {
      @media screen and (max-width: 765px) {
        display: none;
      }
    }
    .ant-col.ant-col-md-4 {
      @media screen and (max-width: 765px) {
        width: 100%;
        button {
          width: 100%;
          border-radius: 6px !important;
        }
      }
    }
    // input
    .ant-row {
      button, input {
        height: 50px;
      }

      input {
        color: #666;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      button {
        font-size: 13px;
        text-shadow: none;
        border: #65738f;
        background: #65738f;
        text-transform: uppercase;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 7px !important;
        border-bottom-right-radius: 7px !important;
      }
    }
  }

  &__share-job {
    margin-top: 2em;

    .btns-container {
      display: flex;
      justify-content: space-between;
 
      @media screen and (max-width: 575px) {
        display: grid !important;
        grid-template-columns: repeat(1, 1fr);
      }
      
      .website-btn {
        width: 32% !important;
        margin: 0;
        padding: 12px;

        @media screen and (max-width: 575px) {
          width: 100% !important;
          margin-bottom: 10px;
        }
  
        .rrss {
          display: flex;
          align-items: center;

          &-img {
            width: 30px !important;
            margin-right: 7px;
            background: $main-bg;
            border-radius: 5px;
            padding: 7px;
          }
        }
      }
    }
  }

  /*-----------------------------------------
  * botón TRABAJANDO
  *------------------------------------------*/
  &__post-job {
    width: 100%;
    max-width: 235px;

    .btns-container {
      .website-btn-new {
        &::after {
          content: 'NUEVO';
          position: absolute;
          right: 7px;
          top: 7px;
          font-size: 11px;
          color: #fff;
          padding: 3px;
          border-radius: 3px;
          letter-spacing: 0.5px;
          background: $success-color;
        }
      }

      .website-btn {
        cursor: default !important;
        width: auto !important;
        position: relative;

        &:hover {
          background: none !important;
        }

        .rrss {
          width: 100%;

          &-img {
            width: 100%;

            img {
              width: 110px;
              display: flex;
              margin: 1.5em auto 2em;
            }
          }

          button {
            height: auto;
            padding: 7px;
            margin-top: 20px;
            font-weight: 600;
            background: $main-bg !important;
            color: $primary-color !important;
          }

          button:enabled {
            &:hover {
              opacity: 1 !important;
              background: $primary-color !important;
            }
          }

          button:disabled {
            &:hover {
              color: $primary-color !important;
            }
          }
        }
      }
    }
  }
}

.ant-tooltip.copy.ant-tooltip-placement-top {
  .ant-tooltip-inner {
    background: #131313;

    span {
      color: #fff;
    }
  }
}