@import '../../../../../../styles/variables.scss';

.se {
  padding: 4em 6em !important;

  .dnd-wrap {

    .ant-btn:active,
    .ant-btn:hover,
    .ant-btn:focus {
      color: $mainColor;
      border-color: $mainColor;
    }
  }

  .group {
    margin: 3em 0 0 0;

    .ant-typography {
      margin-left: 5px;
    }

    &__add {
      display: flex;
      margin-top: 10px;
    }

    &__add-stage-input {
      margin-right: 10px;

      .ant-input {
        width: 300px;
      }
    }

    &__add-stage-button {
      display: flex;
      justify-content: flex-end;
    }


  }
}