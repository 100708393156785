@import '../../../styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

section.np.ant-layout.ant-layout-has-sider.collapse-sider {
  display: grid;

  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-below {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background: $mainColor;
  }

  @media (max-width: 480px) {
    .ant-steps-horizontal.ant-steps-label-horizontal {
      display: flex;
    }

    .ant-steps-item.ant-steps-item-wait.ant-steps-item-custom {
      margin-bottom: 0;
    }

    .ant-steps.ant-steps-horizontal.ant-steps-small.ant-steps-label-horizontal {
      height: 46px;
    }
  }
}

%opacity-animation {
  animation: animateTabs .7s ease;

  @keyframes animateTabs {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

%scroll-bar {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgb(255, 255, 255);

    &:hover {
      width: 12px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.473);
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: $mainColor;
}

::selection {
  color: #fff;
  background: $mainColor;
}

.np {
  padding-top: 0px;
  background: #ffffff;
  border-top: 1px solid #dfdfdf;
  font-family: 'Lato', sans-serif !important;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  ul,
  span,
  div,
  article,
  a {
    font-family: 'Lato', sans-serif !important;
  }

  a {
    text-decoration: none;
  }

  p,
  ul li {
    color: rgb(65, 65, 65);
    font-size: 16px;
  }

  button {
    box-shadow: 0 0 0 0 !important;
    text-shadow: none !important;
  }

  aside {
    background: #fff !important;
    border: none;
    flex: 0 0 205px !important;
    max-width: 205px !important;
    min-width: 205px !important;
    width: 205px !important;
  }

  header {
    height: auto;
    line-height: 1;
    background: white;
  }

  .job-state {
    padding: 0 4em 0 1em;
  }

  .ant-form-item-required::before {
    content: '';
  }

  i.anticon.anticon-down.ant-select-arrow-icon,
  i.anticon.anticon-calendar.ant-calendar-picker-icon {
    color: $mainColor;
  }

  footer {
    z-index: 99;
    background: $main-bg;
    position: fixed;
    bottom: 0;
    padding: 0;
    width: 100%;
    border-radius: 0;
    margin-left: -8em;
    padding-left: 11em;
    padding-right: 25em;
    border-top: 1px solid $main-border-color;

    button.ant-btn.ant-btn-primary:enabled {
      background: $primary-color !important;
      filter: $purple-drop-shadow;
    }
  }

  main {
    background: #ffffff;
  }

  .main-content {
    min-height: 90vh;
    max-height: 100%;
    overflow: auto;
    background: #ffffff;
    scroll-behavior: smooth;
    padding-bottom: 5em;

    &__headline {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 4.5em 0 2em 5em;

      @media screen and (max-width: 499px) {
        padding: 0;
        margin: 2em 0;
      }
  
      @media screen and (max-width: 999px) {
        display: flex;
        justify-content: center;
        padding-top: 2em;
      }
  
      .ant-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px !important;
        width: 30px !important;
        height: 40px !important;
      }

      h3.ant-typography {
        margin: 0 1em;
      }
    }
  }

  .footer-btn {
    margin: 2em 0 6em;
  }

  .main-btn {
    background: #f3f3f4;
    color: #0d0c22;
    display: inline-block;
    padding: 10px 16px;
    border: none;
    text-decoration: none;

    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    outline: none;
    font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    border-radius: 8px !important;

    &:hover {
      opacity: 1 !important;
      filter: brightness(0.9);
    }
  }

  button.ant-btn.ant-btn-primary:disabled {
    color: #838383 !important;
    background: rgb(235, 235, 235) !important;
  }

  button.ant-btn.ant-btn-primary {
    @extend .main-btn;
    color: #fff !important;
    background: $mainColor !important;

    &:hover {
      color: #fff !important;
      opacity: 0.7 !important;
      filter: none;
    }
  }

  button.ant-btn.ant-btn-default {
    @extend .main-btn;
    color: #fff !important;
    background: $primary-color !important;

    &:hover {
      color: #fff !important;
      opacity: 0.7 !important;
      filter: none;
    }
  }

  button.ant-btn.ant-btn-link {
    height: auto;
    font-weight: 600;
    color: #383838 !important;
    padding: 2px !important;
    font-size: 16px;

    &:hover {
      background: #f3f3f4 !important;
      filter: none !important;
    }

    i {
      margin-left: 0;
      margin-right: 3px;
      color: $mainColor;
    }
  }

  .dnd-row {
    .dnd-wrap {
      width: 100%;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        min-width: 27px;
      }

      .dnd-item,
      .dnd-stage {
        width: 100%;
        padding: 4px 17px;
      }
    }

    .dnd-item {
      display: flex;
      align-items: center;
      border-radius: 7px;
      justify-content: space-between;

      button {
        box-shadow: 0 0 0 0;

        &:first-child {
          margin-right: 5px;
        }
      }
    }

    .dnd-stage {
      display: flex;
      align-items: center;
      border-radius: 7px;
      justify-content: space-between;

      button {
        box-shadow: 0 0 0 0;

        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }

  .btns-container {
    text-align: left;
    margin-bottom: 13px;

    .website-btn {
      border-radius: 5px;
      background-color: #fff;
      border-color: #fff;
      width: 100px;
      position: relative;
      height: 42px;
      display: inline-block;
      margin-right: 20px;
      filter: grayscale(.7);
      border: 1px solid $main-border-color;
      transition: filter .4s ease, border-color .4s ease;
      -webkit-transition: filter .4s ease, border-color .4s ease;

      img {
        width: 65px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }

  &__job-actions {
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: space-between;

    .preview {
      button {
        border: none;
        font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: rgb(15, 15, 15);
        box-shadow: 0 0 0 0;
        background: transparent;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .save {
      button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .add-btn {
    height: auto;
    padding: 7px 0 3px;
    border-style: solid;
    border-width: 1.3px;
    margin-left: 2.3em;
    background: transparent !important;
  }

  /*-------------------------------------------------
  * ANTD classes
  *--------------------------------------------------*/
  .ant-steps-navigation {
    .ant-steps-item-process .ant-steps-item-icon {
      background: $primary-color;
    }

    .ant-steps-item-process .ant-steps-item-icon,
    .ant-steps-item-finish .ant-steps-item-icon {
      border-color: $primary-color;
    }

    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
      color: $primary-color;
    }
  }

  .ant-steps-navigation .ant-steps-item::before {
    display: none;
  }

  input {
    color: black;
  }

  .ant-select-selection__rendered {
    line-height: 1.5;
  }

  .ant-form-item-required::before {
    display: none;
  }

  .ant-col.ant-form-item-label label {
    font-weight: 600;
  }

  i.anticon.anticon-down.ant-select-arrow-icon {
    color: $mainColor;
  }

  i.anticon.anticon-close.ant-modal-close-icon {
    color: $primary-color;
  }

  .ant-select-selection.ant-select-selection--single {
    padding-left: 5px !important;
  }

  // ANTD
  .ant-steps-navigation {
    .ant-steps-item-process .ant-steps-item-icon {
      background: $primary-color;
    }

    .ant-steps-item-process .ant-steps-item-icon,
    .ant-steps-item-finish .ant-steps-item-icon {
      border-color: $primary-color;
    }

    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
      color: $primary-color;
    }
  }

  .ant-steps-navigation .ant-steps-item::before {
    display: none;
  }

  input {
    color: black;
  }

  .ant-select-selection__rendered {
    line-height: 1.5;
  }

  .ant-form-item-required::before {
    display: none;
  }

  .ant-col.ant-form-item-label label {
    font-weight: 600;
  }

  i.anticon.anticon-down.ant-select-arrow-icon {
    color: $mainColor;
  }

  i.anticon.anticon-close.ant-modal-close-icon {
    color: $primary-color;
  }

  .ant-select-selection.ant-select-selection--single {
    padding-left: 5px !important;
  }

  .ant-select-selection-selected-value {
    line-height: 1.5;
  }

  .ant-input,
  .ant-input-number,
  .ant-select-selection.ant-select-selection--single,
  .ant-select-selection.ant-select-selection--multiple {
    font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 45px;
    padding: 10px 16px;
    outline: none;
    border-radius: 8px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #0d0c22;
    border: $main-border-color solid 2px !important;
    background-color: #f2f5fa !important;

    &:focus,
    &-focused,
    &:active {
      background-color: white !important;
    }
  }

  .ant-select {

    &:focus,
    &-focused,
    &:active {
      background-color: white !important;
    }
  }

  .ant-input-number {
    display: flex;
    align-items: center;
  }

  .ant-select-selection.ant-select-selection--multiple {
    min-height: 45px;
    height: auto;
  }

  .ant-input {
    border: none;
    border-radius: 8px !important;
  }

  .ant-input-number {
    input {
      padding: 0;
    }
  }

  .ant-select-selection--multiple>ul>li,
  .ant-select-selection--multiple .ant-select-selection__rendered>ul>li {
    margin-top: 0;
    margin-bottom: 3px;
  }

  .ant-input:focus,
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active,
  .ant-input-number:focus,
  .ant-input-number-focused,
  .ant-select-selection.ant-select-selection--single:focus {
    -webkit-box-shadow: 0 0 0 2px #7582f742;
    box-shadow: 0 0 0 2px #7582f742 !important;
  }

  .ant-select-selection.ant-select-selection--single {
    border: 0;
  }

  &:focus,
  &-focused,
  &:active {
    background-color: white !important;
  }

  .ant-select {

    &:focus,
    &-focused,
    &:active {
      background-color: white !important;
    }
  }

  .ant-input-number {
    display: flex;
    align-items: center;
  }

  .ant-select-selection.ant-select-selection--multiple {
    min-height: 45px;
    height: auto;
    .ant-select-selection__rendered{
      margin: 0;
    }
  }

  .ant-input {
    border: none;
    border-radius: 8px !important;
  }

  .ant-input-number {
    input {
      padding: 0;
    }
  }

  .ant-select-selection--multiple>ul>li,
  .ant-select-selection--multiple .ant-select-selection__rendered>ul>li {
    margin-top: 0;
    margin-bottom: 3px;
  }

  .ant-input:focus,
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active,
  .ant-input-number:focus,
  .ant-input-number-focused,
  .ant-select-selection.ant-select-selection--single:focus {
    -webkit-box-shadow: 0 0 0 2px #7582f742;
    box-shadow: 0 0 0 2px #7582f742 !important;
  }

  .ant-select-selection.ant-select-selection--single {
    border: 0;
  }
}


// modal bg 
.ant-modal-mask {
  background: rgba(0, 0, 0, 0.8);
}

.animate-last-question {
  animation: 1.3s animate-question forwards;

  @keyframes animate-question {
    from {
      opacity: 0.1
    }

    to {
      opacity: 1
    }
  }
}

.cp-view-old {
  // preview de los juegos en el formulario antiguo
  pointer-events: none;

  .outerCircle,
  .outerCirclrPlayable {
    width: 90px;
    height: 90px;
  }

  .icon {
    height: 79px;
  }

  .smallHeart {
    width: 30px;
    height: 30px;
  }

  .ant-col.ant-col-xs-24.ant-col-md-18.ant-col-lg-15 {
    width: 100%;
  }

  .ant-card {
    &-body {
      padding: 20px 0;
    }
  }
}

.ant-list {
  padding: 2rem;
}