@import '../../../styles/main.scss';
@import '../../../styles/variables.scss';

$bg: #fbfbfb;

.gw-ppa {
  height: 100vh;
  background: #fff;

  .ant-card {
    box-shadow: rgb(247, 247, 247) 0px 16px 20px 0px;
  }

  aside {
    h1 {
      font-size: 1.5em;
      margin-bottom: 1.5em;
    }

    span.ant-typography {
      font-size: 15px;
      line-height: 1.3;
    }

    ul {
      margin: 1.5em 0;

      li {
        padding: 0.5em 0;

        strong {
          color: #1a1a1a;
        }
      }
    }
  }

  &__sider {
    background: #fff !important;
    padding: 2em 0em;

    &-menu {
      padding: 0;
    }

    .ant-typography {
      color: #414141;
      padding: 0em 1em 0em 1em;
    }

    .ant-menu-item {
      padding-left: 2em !important;
      white-space: normal !important;
      line-height: 16px;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: rgb(255, 227, 238);
    }

    .ant-menu-item-selected > a,
    .ant-menu-item-selected > a:hover {
      color: #f175a5;
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
      border-right: 3px solid #f175a5;
    }

    span.ant-typography {
      color: #444444;
    }

    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  &__main-content {
    background-color: #fff !important;
    main {
      overflow-y: auto;
      background: $bg;
      padding: 2em 4em;

      @media screen and (min-width: 1290px) {
        padding: 2em calc(48vw - 640px);
      }

      @media screen and (max-width: 550px) {
        padding: 3em 1em 0em 1em;
      }

      @media screen and (max-width: 400px) {
        padding: 4.5em 0em;
      }
    }

    .ant-select,
    .ant-calendar-picker {
      // NOTE: No estoy segura del padding derecho
      padding: 0 4em 0 1em;
    }
  }

  &__cands-per-stage {
    margin: 2em 0 3em 0;

    @media screen and (max-width: 999px) {
      margin: 3em 0 3em 0;
    }

    @media screen and (max-width: 850px) {
      margin-top: 2em;
    }

    .ant-typography {
      color: #444444;
    }
  }

  &__cands-timeline {
    .ant-typography {
      color: #444444;
    }
  }

  &__characteristics {
    .ant-typography {
      color: #444444;
    }
  }

  &__total-cands {
    .total-candidates {
      display: flex;
      align-items: center;
      height: 150px;
      width: 90%;

      @media screen and (max-width: 999px) {
        display: flex;
      }

      @media screen and (max-width: 850px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 380px;
      }

      @media screen and (max-width: 410px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 380px;
      }

      button {
        color: white;
        background: #0e0e0e;
        border: none;
        font-weight: 600;
        width: 100%;
        padding: 8px;
        height: auto;
      }

      .ant-card {
        height: 120px;
        width: 210px !important;
        border: 1px solid #e2e2e2;
        border-radius: 20px;
        margin-right: 8px;
        box-shadow: #ebebeb 0px 12px 8px 0px;
        padding: 1em 0 0 1em;

        @media screen and (max-width: 1199px) {
          width: 180px !important;
          margin: 5px 5px 0 0;
        }

        @media screen and (max-width: 770px) {
          width: 200px !important;
          margin: 5px 5px 0 0;
        }

        @media screen and (max-width: 450px) {
          width: 140px !important;
          margin: 3px;
        }

        .ant-card-body {
          padding: 6px 6px 0px 6px;

          .ant-typography {
            margin-bottom: 8px;

            @media screen and (max-width: 450px) {
              font-size: 12px;
            }
          }

          h4.ant-typography {
            display: flex;
            align-items: flex-end;
            margin-bottom: 0;
            font-size: 28px;
            color: #444444;
          }
        }
      }
    }
  }

  &__score-chart {
    margin: 3em 0;

    h4.ant-typography {
      color: #444444;
    }
  }

  &__characteristics {
    margin: 3em 0;

    .ant-row-flex {
      margin-bottom: 1em;
    }
  }

  &__evaluations {
    margin: 3em 0;
  }

  &__ko {
    width: 100%;

    .questions {
      display: flex;
      flex-direction: column;

      .ant-typography {
        color: #444444;
      }

      .ant-card {
        margin-bottom: 1em;
      }

      &__text {
        margin-top: 1em;

        .ant-typography {
          font-size: 12px;
        }
      }
    }
  }
}

.gw-bar-chart[layout='horizontal'] {
  display: grid;

  .column {
    width: 100%;
    position: relative;
    margin-top: 20px;

    .bar {
      border-radius: 10px;

      .result {
        left: 0;
        height: 100%;
        border-radius: 10px;
      }

      .total {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        margin-right: 20px;

        i {
          margin-left: 5px;
        }
      }
    }

    .left {
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 99;
      margin-left: 20px;
    }

    .label {
      margin-right: 1.5em;
    }

    .percent {
      margin-top: 2px;
      color: #fbfbfb;
    }
  }
}

.gw-bar-chart[layout='vertical'] {
  display: flex;
  text-align: center;

  .column {
    width: 100%;

    .bar {
      width: 80%;

      .center {
        position: absolute;
        z-index: 99;
        left: 0;
        right: 0;
        text-align: center;
      }

      .result {
        bottom: 0;
        width: 100%;
      }
    }

    .label {
      margin-right: 15%;
      margin-top: 1.5em;
    }
  }
}

.gw-bar-chart {
  .column {
    .bar {
      height: 300px;
      display: grid;
      align-items: center;
      position: relative;

      .percent {
        font-size: 20px;
      }

      .total {
        font-size: 16px;
        color: #444444;
      }

      .result {
        position: absolute;
      }
    }

    .left {
      width: 100%;
    }

    .label {
      color: #fbfbfb;

      .ant-rate {
        .ant-rate-star-zero svg path {
          fill: #d7d7d7;
        }

        .ant-rate-star-full svg path {
          fill: #ffc107;
        }
      }
    }
  }
}

.gw-ppa__nationality {
  ul {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -moz-column-fill: auto;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    -webkit-column-fill: auto;
    column-count: 2;
    column-gap: 20px;
    column-fill: auto;
    list-style-position: inside;
  }

  svg {
    width: 90%;
    height: 90%;
  }

  path:hover {
    stroke: #1a1a1a;
  }

  #CL {
    fill: red;
  }

  #PE {
    fill: blue;
  }

  #URG {
    fill: orange;
  }

  #BR {
    fill: green;
    background: green;
  }

  #PARG {
    fill: lightblue;
  }

  #EC {
    fill: violet;
  }

  #CLMB {
    fill: coral;
  }

  #VEN {
    fill: yellowgreen;
  }

  #PARG {
    fill: yellow;
  }

  #ARG {
    fill: rgb(0, 204, 255);
  }

  #BOL {
    fill: pink;
  }

  .legends {
    display: grid;
  }
}

.gw-ppa-traits {
  margin: 3em 0;

  h4.ant-typography {
    color: #444444;
  }

  .categories {
    padding-bottom: 1.5em;

    .active {
      background: #f175a5;
      border-radius: 10px;

      h4 {
        color: #fff;
      }

      span.ant-typography,
      span.ant-progress-text {
        color: rgb(231, 231, 231);
      }
    }

    .inactive {
      background: #fff;
      border-radius: 10px;
    }

    .ant-col:nth-child(1) path.ant-progress-circle-path {
      stroke: #ca75f2 !important;
    }

    .ant-col:nth-child(2) path.ant-progress-circle-path {
      stroke: #fd78ab !important;
    }

    .ant-col:nth-child(3) path.ant-progress-circle-path {
      stroke: #ffc107 !important;
    }

    .ant-card {
      cursor: pointer;

      &:hover {
        box-shadow: #e6e7ec 0px 16px 20px 0px;
      }

      &-body {
        display: flex;
        padding: 0;
        align-items: flex-start;

        // progress
        .ant-progress-inner {
          width: 2em !important;
          height: 2em !important;

          span.ant-progress-text {
            font-size: 11px;
          }
        }

        .category-info {
          margin-left: 1em;
          color: #444444;

          h4.ant-typography {
            font-size: 1.2em;
            line-height: 1.3;
            margin: 0 !important;
          }

          span.ant-typography {
            font-size: 13px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.gw-line-chart-tooltip {
  box-shadow: rgba(114, 114, 114, 0.212) 0px 6px 10px 0px;
  background: #fff;
  padding: 10px;
  border-radius: 4px;

  span {
    background: $bg;
    width: 100%;
    border-radius: 4px;
    padding: 5px;
  }
}

.ppa_header-info {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 24px;
}

.disability {
  display: flex;
  flex-direction: column;

  &__kind {
    display: flex;
    flex-direction: column;
  }
}

// Candidates header
.header-comparision {
  display: flex;
  width: 100%;

  &__candidate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 2em 0 2em;

    @media screen and (max-width: 999px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 1em 0 1em;
    }
  }

  &__name {
    display: flex;
    flex-direction: column;

    .ant-typography {
      font-size: 14px;
    }
  }

  .ant-card {
    border-radius: 50px;
    width: 100px;
    height: 100px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1.ant-typography {
      color: #ffffff;
      margin-bottom: 0;
      padding: 0;

      @media screen and (max-width: 1199px) {
        font-size: 16px;
      }
    }

    @media screen and (max-width: 1199px) {
      width: 60px;
      height: 60px;
    }

    @media screen and (max-width: 699px) {
      width: 25px;
      height: 25px;
    }
  }

  .card-1 {
    background-color: rgb(167, 105, 211);
  }

  .card-2 {
    background-color: rgb(162, 129, 235);
  }

  .card-3 {
    background-color: rgb(110, 106, 212);
  }

  .card-4 {
    background-color: rgb(123, 150, 246);
  }

  .card-5 {
    background-color: rgb(141, 135, 255);
  }

  .card-6 {
    background-color: rgb(121, 214, 246);
  }

  &__more {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

// Candidates table scores
.comparision-dashboard {
  @media screen and (max-width: 550px) {
    display: none;
  }

  table,
  td,
  th {
    // border: 1px solid rgb(172, 172, 172);
    border-bottom: 1px solid #e8e8e8;
    padding: 16px;

    @media screen and (max-width: 420px) {
      font-size: 12px;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    overflow-x: scroll;

    tbody > tr > td {
      padding: 25px;
    }

    tbody > tr:hover {
      background: #fff0fa;
    }

    .ant-typography {
      @media screen and (max-width: 690px) {
        font-size: 12px;
      }
    }

    &__header {
      background-color: #fafafa;
      border-bottom: 1px solid #e8e8e8;
      text-align: left;

      .ant-typography {
        font-weight: bold;
        color: #616161;
        font-size: 14px;

        @media screen and (max-width: 420px) {
          font-size: 12px;
        }
      }

      &__names {
        &-1 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px 0 15px 15px;
          padding: 20px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background-color: rgb(167, 105, 211);

          .ant-typography {
            color: #ffffff;
          }
        }

        &-2 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px 0 15px 15px;
          padding: 20px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background-color: rgb(162, 129, 235);

          .ant-typography {
            color: #ffffff;
          }
        }

        &-3 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px 0 15px 15px;
          padding: 20px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background-color: rgb(110, 106, 212);

          .ant-typography {
            color: #ffffff;
          }
        }

        &-4 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px 0 15px 15px;
          padding: 20px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background-color: rgb(123, 150, 246);

          .ant-typography {
            color: #ffffff;
          }
        }

        &-5 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px 0 15px 15px;
          padding: 20px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background-color: rgb(141, 135, 255);

          .ant-typography {
            color: #ffffff;
          }
        }

        &-6 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px 0 15px 15px;
          padding: 20px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background-color: rgb(121, 214, 246);

          .ant-typography {
            color: #ffffff;
          }
        }
      }
    }

    .ant-rate {
      color: #ffe95c !important;
    }

    &__progress1 {
      .ant-progress-inner {
        width: 80px !important;
        height: 80px !important;
        font-size: 18px !important;

        @media screen and (max-width: 699px) {
          width: 50px !important;
          height: 50px !important;
          font-size: 13px !important;
        }
      }

      .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: rgb(167, 105, 211) !important;
      }
    }

    &__progress2 {
      .ant-progress-inner {
        width: 80px !important;
        height: 80px !important;
        font-size: 18px !important;

        @media screen and (max-width: 699px) {
          width: 50px !important;
          height: 50px !important;
          font-size: 13px !important;
        }
      }

      .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: rgb(162, 129, 235) !important;
      }
    }

    &__progress3 {
      .ant-progress-inner {
        width: 80px !important;
        height: 80px !important;
        font-size: 18px !important;

        @media screen and (max-width: 699px) {
          width: 50px !important;
          height: 50px !important;
          font-size: 13px !important;
        }
      }

      .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: rgb(110, 106, 212) !important;
      }
    }

    &__progress4 {
      .ant-progress-inner {
        width: 80px !important;
        height: 80px !important;
        font-size: 18px !important;

        @media screen and (max-width: 699px) {
          width: 50px !important;
          height: 50px !important;
          font-size: 13px !important;
        }
      }

      .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: rgb(123, 150, 246) !important;
      }
    }

    &__progress5 {
      .ant-progress-inner {
        width: 80px !important;
        height: 80px !important;
        font-size: 18px !important;

        @media screen and (max-width: 699px) {
          width: 50px !important;
          height: 50px !important;
          font-size: 13px !important;
        }
      }

      .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: rgb(141, 135, 255) !important;
      }
    }

    &__progress6 {
      .ant-progress-inner {
        width: 80px !important;
        height: 80px !important;
        font-size: 18px !important;

        @media screen and (max-width: 699px) {
          width: 50px !important;
          height: 50px !important;
          font-size: 13px !important;
        }
      }

      .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: rgb(121, 214, 246) !important;
      }
    }
  }
}

.candidate-comparision {
  width: 100%;
  margin: auto;
  border-top: 1px solid rgb(236, 236, 236);
  padding: 0 70px 0 70px;
  background-color: #ffffff;

  @media screen and (max-width: 999px) {
    padding: 0 20px 0 20px;
  }

  &__main-title {
    h3.ant-typography {
      padding: 2em 0 2em 3em;
      color: #404040;

      @media screen and (max-width: 420px) {
        padding-top: 30px;
        font-size: 18px;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    position: sticky;
    top: 55px;
    z-index: 100;
    width: 100%;

    @media screen and (max-width: 999px) {
      justify-content: center;
      padding: 1em 0 1em 0;
    }
  }

  &__dashboard {
    padding: 0 4em 0 4em;
    margin-top: 2em;
    width: 100%;

    @media screen and (max-width: 699px) {
      padding: 0 10px 0 10px;
      margin-top: 1em;
    }
  }
}

// Candidates personality scores
.personality-comparasion {
  &__title {
    margin: 3em 0;

    h3.ant-typography {
      background-color: #f175a5;
      color: #ffffff;
      border-radius: 20px;
      margin: 3em 0;
      padding: 1em;
    }
  }

  &__subtitle {
    h4.ant-typography {
      color: #444444;
    }
  }

  &__range {
    margin: 1em 0 3em 0;

    &-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 150px;
      margin-right: 10px;
    }

    &-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 150px;
      margin-left: 20px;
    }

    .rc-slider {
      height: 3px;
    }

    .rc-slider-handle {
      display: none;
    }
  }

  .icon-wrapper {
    position: relative;
    padding: 0px 30px;
  }

  .icon-wrapper .anticon {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    line-height: 1;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.25);
  }

  .icon-wrapper .anticon:first-child {
    left: 0;
  }

  .icon-wrapper .anticon:last-child {
    right: 0;
  }
}

// Candidates heatmap
.heatmap-section {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 420px) {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 3em 0;

    h3.ant-typography {
      background-color: #f175a5;
      color: #ffffff;
      border-radius: 20px;
      margin: 3em 0 0 0;
      padding: 1em;
    }
  }

  &__heatmap-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @media screen and (max-width: 420px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__select {
    padding-bottom: 50px;

    .ant-select {
      width: 300px;
    }

    @media screen and (max-width: 420px) {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__trait-bar {
    img {
      width: 350px;
    }
  }
}

.axis-left-value {
  white-space: normal !important;
  line-height: 16px;
}


.job-title-container {
  min-height: 15vh;
  h2 {
    margin: 0;
  }

  &__job-name {
    margin-left: 5rem;
  }

  &__compare-modal {
    .ant-modal-content {
      display: flex;
      justify-content: center;
    }
  }

  @media all and (max-width: 600px) {
    h2 {
      font-size: 20px;
    }
    &__job-name {
      margin-left: 3.5rem;
    }
  }
}

// PROFILE CONTAINER

.profiles-container {
  overflow-x: auto;
  overflow-y: hidden;

  &__profiles {
    min-height: 25vh;
    min-width: 950px;
    background-color: #fafafa;
  }

  &__title {
    .ant-typography {
      font-size: 16px;
    }
  }
  &__avatars {
    text-align: center;
    text-transform: capitalize;
    .ant-typography {
      margin: 0;
      font-size: 16px;
      @media all and (max-width: 600px) {
        font-size: 14px;
      }
    }
    .ant-avatar-string {
      font-size: 30px;
    }
  }
}

// FIT CONTAINER

.fit-section-container {
  overflow-x: auto;

  &__fit-section {
    min-width: 950px;
  }

  &__title {
    .ant-typography {
      font-size: 16px;
    }
  }
}

.section-title {
  min-height: 62px;
  .ant-typography {
    margin: 0 83px;
  }
}

// TRAITS CONTAINER

.traits-section-container {
  overflow-x: auto;

  &__table {
    width: 100%;
    min-width: 950px;
  }

  &__rows {
    margin: 20px 0;
  }
}

// INTERVIEW CONTAINER

.interview-section-container {
  overflow-x: auto;
  overflow-y: hidden;

  &__info {
    min-width: 950px;
  }
}

.question-section-container {
  overflow-x: auto;

  &__table {
    width: 100%;
    text-align: center;
  }

  &__row {
    min-width: 950px;
  }
}

.personality-section-container {
  overflow-x: auto;
  &__slider {
    margin: 40px 0 48px 0;
  }
}

.heatmap-container {
  width: 100%;
  margin: 25px 0;
  &__heatmap-questions {
    margin-top: 4.688rem;
    table {
      width: 100%;
    }
    tbody {
      display: flex;
      flex-direction: column;
    }
    tr {
      margin: 10px 0;
    }
    td {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: end;
      margin: 0 10px;
      text-align: center;
    }
  }
  &__heatmap-percent {
    table {
      width: 100%;
    }
    thead {
      tr {
        display: flex;
        justify-content: center;
      }
      th {
        margin: 0px 18px;
      }
    }
    tbody {
      display: flex;
      justify-content: center;
      tr {
        margin: 10px 0;
        display: flex;
      }
      td {
        margin: 4px 4px;
        height: 50px;
        width: 91px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }
    }
  }
  &__heatmap-colors {
    margin-top: 5.5rem;
    display: flex;
    flex-direction: column;
    .color-info {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 0 30px 0;
      justify-content: flex-end;
    }
  }
  @media all and (max-width: 1000px) {
    .heatmap-colors {
      display: none;
    }
  }
}
.heatmap-mobile-container {
  display: none;
}
@media all and (max-width: 600px) {
  .heatmap-container {
    display: none;
  }
  .heatmap-mobile-container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    min-width: 100%;
    min-height: 50px;
    &__heatmap-mobile-colors {
      width: 100%;
      justify-content: space-around;
    }
    &__color-info {
      display: flex;
      align-items: center;
      .ant-typography {
        margin: 0px 6px;
      }
    }
    &__heatmap-mobile-questions {
      overflow-x: auto;
      min-height: 600px;
      -webkit-transform: matrix(0, -1, 1, 0, 0, 0);
      -moz-transform: matrix(0, -1, 1, 0, 0px, 0px);
      -ms-transform: matrix(0, -1, 1, 0, 0, 0);
      -o-transform: matrix(0, -1, 1, 0, 0, 0);
      transform: matrix(0, -1, 1, 0, 0, 0);
    }
    &__questions {
      margin: 52px 10px 0 10px;
      table {
        width: 100%;
      }
      tbody {
        display: flex;
        flex-direction: column;
      }
      tr {
        margin: 10px 0;
      }
      td {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 0 5px;
        text-align: center;
      }
    }
    &__percent {
      margin: 10px 0 10px 20px;
      table {
        min-width: 100%;
      }
      thead {
        tr {
          display: flex;
          justify-content: center;
        }
        th {
          margin: 0px 6px;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
      tbody {
        display: flex;
        justify-content: center;
        tr {
          margin: 10px 0;
          display: flex;
        }
        td {
          margin: 10px 0px;
          min-height: 30px;
          min-width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }
  }
}
@media all and (max-width: 1200px) {
  .job-title {
    margin: 0 75px;
  }
  .profiles-container {
    &__avatars {
      width: 750px;
    }
  }
  .section-title {
    .ant-typography {
      margin: 0 75px;
    }
  }
  .traits-section {
    .trait-container {
      .feature-name {
        .ant-typography {
          margin-left: 75px;
        }
      }
      .answer-name {
        margin-right: 75px;
      }
    }
  }
  .interview-section {
    .no-data {
      margin-left: 75px;
    }
    .question {
      .ant-typography {
        margin-left: 75px;
      }
    }
    .assesment {
      margin-right: 0px;
    }
  }
  .question-section {
    .no-data {
      margin-left: 75px;
    }
    .questions {
      .ant-typography {
        margin-left: 75px;
      }
    }
  }
  .personality-section {
    margin: 20px 75px;
  }
}
@media all and (max-width: 900px) {
  .job-title {
    margin: 0 25px;
    min-height: 15vh;
  }
  .profiles-container {
    &__avatars {
      width: 750px;
    }
  }
  .section-title {
    .ant-typography {
      margin: 0 57px;
      font-size: 21px;
    }
  }
  .traits-section {
    .trait-container {
      .feature-name {
        width: 150px;
        .ant-typography {
          margin-left: 25px;
        }
      }
      .answer-name {
        width: 750px;
        margin-right: 25px;
      }
    }
  }
  .interview-section {
    .no-data {
      margin-left: 25px;
    }
    .question {
      .ant-typography {
        margin-left: 25px;
      }
    }
    .assesment {
      width: 750px;
      margin-right: 25px;
    }
  }
  .question-section {
    .no-data {
      margin-left: 25px;
    }
    .ant-col {
      width: 150px;
      .questions {
        margin: 5px 0 5px 0;
        .ant-typography {
          margin-left: 25px;
        }
      }
      .answers {
        width: 750px;
        height: 100%;
      }
    }
  }
  .personality-section {
    margin: 20px 10px;
  }
  .heatmap-container {
    margin: 0 25px 60px 0;
  }
  .interview-section-mobile {
    margin: 20px 0px;
  }
}
