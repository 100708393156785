@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$mainColor: #f175a5;
$cognit: #ffa388;
$reasoning: #c17ee6;
$personality: #ff82a0;
$white: #ffffff;
$low: #f5222d;
$medium: #e8e526;
$high: #52c41a;
$subtitle: #030303b9;
$lightBlue: #00b6c7;


// button.ant-btn.ant-btn-primary:enabled, 
// button.ant-btn.ant-btn-primary:enabled:focus, 
// button.ant-btn.ant-btn-primary:enabled:hover {
//   background: $mainColor !important;
//   border-color: $mainColor;
//   color: $white;
// }

.ant-pagination-prev .ant-pagination-item-link, 
.ant-pagination-next .ant-pagination-item-link {
  display: block !important;
}

.ant-message {
  bottom: 4px !important;
  left: 10px !important;
  top: unset !important;
  width: auto;

  .ant-message-notice-content {
    padding: 18px 30px;
    font-size: 17px;
    background: #232323;
    color: white;
  }
}

.ant-page-header-back-button:focus, 
.ant-page-header-back-button:hover {
  color: $mainColor;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover, 
.ant-input:hover, 
.ant-input:focus {
  border-color: $mainColor;
}
.ant-input:focus {
  box-shadow: 0 0 0 2px rgba(255, 24, 197, 0.2);
}

.candidate-profile-layout {
  background: #fafafb !important;
  padding: 30px 15px;
  font-family: 'Roboto', sans-serif;

  @media screen and (mix-width: 400px) {
    padding-top: 20px;
  }

  @media screen and (min-width: 1200px) {
    padding: 30px 50px;
  }

  .ant-progress-inner, .ant-progress-bg {
    height: unset !important;
  }

  path.ant-progress-circle-path {
    //stroke: #f175a5 !important;
  }

  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #adadad !important;
  }

  .ant-card-body {
    padding: 0;
  }

  .candidate-prof {
    max-width: 1570px;

    .ant-card-body {
      padding: 0;
    }
    
    @media screen and (min-width: 1500px) {
      margin: auto !important;
    }

    .rc-slider-handle {
      border-color: #ffffff;
      box-shadow: rgba(9, 22, 31, 0.24) 0px 2px 10px 0px;

      span {
        top: 50% !important;
      }
    }
  
    .rc-slider-handle.rc-slider-handle-1 {
      background-color: $mainColor;
      width: 34px;
      height: 34px;
      margin-top: -15px;
      z-index: 999;
    }
  
    .rc-slider-handle.rc-slider-handle-2 {
      background-color: $lightBlue;
      width: 18px;
      height: 18px;
      margin-top: -8px;
    }

    .MceEditor {
      border: 1px solid #c1c1c1;
    }

    a {
      font-family: unset;
      font-size: 1rem;
      text-decoration: none;
      font-size: 12px;
      line-height: 26px !important;
    }

    p {
      font-size: 14px;
      line-height: 19px;
    }

    .ant-card {
      padding: 20px;
      border-radius: 7px !important;
      box-shadow: 0 2px 10px 0 rgba(7,31,49,.06);
    }

    .header-title {
      margin: 10px 0 30px;

      .ant-page-header-heading-title {
        font-size: 25px;

        @media screen and (max-width: 500px) {
          font-size: 20px;
        }
      }
    }

    .academic-info {
      @media screen and (max-width: 990px) {
        flex-direction: column-reverse;
      }
    } 

    .categories {
      padding-top: 10px;

      &-box {
        margin-top: 10px;
      }

      &-bok {
        margin-left: -5px; 
        margin-right: -5px;
      }
      .ant-card {
        margin-bottom: 10px;

        &:hover {
          cursor: pointer;
          box-shadow: 0 2px 10px 0 rgba(7, 31, 49, 0.445);
        }

        .ant-card-body {
          display: -webkit-box;
          align-items: center;
      
          h3 {
            color: $white;
            font-size: 14px;
            text-align: left;
            margin: auto 0;
            width: 80%;

            @media screen and (min-width: 990px) {
              font-size: 19px;
            }
          }
      
          .ant-avatar {
            background: rgba(255, 255, 255, 0.22);
            margin-right: 15px;
            width: 55px;
            height: 55px;
            line-height: 55px;
            font-size: 16px;
          }
      
          i.anticon.anticon-arrow-right {
            position: absolute;
            right: 19px;
            top: 4em;
            color: $white;
            font-size: 18px;
          }
        }
      }
    }


    // AcademicInfo/PersonalDataTable.js
    .personal-info {
      display: flex;
      flex-flow: wrap;

      .wrap {
        display: grid;
        padding: 0 20px 0 0;

        label {
          color: $mainColor;
        }
      }
    }

    .back-top {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 35px 0;

      div {
        cursor: pointer;
        color: white;
        padding: 10px;
        font-size: 30px;
        border-radius: 40px;
        background: rgb(163, 163, 163);

        &:hover {
          background: #1f1d1c;
        }
      }
    }

    // Ant Design classes
    .ant-card-head-title, .ant-card-extra {
      padding: 9px 0;
    }

    .ant-tag-cyan {
      color: #0e9c9c;
      background: #c7f3ec;
      border-color: #e6fffb;
    }
    a.nt-badge-status-dot {
      width: 8px;
      height: 8px;
    }
    .ant-divider-horizontal {
      margin: 9px 0 0;
    }
    .ant-empty {
      display: grid;
      margin: auto;
    }
    
    .ant-statistic-title {
      color: $mainColor;
    }

    .ant-tag-orange {
      background: #ffa38847;
      border-color: #ffa48800;
      color: #f1886a;
    }

    .ant-tag-pink {
      background: #ff829f5b;
      border-color: #ff829f00;
      color: #f8587d;
    }

    .ant-tag-purple {
      background: #f582ff5b;
      border-color: #ff82f900;
      color: #ba52d4;
    }

    .ant-tag-pink, .ant-tag-orange, .ant-tag-purple {
      font-size: 11px;
    }

    .ant-slider-handle.ant-slider-handle-2 {
      background: #91d5ff;
      width: 20px;
      height: 20px;
      margin-top: -5px;
    }

    .ant-slider-handle.ant-slider-handle-1 {
      background: $mainColor;
      width: 27px;
      height: 27px;
      margin-top: -8px;

      &:hover {
        z-index: 99999999;
      }
    }

    .ant-slider-rail {
      width: 100%;
      height: 9px;
      background-color: #f5f5f5;
      border-radius: 2px;
    }

    .ant-slider-track {
      height: 10px;
    }

    .ant-slider-disabled .ant-slider-track {
      background-color: transparent !important;
    }

    p.ant-empty-description {
      color: #c7c7c7
    }

    .ant-page-header.ant-page-header-ghost {
      border: none !important;
      padding: 0;
    }

    .ant-select-disabled {
      color: black !important;
      pointer-events: none;
    
      .ant-select-selection {
        border: 1px solid transparent !important;
        box-shadow: 0 0 0 0 !important;
      }
    }

    .ant-select-selection:hover {
      border-color: $mainColor;
    }
    
    .ant-select-disabled .ant-select-selection {
      background: $white !important;
      cursor: default !important;
    }
    
    .ant-select-selection:focus {
      border-color: $mainColor !important;
      box-shadow: 0 0 0 2px rgba(255, 24, 166, 0.2) !important;
    }

    .ant-list-split .ant-list-item {
      border-bottom: none;
    }

  }

  .ant-comment-inner {
    padding-top: 0;
  }
  .ant-comment-avatar {
    margin: 0;
  }

  .notes {
    padding: 0 !important;
    .ant-card-head-title {
      font-size: 19px;
    }
    small {
      font-size: 15px;
    }
    .notes-list {
      padding: 20px !important;
    }
    h4 {
      color: $subtitle !important;
    }
  }

  .add-comment {
    font-size: 11px;
    margin-left: 5px;
    height: 19px;
    width: 21px;
    margin-top: 5px;
    border-color: $mainColor;
  }
  
  .ant-list {
    width: 100%
  }
  
  
  .rc-slider-rail {
    width: 100%;
    height: 6px;
  }

  .ant-modal-header {
    background: #1f1d1c;
    padding: 16px 24px 6px;
  
    .ant-modal-title {
      color: white;
    }
    
  }
  
  button.ant-modal-close, button.ant-modal-close:hover, button.ant-modal-close:focus {
    color: white;
  }
  
  .rc-slider-handle.rc-slider-handle-1:hover {
    z-index: 99999;
  }
  
  .error-modal {
    .ant-modal-header {
      display: none;
    }
  }

  // Modal para enviar mail
.sendMail {
  h4 {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: white;

    img {
      margin-right: 10px;
    }
  }
  .copy-link-btn {
    margin: auto;
    display: flex;
    line-height: 14px;
    font-size: 21px;
    height: auto;
    padding: 16px 22px 0;
  }
}
.top {
  @media screen and (max-width: 575px) {
    padding-top: 7px;
  }
}

.additional-questions, .video-questions, .state-card {
  a i {
    color: rgb(59, 59, 59);
  }
  a:hover {
    i {
      color: $mainColor;
    }
  }

  li.ant-list-item {
    border-bottom: none !important;
  }

  .ant-list-pagination {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    width: 100%;
    padding: 12px 9px;
    border-top: rgba(0, 0, 0, 0.07) solid 1px;
  }
}

.ant-empty-image, p.ant-empty-description {
  @media screen and (max-width: 560px) {
    height: 69px;
    font-size: 12px !important;
  }
}

} // FIN container


.mail-history {  // esto creo que lo borrare
  height: 90%;

  .ant-modal-content, .ant-modal-body {
    height: 90%;
    padding: 0;
  }

  .ant-row {
    height: 100%;
  }

  &-list {
    padding: 20px 0;
    height: 100%;

    .active-card {
      border-left: rgb(248, 77, 171) 3px solid;
      background: rgb(255, 255, 255) !important;
    }

    .ant-card {
      border-radius: 0;
      padding: 15px;
      cursor: pointer;
      margin-bottom: 10px;
      border-bottom: 1px solid rgb(241, 241, 241);

      &:hover {
        box-shadow: 0 2px 10px 0 rgba(37, 37, 37, 0.13);
      }

      .ant-card-body {
        display: grid;
      }
    }

    button, button:hover, button:hover {
      background: rgb(248, 77, 171);
      color: $white;
      border-color: rgb(248, 77, 171);
      width: 90%;
      margin-left: 5%;
      margin-bottom: 10px;
      padding: 10px;
      height: auto;
      font-size: 15px;
    }
  }

  &-mail {
    padding: 15px;
    background: #fafafb;
    height: 100%;

    .selected-mail {
      background: white;
      padding: 20px;
      height: 100%;
    }

    &-content {
      margin-top: 2em;
    }
  }
}

.ant-modal-header {
  background: #f3f3f3;
}

.mail-actions {
  padding-top: 1.5em;

  button {
    margin-right: 7px;
    font-size: 13px;

    &:nth-child(1), &:nth-child(2) {
      border-color: $mainColor !important;
      color: $mainColor !important;
    }

    &:nth-child(3) {
      border-color: #666;
    }

    &:hover, &:focus {
      border-color: $mainColor !important;
      color: $mainColor !important;
    }
  }  
}

.ant-btn-secondary:hover, .ant-btn-secondary:focus, .ant-btn-secondary:active {
  border-color: $mainColor !important;
  color: $mainColor;
}

.ant-popover.ant-popover-placement-top {
  //width: 400px !important;
}
.ant-modal-footer {
  @media screen and (min-width: 500px) {
    justify-content: flex-end;
  }
}

.headroom--pinned {
  background-color: #fff !important;
  @media screen and (min-width: 870px) {
    display: flex;
    align-items: center;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    height: 77px;
    z-index: 99;
    background: white;
    -webkit-box-shadow: 0 2px 10px 0 rgba(44,44,44,.2);
    box-shadow: 0 2px 10px 0 rgba(44,44,44,.2) !important;
    animation: animateHeaderIn .5s ease;

    .header-content {
      max-width: 1570px;
      display: flex;
      width: 100%;
      margin: auto;
      justify-content: space-between;
    }

    .first-section {
      display: flex;
      align-items: center;
      
      &__name {
        display: grid;

        p {
          line-height: 20px;

          &:nth-child(2) {
            font-size: 14px;
          }
        }
      }

      ul.ant-rate {
        margin-left: 21px;
      }
    }

    .second-section {
      display: flex;
      align-items: center;

      .ant-select {
        min-width: 230px;
      }

      .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
        border-color: $mainColor;
        -webkit-box-shadow: 0 0 0 2px rgba(255, 24, 178, 0.2);
        box-shadow: 0 0 0 2px rgba(255, 24, 178, 0.2);
      }
      .ant-select-selection:hover {
        border-color: $mainColor;
      }

      .ant-btn:active, .ant-btn.active, .ant-btn:hover, .ant-btn:focus {
        color: $mainColor;
        border-color: $mainColor;
      }
    }
  }
}

.headroom--closed {
  display: none;
}

@keyframes animateHeaderIn {
  0% {
      transform: translateY(-100px);
  }
  100% {
      transform: translateY(0px);
  }
}

.loading-modal {
  width: 70px !important;
}

.preview-document-modal {
  @media screen and (min-width: 780px) {
    width: 800px !important;
  }
}

.mail-history-modal { 
  @media screen and (min-width: 780px) {
    width: 700px !important;
  }

  h4 {
    margin-bottom: 1.2em !important;
  }
  
  .ant-collapse.ant-collapse-borderless.ant-collapse-icon-position-left {
    max-height: 70vh;
    overflow: auto;

    .ant-tag {
      font-size: 11px;
    }

    .title {
      display: flex;
      align-items: center;
    }

    .date {
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;
      border-top: 1px solid rgb(224, 224, 224);
      padding-top: 10px;
    }
  }
}

.temporary-alert {
  background: #ffffff;
  display: flex;
  align-items: center;
  height: auto;
  padding-top: 17px;
  padding-bottom: 17px;
  max-width: 1570px;
  margin: auto;

  @media screen and (max-width: 430px) {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 1.5em;
  }

  @media screen and (max-width: 760px) {
    display: grid !important;
  }

  .first-section {
    display: flex;
    align-items: center;
    max-width: 550px;

    @media screen and (max-width: 430px) {
      display: grid;
    }

    img {
      margin-right: 20px;
    }

    p {
      line-height: 20px;

      &:first-child {
        @media screen and (max-width: 430px) {
          margin-top: 10px;
        }
      }

      &:last-child {
        font-size: 15px;
      }
    }
  }

  .second-section {
    margin-left: 5em;

    @media screen and (max-width: 760px) {
      margin-top: 10px;
      margin-left: 60px;
    }

    @media screen and (max-width: 430px) {
      margin-left: 0;
    }

    p {
      line-height: 20px;

      &:last-child {
        font-size: 13px;
        color: #333333 !important;
      }
    }

    a {
      color: $mainColor;
      text-decoration: none;
      font-weight: 600;
      font-size: 15px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.ant-modal-header {
  h4 {
    color: rgba(56, 56, 56, 0.85) !important;
  }
}

.custom-eval-card {
  padding: 0px !important;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;

  .ant-card-head-title {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .ant-card-body {
    padding-bottom: 20px;
  }

  .ant-card {
    padding: 20px;
    border-radius: 0px !important;
    box-shadow: 0 0px 0px 0 rgba(255, 255, 255, 0);
  }
}