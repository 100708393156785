$primary-color: #7583f7;
$mainColor: #f175a5;
$danger-color: #ec5f2f;
$success-color: #47E197;
$cognit: #f07f8b;
$reasoning: #4e4cac;
$personality: #9698d5;
$main-shadow: 0 6px 10px 0 rgba(128,98,96,.16);
$main-bg: #ecf1fa;
$main-border-color: #e2e8f5;
$pink-drop-shadow: drop-shadow(0 7px 0.45rem #ee689b65);
$purple-drop-shadow: drop-shadow(0 7px 0.45rem #7582f769);

$neutral-1: #fff;
$neutral-2: #fafafa;
$neutral-3: #f5f5f5;
$neutral-4: #f0f0f0;
$neutral-5: #d9d9d9;
$character-tertiary: #5e5e5e;

$default-border-radius: 2px;

%opacity-animation {
    animation: animateTabs .7s ease;
  
    @keyframes animateTabs {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  
  %scroll-bar {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar {
      width: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: rgb(255, 255, 255);
      &:hover {
        width: 12px;
      }
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.473);
    }
  }